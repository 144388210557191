<template>
    <div class="navigation" :style="{'z-index': 2}" v-if="ready">

        <!-- TOPBAR -->
        <v-toolbar>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$store.getters.isAuth"></v-app-bar-nav-icon>

            <v-toolbar-title>
                <router-link :to="{name: 'Home'}" :class="($vuetify.theme.dark ? 'white--text ' : '' )+'display-1 text-no-underline'">
                    neomind - erp
                </router-link>
            </v-toolbar-title>

            <v-spacer/>

            <v-speed-dial
                v-if="$store.getters.isAuth"
                v-model="submenu"
                direction="bottom"
                transition="scale-transition"
            >
                <!-- open-on-hover -->
                <template v-slot:activator>
                    <v-btn v-model="submenu" @click="showLang = false" fab icon>
                        <v-icon v-if="submenu">close</v-icon>
                        <v-icon v-else>settings</v-icon>
                    </v-btn>
                </template>

                <v-btn fab @click="$router.push({ name: 'Profile' })">
                    <v-icon>person</v-icon>
                </v-btn>

                <!-- <v-btn fab @click="changeTheme">
                    <v-icon v-if="$vuetify.theme.dark">wb_sunny</v-icon>
                    <v-icon v-else>brightness_3</v-icon>
                </v-btn> -->

                <v-btn fab @click.stop="showLang = !showLang">
                    <v-icon>language</v-icon>
                </v-btn>
                <v-btn v-if="showLang" fab small @click="changeLocal('fr')">fr</v-btn>
                <v-btn v-if="showLang" fab small @click="changeLocal('en')">en</v-btn>

                <v-btn fab @click="logout">
                    <v-icon>power_settings_new</v-icon>
                </v-btn>
            </v-speed-dial>

        </v-toolbar>

        <!-- LEFTBAR -->
        <v-navigation-drawer
            v-model="drawer"
            temporary
            absolute
            id="drawer"
            color="primary"
            app
            v-if="$store.getters.isAuth"
        >
            <v-toolbar color="primary">
                <v-toolbar-title class="mx-auto display-1 white--text">
                    neomind - erp
                </v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>
            <v-list dark class="py-0">
                
                <v-list-item @click="$router.push({ name: 'Projects' })">
                    <v-list-item-action>
                        <v-icon color="white">{{ $t('project.icon') }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('object.projects') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$router.push({ name: 'Calendar' })">
                    <v-list-item-action>
                        <v-icon color="white">{{ $t('calendar.icon') }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('calendar.title') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$router.push({ name: 'Issues' })">
                    <v-list-item-action>
                        <v-icon color="white">{{ $t('issue.icon') }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title class="justify-space-between">
                        {{ $t('object.issues') }}
                        <v-avatar v-if="new_issues_count" size="18" class="red darken-1 white--text"><small>{{ new_issues_count }}</small></v-avatar>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item @click="$router.push({ name: 'Timesheets' })">
                    <v-list-item-action>
                        <v-icon color="white">{{ $t('timesheet.icon') }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('object.timesheets') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$router.push({ name: 'FileManager' })">
                    <v-list-item-action>
                        <v-icon color="white">{{ $t('filemanager.icon') }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('filemanager.ged') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$router.push({ name: 'Ethercalc' })">
                    <v-list-item-action>
                        <v-icon color="white">{{ $t('ethercalc.icon') }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('object.ethercalcs') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$router.push({ name: 'Users' })">
                    <v-list-item-action>
                        <v-icon color="white">{{ $t('user.icon') }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $t('object.users') }}</v-list-item-title>
                </v-list-item>
            </v-list>

            <template v-slot:append>
                <v-list dark class="py-0">
                    <v-list-item @click="$router.push({ name: 'Parameters' })">
                        <v-list-item-action>
                            <v-icon color="white">settings</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('navigation.configuration') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>

        <!-- UP BUTTON -->
        <!-- <v-scale-transition origin="center center">
            <v-btn
                fixed
                dark
                fab
                bottom
                right
                color="primary"
                v-if="scrolled"
                @click="$vuetify.goTo('#app')"
            >
                <v-icon x-large>keyboard_arrow_up</v-icon>
            </v-btn>
        </v-scale-transition> -->

        <!-- PREV BUTTON -->
        <!-- <v-btn
            v-if="$route.name != 'Home'"
            fixed
            dark
            fab
            bottom
            left
            color="primary"
            @click="goBack"
        >
            <v-icon x-large>keyboard_arrow_left</v-icon>
        </v-btn> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            drawer: false,
            ready: false,
            // scrolled: false,
            from: null,
            submenu: false,
            showLang: false,
            new_issues_count: 0,
        }
    },
    computed: {
        user(){
            return this.$store.state.auth.profile;
        }
    },
    mounted() {
        this.ready = true
        // window.onscroll = e => this.onScroll(e)
        if (localStorage.getItem('theme.dark') !== null)
            this.$vuetify.theme.dark = (localStorage.getItem('theme.dark') == 'true')
        if (localStorage.getItem('i18n.locale') !== null)
            this.$i18n.locale = localStorage.getItem('i18n.locale')

        this.checkNewIssues()
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.from = from
        })
    },
    methods: {
        logout(){
            this.$store.dispatch('authLogout')
                .then(()=>{
                    this.$router.push('/login')
                })
        },
        // onScroll(e) {
        //     if (window.pageYOffset > 70) {
        //         this.scrolled = true
        //     } else {
        //         this.scrolled = false
        //     }
        // },
        // goBack() {
        //     let from = this.$store.getters.getFromRoute()
        //     if (['EditWorkshop', 'CreateWorkshop'].indexOf(from.name) >= 0) {
        //         this.$router.go(-2)
        //     } else {
        //         this.$router.go(-1)
        //     }
        // },
        changeTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            localStorage.setItem('theme.dark', this.$vuetify.theme.dark)
        },
        changeLocal(lang) {
            this.$i18n.locale  = lang
            localStorage.setItem('i18n.locale', lang)
        },
        checkNewIssues() {
            this.new_issues_count = 0

            let queries = {
                search: JSON.stringify({status: 'new'}),
                per_page: -1,
            }
            this.$store.dispatch('issuesRequest', queries)
                .then((result) => {
                    this.new_issues_count = result.meta.total
                })
                .catch((err) => {
                })
        },
    }
}
</script>
