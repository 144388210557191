<template>
    <v-content class="create-user">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon x-large class="mr-5">{{ $t('user.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.users'), to: {name: 'Users'}},
                        {text: $t('create')}
                    ]"/>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row dense>
                <v-col cols="12">
                    <UserForm ref="UserForm"/>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>
<script>
import UserForm from '@/components/user/Form'
export default {
    components: {
        UserForm,
    },
    data() {
        return {
            // 
        }
    },
    mounted() {
        // 
    },
    methods: {
        // 
    }
}
</script>
