<template>
    <v-data-table
        :headers="[
            {text: '', align: 'start', value: 'actions', class: classes},
            {text: $t('story.ref'), align: 'start', value: 'ref', class: classes},
            {text: $t('story.as'), align: 'start', value: 'as', class: classes},
            {text: $t('story.be_able_to'), align: 'start', value: 'be_able_to', class: classes},
            {text: $t('story.so_that'), align: 'start', value: 'so_that', class: classes},
            {text: $t('story.estimate'), align: 'start', value: 'estimate', class: classes},
            {text: '',  value: 'data-table-expand', align: 'end', class: classes},
        ]"
        disable-sort
        :items="value"
        :items-per-page="-1"
        item-key="id"
        hide-default-footer
        no-data-text="No stories"
        show-expand
        :class="'mobile '+classes"
    >
        <template v-slot:body="{ items, expand, isExpanded, headers }">
            <draggable 
                :list="Object.assign([], value)"
                @end="updateStoriesIndex"
                tag="tbody"
                handle=".story-drag"
                draggable="tr"
                group="stories"
                :data-feature-id="feature.id"
                :data-capability-id="feature.capability_id"
            >
                <template v-for="item in items">
                    <tr :key="'story'+item.id">
                        <td class="text-center text-no-wrap">
                            <RemoveButton
                                depressed
                                text
                                small
                                @confirmed="remove(item)"
                            />
                            <DuplicateButton
                                form="StoryForm"
                                :item="item"
                                depressed
                                text
                                small
                                @duplicated="duplicated"
                            />
                            <v-icon class="story-drag">drag_indicator</v-icon>
                        </td>
                        <td>
                            <v-edit-dialog 
                                @save="submit(item, 'refInput'+item.id)"
                                :ref="'refInput'+item.id"
                                :return-value.sync="item.ref"
                            >
                                #{{ item.ref }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="item.ref"
                                        :label="$t('story.ref')"
                                        single-line
                                    ></v-text-field>
                                    <div class="d-flex mb-2">
                                        <v-btn text depressed small @click="$refs['refInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn text depressed small @click="submit(item, 'refInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                    </div>
                                </template>
                            </v-edit-dialog>
                        </td>
                        <td>
                            <v-edit-dialog 
                                @save="submit(item, 'asInput'+item.id)"
                                :ref="'asInput'+item.id"
                                :return-value.sync="item.as"
                            >
                                {{ item.as }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="item.as"
                                        :label="$t('story.as')"
                                        single-line
                                        :rules="[rules.required]"
                                    ></v-text-field>
                                    <div class="d-flex mb-2">
                                        <v-btn text depressed small @click="$refs['asInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn text depressed small @click="submit(item, 'asInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                    </div>
                                </template>
                            </v-edit-dialog>
                        </td>
                        <td>
                            <v-edit-dialog 
                                @save="submit(item, 'be_able_toInput'+item.id)"
                                :ref="'be_able_toInput'+item.id"
                                :return-value.sync="item.be_able_to"
                            >
                                {{ item.be_able_to }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="item.be_able_to"
                                        :label="$t('story.be_able_to')"
                                        single-line
                                    ></v-text-field>
                                    <div class="d-flex mb-2">
                                        <v-btn text depressed small @click="$refs['be_able_toInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn text depressed small @click="submit(item, 'be_able_toInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                    </div>
                                </template>
                            </v-edit-dialog>
                        </td>
                        <td>
                            <v-edit-dialog 
                                @save="submit(item, 'so_thatInput'+item.id)"
                                :ref="'so_thatInput'+item.id"
                                :return-value.sync="item.so_that"
                            >
                                {{ item.so_that }}
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="item.so_that"
                                        :label="$t('story.so_that')"
                                        single-line
                                    ></v-text-field>
                                    <div class="d-flex mb-2">
                                        <v-btn text depressed small @click="$refs['so_thatInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn text depressed small @click="submit(item, 'so_thatInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                    </div>
                                </template>
                            </v-edit-dialog>
                        </td>
                        <td>
                            <v-edit-dialog 
                                @save="submit(item, 'estimateInput'+item.id)"
                                :ref="'estimateInput'+item.id"
                                :return-value.sync="item.estimate"
                            >
                                <template v-if="item.estimate">{{ item.estimate }} sp</template>
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="item.estimate"
                                        :label="$t('story.estimate')"
                                        single-line
                                    ></v-text-field>
                                    <div class="d-flex mb-2">
                                        <v-btn text depressed small @click="$refs['estimateInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn text depressed small @click="submit(item, 'estimateInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                    </div>
                                </template>
                            </v-edit-dialog>
                        </td>
                        <td>
                            <v-icon :class="classes" @click="expand(item, !isExpanded(item))">{{ isExpanded(item) ? 'remove' : 'keyboard_arrow_down' }}</v-icon>
                        </td>
                    </tr>
                    <tr :key="'storyExp'+item.id" v-if="isExpanded(item)">
                        <td :colspan="headers.length" class="pa-0">
                            <v-card :class="classes">
                                <v-card-text>
                                    <v-row justify="space-between">
                                        <v-col v-if="item.sprint" cols="auto">{{ $t('object.sprint') }}: <v-chip x-small label>{{ item.sprint.name }}</v-chip></v-col>

                                        <v-col cols="auto">
                                            {{ $t('story.priority') }}:
                                            <v-edit-dialog 
                                                @save="submit(item, 'priorityInput'+item.id)"
                                                :ref="'priorityInput'+item.id"
                                                :return-value.sync="item.priority"
                                            >
                                                <v-chip small label>{{ $t('story.'+item.priority) }}</v-chip>
                                                <template v-slot:input>
                                                    <v-select
                                                        v-model="item.priority"
                                                        :items="[
                                                            {text: $t('story.low'), value: 'low'},
                                                            {text: $t('story.medium'), value: 'medium'},
                                                            {text: $t('story.high'), value: 'high'},
                                                        ]"
                                                        :label="$t('story.priority')"
                                                        filled
                                                        :rules="[rules.required]"
                                                    ></v-select>
                                                    <div class="d-flex mb-2">
                                                        <v-btn text depressed small @click="$refs['priorityInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text depressed small @click="submit(item, 'priorityInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                                    </div>
                                                </template>
                                            </v-edit-dialog>
                                        </v-col>

                                        <v-col cols="auto">
                                            {{ $t('object.tags') }}:
                                            <v-edit-dialog 
                                                @save="submit(item, 'tagsInput'+item.id)"
                                                :ref="'tagsInput'+item.id"
                                                :return-value.sync="item.tags"
                                            >
                                                <v-chip small label class="mx-1 my-1" v-for="tag in item.tags" :key="tag.id">{{ tag.name }}</v-chip>
                                                <template v-slot:input>
                                                    <v-combobox
                                                        v-model="item.tags"
                                                        :items="fetchTags"
                                                        :label="$t('object.tags')"
                                                        item-text="name"
                                                        item-value="name"
                                                        multiple
                                                        chips
                                                        deletable-chips
                                                    ></v-combobox>
                                                    <div class="d-flex mb-2">
                                                        <v-btn text depressed small @click="$refs['tagsInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text depressed small @click="submit(item, 'tagsInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                                    </div>
                                                </template>
                                            </v-edit-dialog>
                                        </v-col>

                                        <v-col cols="auto">
                                            {{ $t('story.status') }}:
                                            <v-edit-dialog 
                                                @save="submit(item, 'statusInput'+item.id)"
                                                :ref="'statusInput'+item.id"
                                                :return-value.sync="item.status"
                                            >
                                                <v-chip small label>{{ $t('story.'+item.status) }}</v-chip>
                                                <template v-slot:input>
                                                    <v-select
                                                        v-model="item.status"
                                                        :items="[
                                                            {text: $t('story.draft'), value: 'draft'},
                                                            {text: $t('story.backlog'), value: 'backlog'},
                                                            {text: $t('story.planified'), value: 'planified'},
                                                            {text: $t('story.open'), value: 'open'},
                                                            {text: $t('story.cancel'), value: 'cancel'},
                                                            {text: $t('story.done'), value: 'done'},
                                                            {text: $t('story.waiting'), value: 'waiting'},
                                                        ]"
                                                        :label="$t('story.status')"
                                                        filled
                                                        :rules="[rules.required]"
                                                    ></v-select>
                                                    <div class="d-flex mb-2">
                                                        <v-btn text depressed small @click="$refs['statusInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text depressed small @click="submit(item, 'statusInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                                    </div>
                                                </template>
                                            </v-edit-dialog>
                                        </v-col>

                                        <v-col cols="12">
                                            <span class="grey--text text--darken-2">{{ $t('story.details') }}:</span>
                                            <v-edit-dialog 
                                                @save="submit(item, 'detailsInput'+item.id)"
                                                :ref="'detailsInput'+item.id"
                                                :return-value.sync="item.details"
                                            >
                                                <v-row :class="'subtitle-1 '+( $vuetify.theme.dark ? 'white--text' : 'black--text' )" v-html="item.details"></v-row>
                                                <template v-slot:input>

                                                    <v-textarea
                                                        v-model="item.details"
                                                        filled
                                                        :label="$t('story.details')"
                                                    ></v-textarea>
                                                    <div class="d-flex mb-2">
                                                        <v-btn text depressed small @click="$refs['detailsInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text depressed small @click="submit(item, 'detailsInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                                    </div>
                                                </template>
                                            </v-edit-dialog>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <messages messageable_type="story" :messageable_id="item.id" :key="item.id" :dark="false" :headerClass="classes" :contentClass="classes"/>
                        </td>
                    </tr>
                </template>
            </draggable>
        </template>

    </v-data-table>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    components: {
        draggable,
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        project_id: {
            type: [String, Number],
            required: false,
        },
        feature: {
            type: Object,
            required: false
        },
        classes: {
            type: String,
            default: '',
        },
    },
    data(){
        return {
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
        }
    },
    mounted() {
        // 
    },
    asyncComputed: {
        fetchTags() {
            let queries = {search: JSON.stringify({taggable_type: 'story'}), per_page: -1}
            return this.$store.dispatch('tagsRequest', queries).then(result => result.data)
        },
    }, 
    methods: {
        save(item) {
            this.$store.dispatch('storyEditRequest', {id: item.id, datas: item})
                .then((story) => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.saved')});
                    let i = this.value.findIndex(s => s.id == story.id)
                    if (i >= 0) Object.assign(this.value[i], story)
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        submit(item, ref) {
            this.save(Object.assign({}, item))
            this.$refs[ref][0].cancel()
        },
        remove(item) {
            return this.$store.dispatch('storyDeleteRequest', {id: item.id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.deleted')});
                let i = this.value.findIndex(c => c.id == item.id)
                this.value.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        duplicated(item) {
            this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.saved')});
            if (item.feature_id == this.feature.id)
                this.value.push(item)
            else {
                let fakeEvent = {
                    from: {dataset: {capabilityId: this.feature.capability_id}},
                    to: {dataset: {capabilityId: item.feature.capability_id}},
                    newIndex: 0,
                }
                this.$emit('droppedInFeature', item, fakeEvent)
            }
        },
        updateStoriesIndex(event) {
            let mooved = this.value[event.oldIndex]
            
            // remove in from
            this.value.splice(event.oldIndex, 1)

            if (event.from.dataset.featureId != event.to.dataset.featureId) {
                // if drop in another feature, the parent get the job  
                mooved.feature_id = event.to.dataset.featureId
                this.save(mooved)
                this.$emit('droppedInFeature', mooved, event);
            } else {
                // add in to
                this.value.splice(event.newIndex, 0, mooved)

                let newPos = this.value.map((c) => c.id)
                let queries = {object: 'stories', datas: {positioning: newPos}}

                this.$store.dispatch('updatePositioningRequest', queries)
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }

        },
    }
}
</script>