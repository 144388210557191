<template>
    <v-content class="edit-project">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('project.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.projects'), to: {name: 'Projects'}},
                        {text: project.name, to: {name: 'Project', id: project.id}},
                        {text: $t('update')}
                    ]"/>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-else>
                    <ProjectForm ref="ProjectForm" v-model="project" @savedForm="handleProjectForm" @canceledForm="handleProjectForm"/>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>
<script>
import ProjectForm from '@/components/project/Form'
export default {
    components: {
        ProjectForm,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data() {
        return {
            ready: false,
            project: {},
            loading: false,
        }
    },
    mounted() {
        this.getProject()
    },
    methods: {
        getProject() {
            this.loading = true
            this.$store.dispatch('projectRequest', {id: this.id, with: ['project_manager', 'product_manager', 'service_manager', 'technical_manager', 'partner', 'users']})
                .then(result => {
                    this.project = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        handleProjectForm() {
            this.$router.push({name: 'Project', params: {id: this.id}})
        },
    }
}
</script>
