<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form">
        <v-row>
            <v-col>

                <v-text-field
                    v-model="productUsertype.name"
                    filled
                    :label="$t('productUsertype.name')"
                    :rules="[rules.required]"
                ></v-text-field>

                <wyswyg v-model="productUsertype.motivation" :label="$t('productUsertype.motivation')"/>

                <wyswyg v-model="productUsertype.goals" :label="$t('productUsertype.goals')"/>

                <wyswyg v-model="productUsertype.pain_points" :label="$t('productUsertype.pain_points')"/>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary">{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">{{ $t('save') }}</v-btn>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            productUsertype: Object.assign({}, this.value),
            allProjects: [],
        }
    },
    computed: {
        // 
    },
    mounted() {
        // 
    },
    methods: {
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                let request = 'productUsertypeCreateRequest'
                let queries = this.productUsertype

                if (this.productUsertype.id) {
                    request = 'productUsertypeEditRequest'
                    queries = {id: this.productUsertype.id, datas: this.productUsertype}
                }

                this.$store.dispatch(request, queries)
                    .then((productUsertype) => {
                        this.loading = false;
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('productUsertype.saved')});
                        this.$emit('savedForm', productUsertype);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
    }
}
</script>
