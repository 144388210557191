"use strict";

import Vue from 'vue';
import store from '../vuex/store'
import router from '../router'
import axios from "axios";

const _axios = axios.create({
    baseURL: process.env.VUE_APP_API_URL || ""
})

_axios.interceptors.request.use(
    function(config) {
        if (store.state.auth.profile)
            config.headers.common['Authorization'] = 'Bearer '+ store.state.auth.profile.access_token
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function(response) {
        // Do something with response data
        return response;
    },
    function(error) {
        // Do something with response error
        if (error.response && error.response.status == 401 && store.state.auth.profile) {
            store.dispatch('authLogout').then(() => {
                store.commit('setSnack', {'type': 'success', 'msg': 'Vous êtes déconnecté'});
                location.href = process.env.VUE_APP_PORTAL_URL + '/logout'
            })
        }
        return Promise.reject(error);
    }
);

Plugin.install = function(Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;
