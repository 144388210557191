import Vue from 'vue'
import i18n from '@/plugins/i18n/i18n'

const state = {
    // 
}

const getters = {
    // 
}

const actions = {
    // messageRequest: async ({commit}, queries) => {
    //     let url = `/api/messages/${queries.id}?`+Object.entries(queries).map(e => e.join('=')).join('&')
    //     return new Promise((resolve, reject) => {
    //         Vue.axios.get(url, queries)
    //             .then(result => {
    //                 resolve(result.data)
    //             })
    //             .catch(err => {
    //                 reject(err)
    //             })
    //     })
    // },
    // messageEditRequest: async ({commit}, queries) => {
    //     let url = `/api/messages/${queries.id}`;

    //     return new Promise((resolve, reject) => {
    //         Vue.axios.put(url, queries)
    //             .then(result => {
    //                 commit('setSnack', {'type': 'success', 'msg': i18n.t('message.saved')})
    //                 resolve(result.data.message)
    //             })
    //             .catch(err => {
    //                 commit('setSnack', {'type': 'error', 'msg': err.response.data.message || err.response.data})
    //                 reject(err)
    //             })
    //     })
    // },
    messageCreateRequest: async ({commit}, queries) => {
        let url = `/api/messages`;

        return new Promise((resolve, reject) => {
            Vue.axios.post(url, queries)
                .then(result => {
                    commit('setSnack', {'type': 'success', 'msg': i18n.t('message.saved')})
                    resolve(result.data.message)
                })
                .catch(err => {
                    commit('setSnack', {'type': 'error', 'msg': err.response.data.message || err.response.data})
                    reject(err)
                })
        })
    },
    // messageDeleteRequest: async ({commit}, queries) => {
    //     let url = `/api/messages/${queries.id}`;

    //     return new Promise((resolve, reject) => {
    //         Vue.axios.delete(url)
    //             .then(result => {
    //                 commit('setSnack', {'type': 'success', 'msg': i18n.t('message.deleted')})
    //                 resolve(result)
    //             })
    //             .catch(err => {
    //                 commit('setSnack', {'type': 'error', 'msg': err.response.data.message || err.response.data})
    //                 reject(err)
    //             })
    //     })
    // },
    messagesRequest: async ({commit}, queries) => {
        let url = `/api/messages?`+Object.entries(queries).map(e => e.join('=')).join('&');

        return new Promise((resolve, reject) => {
            Vue.axios.get(url)
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    commit('setSnack', {'type': 'error', 'msg': err.response.data.message || err.response.data})
                    reject(err)
                })
        })
    },
    messageReadRequest: async ({commit}, queries) => {
        let url = `/api/messages/${queries.id}/read`;

        return new Promise((resolve, reject) => {
            Vue.axios.put(url, queries)
                .then(result => {
                    resolve(result.data.message)
                })
                .catch(err => {
                    commit('setSnack', {'type': 'error', 'msg': err.response.data.message || err.response.data})
                    reject(err)
                })
        })
    },
}

const mutations = {
    //
}

export default {
    state,
    getters,
    actions,
    mutations,
}