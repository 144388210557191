<template>
    <div>
        <v-data-table
            disable-sort
            :items="value"
            :items-per-page="-1"
            item-key="id"
            hide-default-footer
            hide-default-header
            no-data-text="No features"
            show-expand
            :class="featuresClass"
        >
            <template v-slot:body="{ items, expand, isExpanded, headers }">
                <draggable 
                    :list="Object.assign([], value)"
                    @end="updateFeaturesIndex"
                    tag="tbody"
                    handle=".feature-drag"
                    draggable="tr"
                    group="features"
                    :data-capability-id="capability_id"
                >
                    <template v-for="item in items">
                        <tr :key="'feat'+item.id">
                            <td>
                                <RemoveButton
                                    depressed
                                    text
                                    small
                                    @confirmed="remove(item)"
                                />
                                <DuplicateButton
                                    form="FeatureForm"
                                    :item="{...item, project_id: null}"
                                    depressed
                                    text
                                    small
                                    @duplicated="duplicated"
                                />
                            </td>
                            <td>
                                <v-icon class="feature-drag">drag_indicator</v-icon>
                            </td>
                            <td>
                                <v-edit-dialog 
                                    @save="submit(item, 'refInput'+item.id)"
                                    :ref="'refInput'+item.id"
                                    :return-value.sync="item.ref"
                                >
                                    #{{ item.ref }}
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="item.ref"
                                            :label="$t('feature.ref')"
                                            single-line
                                        ></v-text-field>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['refInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'refInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-edit-dialog 
                                    @save="submit(item, 'nameInput'+item.id)"
                                    :ref="'nameInput'+item.id"
                                    :return-value.sync="item.name"
                                >
                                    <span class="title">{{ item.name }}</span>
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="item.name"
                                            :label="$t('feature.name')"
                                            single-line
                                        ></v-text-field>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['nameInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'nameInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-edit-dialog 
                                    @save="submit(item, 'descriptionInput'+item.id)"
                                    :ref="'descriptionInput'+item.id"
                                    :return-value.sync="item.description"
                                >
                                    <span v-html="item.description"></span>
                                    <template v-slot:input>
                                        <v-textarea
                                            v-model="item.description"
                                            filled
                                            :label="$t('feature.description')"
                                        ></v-textarea>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['descriptionInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'descriptionInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-edit-dialog 
                                    @save="submit(item, 'commentInput'+item.id)"
                                    :ref="'commentInput'+item.id"
                                    :return-value.sync="item.comment"
                                >
                                    <span v-html="item.comment"></span>
                                    <template v-slot:input>
                                        <v-textarea
                                            v-model="item.comment"
                                            filled
                                            :label="$t('feature.comment')"
                                        ></v-textarea>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['commentInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'commentInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-edit-dialog 
                                    @save="submit(item, 'priorityInput'+item.id)"
                                    :ref="'priorityInput'+item.id"
                                    :return-value.sync="item.priority"
                                >
                                    {{ $t('feature.'+item.priority) }}
                                    <template v-slot:input>
                                        <v-select
                                            v-model="item.priority"
                                            :items="[
                                                {text: $t('feature.low'), value: 'low'},
                                                {text: $t('feature.medium'), value: 'medium'},
                                                {text: $t('feature.high'), value: 'high'},
                                            ]"
                                            :label="$t('feature.priority')"
                                            filled
                                            :rules="[rules.required]"
                                        ></v-select>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['priorityInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'priorityInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-edit-dialog 
                                    @save="submit(item, 'statusInput'+item.id)"
                                    :ref="'statusInput'+item.id"
                                    :return-value.sync="item.status"
                                >
                                    {{ $t('feature.'+item.status) }}
                                    <template v-slot:input>
                                        <v-select
                                            v-model="item.status"
                                            :items="[
                                                {text: $t('feature.draft'), value: 'draft'},
                                                {text: $t('feature.backlog'), value: 'backlog'},
                                                {text: $t('feature.planified'), value: 'planified'},
                                                {text: $t('feature.open'), value: 'open'},
                                                {text: $t('feature.cancel'), value: 'cancel'},
                                                {text: $t('feature.done'), value: 'done'},
                                                {text: $t('feature.waiting'), value: 'waiting'},
                                            ]"
                                            :label="$t('feature.status')"
                                            filled
                                            :rules="[rules.required]"
                                        ></v-select>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['statusInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'statusInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-edit-dialog
                                    @save="submit(item, 'estimateInput'+item.id)"
                                    :ref="'estimateInput'+item.id"
                                    :return-value.sync="item.estimate"
                                >
                                    <v-chip small dark>
                                        <template v-if="stories_estimate[item.id] > 0">
                                            {{ stories_estimate[item.id] }} sp
                                            <!-- <span class="font-italic" v-if="item.estimate > 0">({{ item.estimate }})</span> -->
                                        </template>
                                        <template v-else>
                                            <span class="font-italic" v-if="item.estimate > 0">{{ item.estimate }} sp</span>
                                        </template>
                                    </v-chip>
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="item.estimate"
                                            :label="$t('feature.estimate')"
                                            single-line
                                        ></v-text-field>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['estimateInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'estimateInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-end">
                                <v-icon :class="featuresClass" @click="expand(item, !isExpanded(item))">{{ isExpanded(item) ? 'remove' : 'keyboard_arrow_down' }}</v-icon>
                            </td>
                        </tr>
                        <tr :key="'featExp'+item.id" v-if="isExpanded(item)">
                            <td colspan="10" class="pa-0">
                                <messages messageable_type="feature" :messageable_id="item.id" :key="item.id" :dark="false" :headerClass="featuresClass" :contentClass="featuresClass"/>
                                <StoryIndex 
                                    v-model="item.stories"
                                    :project_id="item.project_id"
                                    :classes="storiesClass"
                                    :feature="item"
                                    @droppedInFeature="changeStoryFeature"
                                />
                                <v-btn
                                    @click="addStory({feature_id: item.id, feature: item, priority: 'medium', status: 'draft'})"
                                    depressed
                                    block
                                    x-large
                                    :class="storiesClass"
                                >
                                    <v-icon>add</v-icon>
                                    {{ $t('add') }} {{ $t('object.story') }}
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </draggable>
            </template>
        </v-data-table>
        <v-dialog v-model="dialogAddStory" max-width="80%" persistent scrollable>
            <v-card>
                <v-card-title class="primary white--text">
                    {{ $t('object.story') }}
                </v-card-title>
                <v-card-text>
                    <StoryForm ref="StoryForm" v-model="editedStory" :key="Date.now()" @savedForm="handleSavedStoryForm" @canceledForm="handleCanceledStoryForm"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import StoryIndex from '@/components/story/Index'
import StoryForm from '@/components/story/Form'
import draggable from 'vuedraggable'
export default {
    components: {
        StoryIndex,
        StoryForm,
        draggable,
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        capability_id: {
            type: [String, Number],
            required: false
        },
        featuresClass: {
            type: String,
            default: '',
        },
        storiesClass: {
            type: String,
            default: '',
        },
    },
    computed: {
        stories_estimate() {
            let res = []
            this.value.forEach((f) => {
                if (! f.stories) f.stories = []
                res[f.id] = f.stories.reduce((acc,s) => acc + parseFloat(s.estimate || 0), 0)
            })
            return res
        }
    },
    asyncComputed: {
        fetchCapabilities() {
            let queries = {}
            if (this.value.length)
                queries = {search: JSON.stringify({project_id: this.value[0].project_id}), per_page: -1}
            return this.$store.dispatch('capabilitiesRequest', queries).then(result => result.data)
        },
    }, 
    data(){
        return {
            dialogAddStory: false,
            editedStory: {},
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
        }
    },
    mounted() {
        // 
    },
    methods: {
        save(item) {
            this.$store.dispatch('featureEditRequest', {id: item.id, datas: item})
                .then((feature) => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('feature.saved')});
                    let i = this.value.findIndex(s => s.id == feature.id)
                    if (i >= 0) Object.assign(this.value[i], feature)
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        submit(item, ref) {
            this.save(Object.assign({}, item))
            this.$refs[ref][0].cancel()
        },
        remove(item) {
            return this.$store.dispatch('featureDeleteRequest', {id: item.id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('feature.deleted')});
                let i = this.value.findIndex(c => c.id == item.id)
                this.value.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        duplicated(item) {
            this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('feature.saved')});
            if (item.capability_id == this.capability_id)
                this.value.push(item)
            else {
                let fakeEvent = {
                    from: {dataset: {capabilityId: this.capability_id}},
                    to: {dataset: {capabilityId: item.capability_id}},
                    newIndex: 0,
                }
                this.$emit('droppedInCapability', item, fakeEvent)
            }
        },
        addStory(story) {
            this.editedStory = story
            this.dialogAddStory = true
        },
        handleCanceledStoryForm() {
            this.editedStory = {}
            this.dialogAddStory = false
        },
        handleSavedStoryForm(story) {
            this.dialogAddStory = false
            let feature = this.value.find(f => f.id == story.feature_id)
            if (feature)
                feature.stories.push(story)
        },
        updateFeaturesIndex(event) {
            let mooved = this.value[event.oldIndex]

            // remove in from
            this.value.splice(event.oldIndex, 1)

            if (event.from.dataset.capabilityId != event.to.dataset.capabilityId) {
                // if drop in another capability, the parent get the job  
                mooved.capability_id = event.to.dataset.capabilityId
                this.save(mooved)
                this.$emit('droppedInCapability', mooved, event.newIndex);
            } else {
                // add in to
                this.value.splice(event.newIndex, 0, mooved)

                let newPos = this.value.map((c) => c.id)
                let queries = {object: 'features', datas: {positioning: newPos}}

                this.$store.dispatch('updatePositioningRequest', queries)
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
        changeStoryFeature(story, event) {
            // remove action is processed in child component (StoryIndex)

            if (event.from.dataset.capabilityId != event.to.dataset.capabilityId) {   
                // if drop in another capability, the parent get the job 
                this.$emit('droppedStoryInCapability', story, event);
            } else {
                // add in to
                let to = this.value.find(f => f.id == story.feature_id)
                to.stories.splice(event.newIndex, 0, story)
                // update stories positions
                let newPos = to.stories.map((s) => s.id)
                let queries = {object: 'stories', datas: {positioning: newPos}}
                this.$store.dispatch('updatePositioningRequest', queries)
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        }
    },
}
</script>