import Vue from 'vue'
import i18n from '@/plugins/i18n/i18n'

const state = () => ({
    profile: localStorage.getItem('profile') ? JSON.parse(localStorage.getItem('profile')) : null,
})

const getters = {
    isAuth: state => {
        return !! state.profile && state.profile.type.find(t => t == 'project')
    }
}

const actions = {
    authRequest: ({commit, dispatch, getters}, payload) => {
        let data = {
            'username': payload.email,
            'password': payload.password,
            'grant_type': 'password',
            'client_id': process.env.VUE_APP_API_CLIENT_ID,
            'client_secret': process.env.VUE_APP_API_CLIENT_SECRET
        }   
        return new Promise((resolve, reject) => {
            Vue.axios.post('/oauth/token', data)
                .then(async (resp) => {
                    await dispatch('profileRequest', resp.data.access_token)
                    if (! getters.isAuth) {
                        dispatch('authLogout')
                        reject({response: {data: 'unauthorized'}})
                    }
                    resolve()
                })
                .catch((err) => {
                    commit('setSnack', {'type': 'error', 'msg': i18n.t('auth.invalid_credentials')})
                    reject(err)
                })
        })
    },
    authLogout: ({commit}) => {
        return new Promise((resolve, reject) => {
            Vue.axios.post('api/logout')
                .then(() => {
                    commit('setSnack', {'type': 'warning', 'msg': i18n.t('auth.disconnected')})
                    commit('profileRemove')
                    resolve()
                })
                .catch((err) => {
                    reject(err)
                });
        })
    },
    profileRequest: async ({commit, dispatch}, access_token) => {
        Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
        await Vue.axios.get('/api/user')
            .then((resp) => {
                let profile = {...resp.data.data, 'access_token': access_token}
                commit('profileSuccess', profile)
            })
            .catch((err) => {
                dispatch('authLogout')
            })
    },
}

const mutations = {
    profileSuccess: (state, profile) => {
        localStorage.setItem('profile', JSON.stringify(profile))
        state.profile = profile
    },
    profileRemove: (state) => {
        localStorage.removeItem('profile')
        state.profile = null
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}