<!-- inspired by: https://www.npmjs.com/package/vue-kanban -->
<template>
    <div class="kanban-container">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row class="flex-column flex-md-row fill-height">
            <v-col v-for="stage in showStages" class="kanban-column" :class="{['kanban-column-' + stage]: true}" :key="stage">

                <v-row class="flex-grow-0" dense>
                    <v-col class="kanban-column-header">
                        <slot :name="stage">
                            <h2>{{ stage }}</h2>
                        </slot>
                    </v-col>
                </v-row>

                <v-row class="kanban-inner-list" dense>
                    <v-row class="kanban-scrollable" ref="list" :data-status="stage" no-gutters align-content="start">
                        <v-col cols="12" class="kanban-item px-2 py-1" v-for="block in getBlocks(stage)" :data-block-id="block.id" :key="block.id">
                            <slot :name="block.id">
                                <strong>{{ block.status }}</strong>
                                <div>{{ block.id }}</div>
                            </slot>
                        </v-col>
                    </v-row>
                </v-row>

                <v-row class="flex-grow-0" dense>
                    <v-col class="kanban-column-footer">
                        <slot :name="`footer-${stage}`"></slot>
                    </v-col>
                </v-row>

            </v-col>

            <v-menu offset-y>
                <template v-slot:activator="{on}">
                    <v-btn
                        absolute
                        fab
                        dark
                        small
                        v-on="on"
                        style="top: 0; right: 0;"
                    >
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </template>

                <slot name="actions">
                    <v-list>
                        <v-list-item v-for="stage in stages" :key="stage" @click="switchStage(stage)">
                            <v-list-item-action>
                                <v-icon v-if="showStages.indexOf(stage) >= 0">done</v-icon>
                            </v-list-item-action>
                            <v-list-item-title>{{ stage }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </slot>
            </v-menu>
        </v-row>
    </div>
</template>

<script>
import dragula from 'dragula';
export default {
    props: {
        stages: {
            type: Array,
            required: true,
        },
        stagesFilter: {
            type: Array,
            default: () => ([]),
        },
        loading: {
            type: Boolean,
            default: false,
        },
        blocks: {
            type: Array,
            required: true,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            // 
        };
    },
    computed: {
        localBlocks() {
            return this.blocks;
        },
        showStages() {
            return this.stages.filter(s => this.stagesFilter.includes(s)) || this.stages
        }
    },
    methods: {
        getBlocks(status) {
            return this.localBlocks.filter(block => block.status === status);
        },
        accepts(block, target, source) {
            return true;
        },
        switchStage(stage) {
            let i = this.stagesFilter.indexOf(stage)
            if (i >= 0) {
                this.stagesFilter.splice(i, 1)
            } else {
                this.stagesFilter.push(stage)
            }
        }
    },
    updated() {
        this.drake.containers = this.$refs.list;
    },
    mounted() {
        if (! this.stagesFilter.length)
            this.stages.forEach(s => this.switchStage(s))

        this.config.accepts = this.config.accepts || this.accepts;
        this.drake = dragula(this.$refs.list, this.config)
        .on('drag', (el) => {
            el.classList.add('is-moving');
        })
        .on('drop', (block, list, source) => {
            let index = 0;
            for (index = 0; index < list.children.length; index += 1) {
                if (list.children[index].classList.contains('is-moving')) break;
            }

            let newState = list.dataset.status;

            this.$emit('update-block', block.dataset.blockId, newState, index);
        })
        .on('dragend', (el) => {
            el.classList.remove('is-moving');

            window.setTimeout(() => {
                el.classList.add('is-moved');
                window.setTimeout(() => {
                    el.classList.remove('is-moved');
                }, 600);
            }, 100);
        });
    },
};
</script>

<style lang="scss">
    $ease-out: all .3s cubic-bezier(0.23, 1, 0.32, 1);

    .kanban-container {
        height: 100%;
        position: relative;
    }

    .kanban-column {
        display: flex;
        flex-direction: column;
    }

    .kanban-inner-list {
        position: relative;
    }

    @media (min-width: 960px) {

        .kanban-scrollable {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
            overflow-y: auto;
        }
    }

    .kanban-column > div {
        padding: 4px;
        background: rgba(black, 0.03);
    }

    .kanban-item {
        transition: $ease-out;
        cursor: pointer;
        &.is-moving {
            font-family: 'PxGrotesk', sans-serif;
            /*transform: scale(1.3);*/
        }

    }


    /* Dragula CSS  */

    .gu-mirror {
        position: fixed !important;
        margin: 0 !important;
        z-index: 9999 !important;
        opacity: 0.8;
        list-style-type: none;
    }

    .gu-hide {
        display: none !important;
    }

    .gu-unselectable {
        -webkit-user-select: none !important;
        -moz-user-select: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
    }

    .gu-transit {
        opacity: 0.2;
    }

</style>