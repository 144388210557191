<template>
    <v-content class="edit-user">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('user.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.users'), to: {name: 'Users'}},
                        {text: user.name, to: {name: 'User', id: user.id}},
                        {text: $t('update')}
                    ]"/>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row dense>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-else>
                    <UserForm ref="UserForm" v-model="user"/>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>
<script>
import UserForm from '@/components/user/Form'
export default {
    components: {
        UserForm,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data() {
        return {
            ready: false,
            user: {},
            loading: false,
        }
    },
    mounted() {
        this.getUser()
    },
    methods: {
        getUser() {
            this.loading = true
            this.$store.dispatch('userRequest', {id: this.id})
                .then(result => {
                    this.user = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
    }
}
</script>
