<template>
    <v-content class="issues" v-if="ready">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon x-large class="mr-5">{{ $t('issue.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.projects'), to: {name: 'Projects'}},
                        {text: project.complete_name, to: {name: 'Project', params: {id: project.id}}},
                        {text: $t('object.issues') },
                    ]"/>
                    <v-spacer/>
                    <v-col cols="auto">
                        <ProjectMenu v-model="project" small/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid :style="{'height': 'calc(100% - 100px)'}">
            <v-row class="fill-height">
                <v-col cols="12">
                    <IssueKanban :project_id="project.id" :stages="stages" :stagesFilter="showStages"/>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>
<script>
import IssueKanban from '@/components/issue/Kanban'
import ProjectMenu from '@/components/project/Menu'
export default {
    components: {
        IssueKanban,
        ProjectMenu,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data () {
        return {
            ready: false,
            project: {},
            loading: false,
            stages: ['new', 'waiting', 'in-progress', 'to-test', 'closed', 'canceled'],
            showStages: ['new', 'waiting', 'in-progress', 'to-test', 'closed'],
        }
    },
    mounted () {
        this.getProject()
    },
    computed: {
        // 
    },
    methods: {
        getProject() {
            this.loading = true
            this.$store.dispatch('projectRequest', {id: this.id})
                .then(result => {
                    this.project = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
    }
}
</script>
