<template>
    <v-app id="app">
        <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet">
        <Snackbar />
        <Navigation/>
        <router-view/>
    </v-app>
</template>

<script>
export default {
    components: {
        // 
    }
}
</script>

<style lang="sass">
    @import "~@/styles/style.scss"
</style>