<template>
    <v-content class="login">
        <v-container fluid>
            <v-row justify="center" class="mt-5">
                <v-col cols="12" :md="6">
                    <v-card>
                        <v-card-text>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="headline">{{ $t('login.login') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-form
                                ref="form"
                                @keyup.native.enter="login"
                            >
                                <v-text-field
                                    v-model="email"
                                    :label="$t('login.email')"
                                    :rules="[rules.required]"
                                ></v-text-field>

                                <v-text-field
                                    v-model="password"
                                    :label="$t('login.password')"
                                    :rules="[rules.required]"
                                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    @click:append="showPassword = !showPassword"
                                ></v-text-field>

                                <v-btn
                                    block
                                    depressed
                                    color="primary"
                                    @click="login"
                                    :disabled="loading" 
                                    :loading="loading"
                                >{{ $t('login.ok') }}</v-btn>

                            </v-form>

                            <v-btn
                                block
                                text
                                small
                                color="secondary"
                                :to="{ name: 'password-reset' }"
                                class="mt-4"
                            >{{ $t('login.forgot_password') }}</v-btn>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
    export default {
        data(){
            return {
                email: '',
                password: '',
                showPassword: false,
                loading: false,
                rules: {
                    required: value => !!value || this.$t('rules.required'),
                },
            }
        },
        methods: {
            login: function () {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    const { email, password } = this;
                    this.$store.dispatch('authRequest', { email, password })
                    .then(() => {
                        this.loading = false
                        this.$router.push('/home')
                    })
                    .catch((err) => {
                        this.loading = false;
                    })
                }
            }
        }
    }
</script>
