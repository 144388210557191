const state = () => ({
    to: '',
    from: '',
    next: '',
})

const getters = {
    getToRoute: state => () => state.to,
    getFromRoute: state => () => state.from,
    getNextRoute: state => () => state.next,
}

const actions = {
    // 
}


const mutations = {
    setToRoute (state, to) { state.to = to },
    setFromRoute (state, from) { state.from = from },
    setNextRoute (state, next) { state.next = next },
}

export default {
    state,
    getters,
    actions,
    mutations,
}