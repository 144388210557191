<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form">
        <v-row>
            <v-col>
                <v-autocomplete
                    v-model="issue.project_id"
                    :items="allProjects"
                    :label="$t('object.project')"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                    filled
                    :disabled="loading" :loading="loading"
                ></v-autocomplete>

                <v-select
                    v-model="issue.priority"
                    :items="[
                        {text: $t('issue.minor'), value: 'minor'},
                        {text: $t('issue.major'), value: 'major'},
                        {text: $t('issue.blocking'), value: 'blocking'},
                    ]"
                    :label="$t('issue.priority')"
                    filled
                    :rules="[rules.required]"
                ></v-select>

                <v-text-field
                    v-model="issue.title"
                    filled
                    :label="$t('issue.title')"
                    :rules="[rules.required]"
                ></v-text-field>

                <wyswyg v-model="issue.description" :label="$t('issue.description')"/>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary">{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">{{ $t('save') }}</v-btn>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            issue: Object.assign({}, this.value),
            allProjects: [],
        }
    },
    computed: {
        // 
    },
    mounted() {
        this.fetchProjects()
        // 
    },
    methods: {
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                let request = 'issueCreateRequest'
                let queries = this.issue

                if (this.issue.id) {
                    request = 'issueEditRequest'
                    queries = {id: this.issue.id, datas: this.issue}
                }

                this.$store.dispatch(request, queries)
                    .then((issue) => {
                        this.loading = false
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('issue.saved')});
                        this.$emit('savedForm', issue);
                    })
                    .catch((err) => {
                        this.loading = false
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
        fetchProjects() {
            this.loading = true
            this.$store.dispatch('projectsRequest', {per_page: -1, with: ['partner']})
                .then((result) => {
                    this.allProjects = result.data
                    this.loading = false
                })
                .catch((err) => {
                })
        },
    }
}
</script>
