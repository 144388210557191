<template>
    <v-content class="password-reset">
        <v-container fluid>
            <v-row justify="center" class="mt-5">
                <v-col cols="12" :md="6">
                    <v-card>
                        <v-card-text>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="headline">{{ $t('password_reset.password_reset') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-form
                                ref="form"
                                v-on:submit.prevent="reset"
                            >
                                <v-text-field
                                    v-model="email"
                                    :label="$t('password_reset.email')"
                                    :rules="[rules.required]"
                                ></v-text-field>

                                <v-btn
                                    block
                                    depressed
                                    color="primary"
                                    @click="reset"
                                    :disabled="loading" 
                                    :loading="loading"
                                >{{ $t('password_reset.ok') }}</v-btn>

                            </v-form>

                            <v-btn
                                block
                                text
                                small
                                color="secondary"
                                :to="{ name: 'login' }"
                                class="mt-4"
                            >{{ $t('password_reset.cancel') }}</v-btn>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
    export default {
        data(){
            return {
                email: '',
                rules: {
                    required: value => !!value || this.$t('rules.required'),
                },
                loading: false,
            }
        },
        methods: {
            reset: function () {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    const { email } = this;
                    this.$store.dispatch('resetPassword', { email })
                    .then(() => {
                        this.loading = false
                        this.$router.push('/login')
                    })
                    .catch((err) => {
                        this.loading = false;
                    })
                }
            }
        }
    }
</script>
