import Vue from 'vue'
import './plugins/axios'
import vuetify from './plugins/vuetify'
import './plugins/moment'
import './plugins/file-manager'
import i18n from './plugins/i18n/i18n'
import App from './App.vue'
import router from './router'
import store from './vuex/store'


import Navigation from './components/Navigation.vue'
Vue.component('Navigation', Navigation)

import Snackbar from './components/Snackbar.vue'
Vue.component('Snackbar', Snackbar)

import RemoveButton from './components/RemoveButton.vue'
Vue.component('RemoveButton', RemoveButton)

import DuplicateButton from './components/DuplicateButton.vue'
Vue.component('DuplicateButton', DuplicateButton)

import Messages from './components/Messages.vue'
Vue.component('Messages', Messages)

import NoData from './components/NoData.vue'
Vue.component('no-data', NoData)

import Breadcrumbs from './components/Breadcrumbs.vue'
Vue.component('Breadcrumbs', Breadcrumbs)

import BtnUpload from './components/BtnUpload.vue'
Vue.component('BtnUpload', BtnUpload)

import KanbanBoard from './components/KanbanBoard.vue'
Vue.component('KanbanBoard', KanbanBoard)

import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed)

import EditDialog from './components/EditDialog.vue'
Vue.component('edit-dialog', EditDialog)

import Wyswyg from './components/Wyswyg.vue'
Vue.component('wyswyg', Wyswyg)

Vue.config.productionTip = false

// temp debug to v-calendar component
Vue.config.warnHandler = (message, vm, componentTrace) => {
    if (message === "The .native modifier for v-on is only valid on components but it was used on <div>.") {
        message = null
        vm = null
        componentTrace = null
    }
};

new Vue({
    vuetify,
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')
