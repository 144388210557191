import Vue from 'vue'
import VueI18n from 'vue-i18n'
import fr from './fr'
import en from './en'

Vue.use(VueI18n)

const messages = {
    fr: fr,
    en: en,
}

export default new VueI18n({
    locale: 'fr',
    fallbackLocale: 'en',
    messages,
    silentFallbackWarn: true,
    // silentTranslationWarn: true,
})