<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form">
        <v-row>
            <v-col>
                <v-autocomplete
                    v-model="capability.project_id"
                    :items="allProjects"
                    :label="$t('object.project')"
                    :item-text="p => p.complete_name"
                    item-value="id"
                    :rules="[rules.required]"
                    filled
                ></v-autocomplete>

                <v-text-field
                    v-model="capability.name"
                    filled
                    :label="$t('capability.name')"
                    :rules="[rules.required]"
                ></v-text-field>

                <v-text-field
                    v-model="capability.ref"
                    filled
                    :label="$t('capability.ref')"
                ></v-text-field>

                <wyswyg v-model="capability.description" :label="$t('capability.description')"/>
                <wyswyg v-model="capability.profits" :label="$t('capability.profits')"/>
            </v-col>
        </v-row>

        <v-row v-if="capability.original_id">
            <v-col cols="12" :md="6">
                <p>{{ $t('duplication.relationships') }}:</p>
                <v-switch 
                    v-if="! capability.duplicate_relationships.includes('features.stories')"
                    v-model="capability.duplicate_relationships"
                    :label="$t('object.features')"
                    value="features"
                ></v-switch>
                <v-switch 
                    v-if="! capability.duplicate_relationships.includes('features')"
                    v-model="capability.duplicate_relationships"
                    :label="$t('object.features')+' & '+$t('object.stories')"
                    value="features.stories"
                ></v-switch>
            </v-col>
            <v-col cols="12" :md="6">
                <p>{{ $t('duplication.reset_relationships_fields') }}:</p>
                <v-switch 
                    v-model="capability.reset_relationships_fields"
                    :label="$t('feature.ref')"
                    value='{"name": "ref", "method": "uniqid()"}'
                ></v-switch>
                <v-switch 
                    v-model="capability.reset_relationships_fields"
                    :label="$t('story.estimate')"
                    value='{"name": "estimate", "method": "null"}'
                ></v-switch>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary">{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">{{ $t('save') }}</v-btn>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            capability: Object.assign({}, this.value),
            allProjects: [],
        }
    },
    computed: {
        // 
    },
    mounted() {
        this.fetchProjects()
    },
    methods: {
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                let request = 'capabilityCreateRequest'
                let queries = this.capability

                if (this.capability.id) {
                    request = 'capabilityEditRequest'
                    queries = {id: this.capability.id, datas: this.capability}
                }

                this.$store.dispatch(request, queries)
                    .then((capability) => {
                        this.loading = false;
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('capability.saved')});
                        if (! capability.features) capability.features = []
                        this.$emit('savedForm', capability);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
        fetchProjects() {
            this.$store.dispatch('projectsRequest', {per_page: -1})
                .then((result) => {
                    this.allProjects = result.data
                })
                .catch((err) => {
                })
        },
    }
}
</script>
