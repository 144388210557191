<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form">
        <v-row justify="center">
            <v-col>
                <v-autocomplete
                    v-model="event.project_id"
                    :items="allProjects"
                    :label="$t('object.project')"
                    :item-text="p => p.complete_name"
                    item-value="id"
                    filled
                    :disabled="loading" :loading="loading"
                    clearable
                    hide-details
                    class="mb-3"
                ></v-autocomplete>

                <v-text-field
                    v-model="event.title"
                    filled
                    :label="$t('event.title')"
                    :rules="[rules.required]"
                    hide-details
                    class="mb-3"
                ></v-text-field>

                <v-textarea
                    v-model="event.description"
                    filled
                    auto-grow
                    :label="$t('event.description')"
                    hide-details
                    class="mb-3"
                ></v-textarea>

                <v-row dense justify="center">
                    <v-col cols="auto">
                        <v-text-field
                            v-model="startDay"
                            filled
                            :label="$t('event.start_day')"
                            :rules="[rules.required]"
                            type="date"
                            hide-details
                            class="mb-3"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="startTime"
                            filled
                            :label="$t('event.start_time')"
                            :rules="[rules.required]"
                            type="time"
                            hide-details
                            class="mb-3"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                        <v-text-field
                            v-model="endDay"
                            filled
                            :label="$t('event.end_day')"
                            :rules="[rules.required]"
                            type="date"
                            hide-details
                            class="mb-3"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="endTime"
                            filled
                            :label="$t('event.end_time')"
                            :rules="[rules.required]"
                            type="time"
                            hide-details
                            class="mb-3"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-text-field
                    v-model="event.place"
                    filled
                    :label="$t('event.place')"
                    hide-details
                    class="mb-3"
                ></v-text-field>

                <v-autocomplete
                    v-model="event.users"
                    :items="allUsers"
                    item-text="name"
                    item-value="id"
                    :label="$t('event.users')"
                    multiple
                    chips
                    filled
                    deletable-chips
                    hide-details
                    class="mb-3"
                ></v-autocomplete>

                <v-combobox
                    v-model="attendees"
                    :items="event.attendees"
                    :label="$t('event.attendees')"
                    item-text="email"
                    item-value="email"
                    multiple
                    chips
                    deletable-chips
                    return-object
                    filled
                    hide-details
                >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            label
                        >
                            {{ item.email }}
                            <v-icon v-if="item.status == 'accepted'" right>thumb_up</v-icon>
                            <v-icon v-if="item.status == 'declined'" right>thumb_down</v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:item="{ index, item }">
                        {{ item.email }}
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop>
                            <v-select
                                v-model="item.status"
                                :items="[
                                    {text: $t('event.attendee.waiting'), value: 'waiting'},
                                    {text: $t('event.attendee.accepted'), value: 'accepted'},
                                    {text: $t('event.attendee.declined'), value: 'declined'},
                                ]"
                                :label="$t('event.attendee.status')"
                                hide-details
                                outlined
                            ></v-select>
                        </v-list-item-action>
                    </template>
                </v-combobox>

                <v-switch 
                    v-if="event.id"
                    v-model="event.sendEventRequestNotification"
                    :label="$t('event.resend')"
                    hide-details
                    class="mb-3"
                ></v-switch>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary">{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">{{ $t('save') }}</v-btn>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
            },
            event: Object.assign({}, this.value),
            attendees: Object.assign([], this.value.attendees),
            allProjects: [],
            allUsers: [],
            startDay: null,
            endDay: null,
            startTime: null,
            endTime: null,
        }
    },
    computed: {
        // 
    },
    watch: {
        attendees(val, prev) {
            if (val.length === prev.length) return
            this.attendees = val.map(a => typeof a === 'string' ? {email: a, status: 'waiting'} : a)
            this.event.attendees = this.attendees
        }
    },
    mounted() {
        if (this.event.start) {
            this.startDay = this.$moment(this.event.start).format('YYYY-MM-DD')
            this.startTime = this.$moment(this.event.start).format('HH:mm')
        }
        if (this.event.end) {
            this.endDay = this.$moment(this.event.end).format('YYYY-MM-DD')
            this.endTime = this.$moment(this.event.end).format('HH:mm')
        }
        this.event.users = this.event.users.map(u => u.id || u)
        this.fetchProjects()
        this.fetchUsers()
    },
    methods: {
        onSubmit () {
            this.event.start = this.startDay+' '+this.startTime+':00'
            this.event.end = this.endDay+' '+this.endTime+':00'

            if (this.$refs.form.validate()) {
                this.loading = true

                let request = 'eventCreateRequest'
                let queries = this.event

                if (this.event.id) {
                    request = 'eventEditRequest'
                    queries = {id: this.event.id, datas: queries}
                }

                this.$store.dispatch(request, queries)
                    .then((event) => {
                        this.loading = false
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('event.saved')});
                        this.$emit('savedForm', event);
                    })
                    .catch((err) => {
                        this.loading = false
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
        fetchProjects() {
            this.loading = true
            this.$store.dispatch('projectsRequest', {per_page: -1, with: []})
                .then((result) => {
                    this.allProjects = result.data
                    this.loading = false
                })
                .catch((err) => {
                })
        },
        fetchUsers() {
            let queries = {per_page: -1}
            this.$store.dispatch('usersRequest', queries)
                .then((result) => {
                    this.allUsers = result.data
                })
                .catch((err) => {
                })
        },
    }
}
</script>
