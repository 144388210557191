<template>
    <v-content class="product-backlog">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('project.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.projects'), to: {name: 'Projects'}},
                        {text: project.complete_name, to: {name: 'Project', params: {id: project.id}}},
                        {text: $t('project.product_backlog')},
                    ]"/>
                    <v-spacer/>
                    <v-col cols="auto">
                        <ProjectMenu v-model="project" small/>
                        -
                        <v-btn
                            @click="dialogPrintBacklog = true"
                            class="mx-2"
                            text
                            fab
                            small
                        >
                            <v-icon>print</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row dense>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <v-card>
                        <v-card-title>
                            {{ $t('project.product_backlog') }}
                            <v-spacer/>
                            <div>
                                <v-icon class="ml-2" color="grey darken-3">lens</v-icon> {{ $t('object.capabilities') }} <small>({{ capabilitiesCount }})</small>
                                <v-icon class="ml-2" color="grey darken-1">lens</v-icon> {{ $t('object.features') }} <small>({{ featuresCount }})</small>
                                <v-icon class="ml-2 white" color="black">panorama_fish_eye</v-icon> {{ $t('object.stories') }} <small>({{ storiesCount }})</small>
                            </div>
                            <v-spacer/>
                            <v-chip class="mr-5" dark>{{ estimate }} sp</v-chip>
                        </v-card-title>
                        <v-card-text>
                            <CapabilityIndex 
                                v-model="project.capabilities"
                                capabilitiesClass="no-hover grey darken-3 white--text"
                                featuresClass="no-hover grey darken-1 white--text"
                                storiesClass="no-hover white black--text"
                            />
                            <v-btn
                                @click="addCapability"
                                depressed
                                block
                                x-large
                                class="grey darken-3 white--text"
                            >
                                <v-icon>add</v-icon>
                                {{ $t('add') }} {{ $t('object.capability') }}
                            </v-btn>

                            <v-dialog v-model="dialogAddCapability" max-width="80%" persistent scrollable>
                                <v-card>
                                    <v-card-title class="secondary white--text">
                                        {{ $t('object.capability') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <CapabilityForm v-model="editedCapability" :key="Date.now()" @savedForm="handleSavedCapabilityForm" @canceledForm="handleCanceledCapabilityForm"/>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>

                            <div class="text-center mt-4">
                                <v-btn
                                    depressed
                                    color="secondary"
                                    @click="dialogImportStories = true"
                                >
                                    {{ $t('import') }} {{ $t('object.stories') }}
                                </v-btn>
                            </div>

                            <v-dialog v-model="dialogImportStories" max-width="900px" persistent scrollable>
                                <v-card>
                                    <v-card-text>
                                        <v-form v-on:submit.prevent="importStories" ref="form">
                                            <v-row>
                                                <v-col>
                                                    <v-file-input 
                                                        v-model="imported.file" 
                                                        label="Fichier"
                                                        outlined
                                                        :rules="[rules.required]"
                                                    ></v-file-input>
                                                </v-col>
                                            </v-row>
                                            <v-row v-if="importedHelp">
                                                <v-col>
                                                    <p>Format du fichier:</p>
                                                    <code>feature_ref,ref,as,estimate,be_able_to,details,so_that
                                                        <br> "FEAT1";"25";"utilisateur";;"je peux importer des 'stories', mais pas de 'features'";;"gagner du temps"
                                                        <br> "FEAT2";"36";"système";"0.5";"j'affiche un message en cas d'erreur";"champs : 
                                                        <br>- numéro de ligne
                                                        <br>- champ en erreur";"ne pas faire d'erreur"
                                                        <br> "FEAT1";"26";"utilisateur";;"je peux cliquer sur un bouton Annuler";;"d'annuler l'import"
                                                        <br>...
                                                    </code>
                                                    <p>Les champs non requis sont: <code>estimate</code>, <code>details</code>, <code>priority</code>, <code>status</code></p>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn @click="importedHelp = ! importedHelp" depressed color="secondary">
                                            Aide
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="dialogImportStories = false" depressed color="secondary">
                                            Annuler
                                        </v-btn>
                                        <v-btn @click="importStories" depressed color="primary">
                                            Importer
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <Lexicons v-if="ready" :project_id="project.id"/>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <messages v-if="ready" messageable_type="project" :messageable_id="project.id"/>
                </v-col>
            </v-row>

            <v-dialog v-model="dialogPrintBacklog" max-width="80%" persistent scrollable>
                <v-card>
                    <v-card-title class="secondary white--text">
                        {{ $t('printParams') }}
                    </v-card-title>
                    <v-card-text>
                        <v-form v-on:submit.prevent="printBacklog">
                            <v-switch v-model="printParams.showEstimate" 
                                :label="`${$t('project.estimate')}: ${printParams.showEstimate ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>
                            <v-switch v-model="printParams.showPricing" 
                                :label="`${$t('pricing')}: ${printParams.showPricing ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>
                            <v-switch v-model="printParams.showStories" 
                                :label="`${$t('object.stories')}: ${printParams.showStories ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>
                            <v-select
                                v-model="printParams.statusStories"
                                v-if="printParams.showStories"
                                :items="[
                                    {text: $t('story.draft'), value: 'draft'},
                                    {text: $t('story.backlog'), value: 'backlog'},
                                    {text: $t('story.planified'), value: 'planified'},
                                    {text: $t('story.open'), value: 'open'},
                                    {text: $t('story.cancel'), value: 'cancel'},
                                    {text: $t('story.done'), value: 'done'},
                                    {text: $t('story.waiting'), value: 'waiting'},
                                ]"
                                :label="$t('story.status')"
                                filled
                                multiple
                            ></v-select>
                            <v-switch v-model="printParams.showSprintZero" 
                                :label="`${$t('project.show_sprintzero')}: ${printParams.showSprintZero ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>
                            <v-switch v-model="printParams.showSynthesis" 
                                :label="`${$t('project.show_synthesis')}: ${printParams.showSynthesis ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>

                            <v-row justify="center" class="mt-5">
                                <v-btn @click.stop="dialogPrintBacklog = false" text color="secondary" x-large>{{ $t('cancel') }}</v-btn>
                                <v-btn color="primary" type="submit" :loading="loadingBacklogPrint" x-large>{{ $t('print') }}</v-btn>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </v-content>
</template>

<script>
import Lexicons from '@/components/Lexicons'
import CapabilityForm from '@/components/capability/Form'
import CapabilityIndex from '@/components/capability/Index'
import ProjectMenu from '@/components/project/Menu'
export default {
    components: {
        Lexicons,
        CapabilityForm,
        CapabilityIndex,
        ProjectMenu,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data () {
        return {
            ready: false,
            project: {},
            loading: false,
            loadingBacklogPrint: false,
            dialogPrintBacklog: false,
            printParams: {
                showEstimate: 1,
                showPricing: 1,
                showStories: 1,
                showSprintZero: 1,
                showSynthesis: 1,
                statusStories: ['draft', 'backlog', 'planified', 'open', 'cancel', 'done', 'waiting'],
            },
            dialogAddCapability: false,
            editedCapability: {},
            rules: {
                required: value => !!value || 'Champ requis',
            },
            dialogImportStories: false,
            imported: {'project_id': this.project_id},
            importedHelp: false,
        }
    },
    mounted () {
        this.getProject()
    },
    computed: {
        estimate() {
            let res = 0
            this.project.capabilities.forEach(function(c) {
                c.features.forEach(function(f) {
                    f.stories_estimate = f.stories.reduce((acc,s) => acc + parseFloat(s.estimate || 0), 0)
                })
                res += c.features.reduce((acc, f) => acc + parseFloat(f.stories_estimate || f.estimate || 0), 0)
            })
            return res
        },
        capabilitiesCount() {
            return this.project.capabilities.length
        },
        featuresCount() {
            return this.project.capabilities.reduce((acc, c) => acc + parseInt(c.features.length), 0)
            
        },
        storiesCount() {
            return this.project.capabilities.reduce((acc1, c) => acc1 + parseInt(c.features.reduce((acc2, f) => acc2 + f.stories.length, 0)), 0)
        },
    },
    methods: {
        getProject() {
            this.loading = true
            this.$store.dispatch('projectRequest', {id: this.id, with: ['capabilities.features.stories']})
                .then(result => {
                    this.project = result.data
                    if (! this.project.capabilities) this.project.capabilities = []
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        printBacklog() {
            this.loadingBacklogPrint = true
            let queries = {
                id: this.project.id,
                datas: this.printParams,
            }
            let filename = '[' + this.project.complete_name + '] ProductBacklog.pdf'
            return this.$store.dispatch('projectPrintBacklogRequest', queries)
                .then((data) => {
                    const url = window.URL.createObjectURL(new Blob([data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                    this.loadingBacklogPrint = false
                });
        },
        addCapability() {
            this.editedCapability = {project_id: this.project_id}
            this.dialogAddCapability = true
        },
        handleCanceledCapabilityForm() {
            this.editedCapability = {}
            this.dialogAddCapability = false
        },
        handleSavedCapabilityForm(capability) {
            this.dialogAddCapability = false
            this.project.capabilities.push(capability)
        },
        importStories () {
            if (this.$refs.form.validate()) {
                const datas = new FormData();
                datas.append('project_id', this.imported.project_id)
                datas.append('file', this.imported.file)

                this.$store.dispatch('storiesImportRequest', datas)
                    .then((workshop) => {
                        this.$refs.form.resetValidation()
                        this.dialogImportStories = false
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.imported')});
                        this.$router.go({name: 'Users'})
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
    }
}
</script>