<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form">
        <v-row>
            <v-col>
                <wyswyg v-model="productVision.description" :label="$t('productVision.description')"/>

                <wyswyg v-model="productVision.target" :label="$t('productVision.target')"/>

                <wyswyg v-model="productVision.needs" :label="$t('productVision.needs')"/>

                <wyswyg v-model="productVision.product" :label="$t('productVision.product')"/>

                <wyswyg v-model="productVision.goals" :label="$t('productVision.goals')"/>

            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary">{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">{{ $t('save') }}</v-btn>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            productVision: Object.assign({}, this.value),
            allProjects: [],
        }
    },
    computed: {
        // 
    },
    mounted() {
        // 
    },
    methods: {
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                let queries = {id: this.productVision.id, datas: this.productVision}

                this.$store.dispatch('productVisionEditRequest', queries)
                    .then((productVision) => {
                        this.loading = false;
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('productVision.saved')});
                        this.$emit('savedForm', productVision);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
    }
}
</script>
