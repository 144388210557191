<style scoped>
    .v-data-table >>> td {
        vertical-align: top;
        padding: 16px;
    }
    .v-data-table >>> .remove-btn {
        margin-top: -10px;
    }
</style>
<template>
    <div>
        <draggable 
            :list="Object.assign([], value)"
            @end="updateCapabilitiesIndex"
            tag="div"
            handle=".capability-drag"
            draggable=".row"
            group="capabilities"
        >
            <v-row no-gutter v-for="(capability,index) in value" :key="'capability_'+capability.id" flat align="start">
                <v-col cols="12" :md="showFeatures[capability.id] ? 2 : 12" style="position:sticky;top:0;">
                    <div :class="{'d-flex primary--text': true, 'flex-column': showFeatures[capability.id], 'align-center': ! showFeatures[capability.id]}">
                        <div class="mr-5">
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="capability"
                                field="ref"
                                :label="$t('capability.ref')"
                                type="string"
                                required
                            >{{ '#'+capability.ref }}</edit-dialog>
                        </div>
                        <div class="flex-grow-1">
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="capability"
                                field="name"
                                :label="$t('capability.name')"
                                type="string"
                                required
                                size="600"
                            >
                                <span class="headline">{{ capability.name }}</span>
                            </edit-dialog>
                        </div>
                        <div :class="{'ml-auto': !showFeatures[capability.id], 'text-center': showFeatures[capability.id]}">
                            <div class="text-center my-2">
                                <v-chip small dark>
                                    {{ capability.estimate ? capability.estimate : '-' }} sp
                                </v-chip>
                            </div>
                        </div>
                    </div>

                    <div :class="{'d-flex': true, 'flex-column': showFeatures[capability.id]}">
                        <div class="mb-3 flex-grow-1">
                            <p class="mb-2 mt-5">{{ $t('capability.description') }}:</p>
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="capability"
                                field="description"
                                :label="$t('capability.description')"
                                type="wyswyg"
                                size="600"
                            >
                                <span v-html="capability.description"></span>
                            </edit-dialog>    
                        </div>
                        <div class="mb-3 flex-grow-1">
                            <p class="mb-2 mt-5">{{ $t('capability.profits') }}:</p>
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="capability"
                                field="profits"
                                :label="$t('capability.profits')"
                                type="wyswyg"
                                size="600"
                            >
                                <span v-html="capability.profits"></span>
                            </edit-dialog>
                        </div>
                    </div>

                    <div class="d-flex flex-wrap justify-center">
                        <v-btn text small fab class="d-inline-block" @click="addFeature({capability_id: capability.id, priority: 'medium', status: 'draft', project_id: capability.project_id})">
                            <v-icon>add</v-icon>
                        </v-btn>

                        <DuplicateButton form="CapabilityForm" :item="capability" text small @duplicated="duplicated"/>

                        <v-btn text small fab class="d-inline-block">
                            <v-icon class="capability-drag">drag_indicator</v-icon>
                        </v-btn>

                        <RemoveButton text small @confirmed="remove(capability)"/>

                        <v-btn text small fab @click="toggleShowFeatures(capability.id)">
                            <v-icon v-if="showFeatures[capability.id]">keyboard_arrow_right<!-- format_indent_decrease --></v-icon>
                            <v-icon v-else>keyboard_arrow_left<!-- format_indent_increase --></v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <!-- <v-slide-x-transition> -->
                <v-col v-if="showFeatures[capability.id]" cols="12" :md="10">
                    <FeatureIndexV2 
                        v-model="capability.features" 
                        :capability_id="capability.id" 
                        @droppedInCapability="changeFeatureCapability"
                        @droppedStoryInCapability="changeStoryCapability"
                        @selectedStories="selectedStories"
                    />
                        <!-- :filters="filters" -->
                </v-col>
                <!-- </v-slide-x-transition> -->
                <v-col cols="12" v-if="index != value.length-1">
                    <hr/>
                </v-col>
            </v-row>
        </draggable>

        <v-dialog v-model="dialogAddFeature" max-width="80%" persistent scrollable>
            <v-card>
                <v-card-title class="primary white--text">
                    {{ $t('object.feature') }}
                </v-card-title>
                <v-card-text>
                    <FeatureForm ref="FeatureForm" v-model="editedFeature" :key="Date.now()" @savedForm="handleSavedFeatureForm" @canceledForm="handleCanceledFeatureForm"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FeatureIndexV2 from '@/components/feature/IndexV2'
import FeatureForm from '@/components/feature/Form'
import draggable from 'vuedraggable'
export default {
    components: {
        FeatureIndexV2,
        FeatureForm,
        draggable,
    },
    props: {
        value: {type: Array, required: true},
        // filters: {type: Object, default: () => ({}),},
    },
    computed: {
        capabilities_estimate() {
            let res = []
            this.value.forEach(function(c) {
                c.features.forEach(function(f) {
                    f.stories_estimate = f.stories.reduce((acc,s) => acc + parseFloat(s.estimate || 0), 0)
                })
                res[c.id] = c.features.reduce((acc, f) => acc + parseFloat(f.stories_estimate || f.estimate || 0), 0)
                c.estimate = res[c.id]
            })
            return res
        },
    },
    data(){
        return {
            dialogAddFeature: false,
            editedFeature: {},
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            showFeatures: [],
        }
    },
    methods: {
        save(item) {
            this.$store.dispatch('capabilityEditRequest', {id: item.id, datas: item})
                .then((capability) => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('capability.saved')});
                    let i = this.value.findIndex(s => s.id == capability.id)
                    if (i >= 0) Object.assign(this.value[i], capability)
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        remove(item) {
            return this.$store.dispatch('capabilityDeleteRequest', {id: item.id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('capability.deleted')});
                let i = this.value.findIndex(c => c.id == item.id)
                this.value.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        selectedStories(newVal, oldVal) {
            this.$emit('selectedStories', newVal, oldVal)
        },
        addFeature(feature) {
            this.editedFeature = feature
            this.dialogAddFeature = true
        },
        handleCanceledFeatureForm() {
            this.editedFeature = {}
            this.dialogAddFeature = false
        },
        handleSavedFeatureForm(feature) {
            this.dialogAddFeature = false
            let capability = this.value.find(c => c.id == feature.capability_id)
            if (capability)
                capability.features.push(feature)
        },
        updateCapabilitiesIndex(event) {
            let mooved = this.value[event.oldIndex]
            this.value.splice(event.oldIndex, 1)
            this.value.splice(event.newIndex, 0, mooved)

            let newPos = this.value.map((c) => c.id)
            let queries = {object: 'capabilities', datas: {positioning: newPos}}

            this.$store.dispatch('updatePositioningRequest', queries)
                .then(() => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                })
        },
        duplicated(item) {
            this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('capability.saved')});
            if (item.project_id == this.value[0].project_id)
                this.value.push(item)
        },
        changeFeatureCapability(feature, position) {
            // remove action is processed in child component (FeatureIndex)
            // add in to
            let to = this.value.find(c => c.id == feature.capability_id)
            to.features.splice(position, 0, feature)
            // update features positions
            let newPos = to.features.map((f) => f.id)
            let queries = {object: 'features', datas: {positioning: newPos}}
            this.$store.dispatch('updatePositioningRequest', queries)
                .then(() => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                })
        },
        changeStoryCapability(story, event) {
            // remove action is processed in child component (FeatureIndex)
            // add in to
            let to_capabilty = this.value.find(c => c.id == event.to.dataset.capabilityId)
            let to_feature = to_capabilty.features.find(f => f.id == story.feature_id)
            to_feature.stories.splice(event.newIndex, 0, story)
            // update stories positions
            let newPos = to_feature.stories.map((s) => s.id)
            let queries = {object: 'stories', datas: {positioning: newPos}}
            this.$store.dispatch('updatePositioningRequest', queries)
                .then(() => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                })
        },
        toggleShowFeatures(id) {
            this.$set(this.showFeatures, id, ! this.showFeatures[id])
        }
    },
}
</script>
