<template>
    <v-data-table
        :headers="[
            {text: $t('story.ref'), align: 'start', value: 'ref'},
            {text: $t('story.as'), align: 'start', value: 'as'},
            {text: $t('story.be_able_to'), align: 'start', value: 'be_able_to'},
            {text: $t('story.so_that'), align: 'start', value: 'so_that'},
            {text: 'estim.', align: 'start', value: 'estimate'},
            {text: $t('story.status'), align: 'start', value: 'status'},
            {text: '', align: 'start', value: 'actions'},
        ]"
        disable-sort
        :items="value"
        :items-per-page="-1"
        item-key="id"
        hide-default-footer
        no-data-text="No stories"
        class="mobile"
        v-model="selectedStories"
        show-select
    >
        <!-- <template v-slot:header.data-table-select="{ on, props }">
            <v-checkbox color="red" v-bind="props" v-on="on"></v-checkbox>
        </template> -->
        <template v-slot:body="{ items, expand, isExpanded, headers, isSelected, select }">
            <draggable 
                :list="Object.assign([], value)"
                @end="updateStoriesIndex"
                tag="tbody"
                handle=".story-drag"
                draggable="tr"
                group="stories"
                :data-feature-id="feature.id"
            >
                <!-- <template v-for="item in filteredItems"> -->
                <template v-for="item in items">
                    <tr :key="'story'+item.id">
                        <td>
                            <v-checkbox v-model="selectedStories" multiple :value="item"></v-checkbox>
                        </td>
                        <td>
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="item"
                                field="ref"
                                :label="$t('story.ref')"
                                type="string"
                                required
                            >
                                #{{ item.ref }}
                            </edit-dialog>
                        </td>
                        <td>
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="item"
                                field="as"
                                :label="$t('story.as')"
                                type="string"
                                required
                            >
                                {{ item.as }}
                            </edit-dialog>
                        </td>
                        <td>
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="item"
                                field="be_able_to"
                                :label="$t('story.be_able_to')"
                                type="text"
                                required
                                size="600"
                            >
                                {{ item.be_able_to }}
                            </edit-dialog>
                        </td>
                        <td>
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="item"
                                field="so_that"
                                :label="$t('story.so_that')"
                                type="text"
                                required
                                size="600"
                            >
                                {{ item.so_that }}
                            </edit-dialog>
                        </td>
                        <td>
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="item"
                                field="estimate"
                                :label="$t('story.estimate')"
                                type="string"
                            >
                                <span v-if="['number', 'string'].indexOf(typeof item.estimate) > -1">{{ item.estimate }} sp</span>
                                <span v-else></span>
                            </edit-dialog>
                        </td>
                        <td>
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="item"
                                field="status"
                                :label="$t('story.status')"
                                type="select"
                                :items="[
                                    {text: $t('story.draft'), value: 'draft'},
                                    {text: $t('story.backlog'), value: 'backlog'},
                                    {text: $t('story.planified'), value: 'planified'},
                                    {text: $t('story.open'), value: 'open'},
                                    {text: $t('story.cancel'), value: 'cancel'},
                                    {text: $t('story.done'), value: 'done'},
                                    {text: $t('story.waiting'), value: 'waiting'},
                                ]"
                            >
                                <v-chip small label>{{ $t('story.'+item.status) }}</v-chip>
                            </edit-dialog>
                        </td>
                        <td class="text-center text-no-wrap">
                            <DuplicateButton form="StoryForm" :item="item" text small @duplicated="duplicated"/>

                            <v-btn text small fab class="d-inline-block">
                                <v-icon class="story-drag">drag_indicator</v-icon>
                            </v-btn>

                            <RemoveButton text small @confirmed="remove(item, feature)"/>

                            <v-btn text small fab class="d-inline-block" @click="expand(item, !isExpanded(item))">
                                <v-icon>{{ isExpanded(item) ? 'remove' : 'keyboard_arrow_down' }}</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    <tr :key="'storyExp'+item.id" v-if="isExpanded(item)">
                        <td :colspan="headers.length">
                            <v-row justify="space-between">
                                <v-col v-if="item.sprint" cols="auto">{{ $t('object.sprint') }}: <v-chip x-small label>{{ item.sprint.name }}</v-chip></v-col>

                                <v-col cols="auto">
                                    {{ $t('story.priority') }}:
                                    <edit-dialog 
                                        :key="Math.random()"
                                        @save="save"
                                        :value="item"
                                        field="priority"
                                        :label="$t('story.priority')"
                                        type="select"
                                        :items="[
                                            {text: $t('story.low'), value: 'low'},
                                            {text: $t('story.medium'), value: 'medium'},
                                            {text: $t('story.high'), value: 'high'},
                                        ]"
                                    >
                                        <v-chip small label>{{ $t('story.'+item.priority) }}</v-chip>
                                    </edit-dialog>
                                </v-col>

                                <v-col cols="auto">
                                    {{ $t('object.tags') }}:

                                    <edit-dialog 
                                        :key="Math.random()"
                                        @save="save"
                                        :value="item"
                                        field="tags"
                                        :label="$t('object.tags')"
                                        type="select"
                                        :items="fetchTags"
                                        item-text="name"
                                        item-value="name"
                                        multiple
                                        chips
                                        deletable-chips
                                    >
                                        <v-chip small label class="mx-1 my-1" v-for="tag in item.tags" :key="tag.id">{{ tag.name }}</v-chip>
                                        <span></span>
                                    </edit-dialog>
                                </v-col>

                                <v-col cols="12">
                                    <span class="grey--text text--darken-2">{{ $t('story.details') }}:</span>
                                    <edit-dialog 
                                        :key="Math.random()"
                                        @save="save"
                                        :value="item"
                                        field="details"
                                        :label="$t('story.details')"
                                        type="wyswyg"
                                        size="600"
                                    >
                                        <div :class="'subtitle-1 '+( $vuetify.theme.dark ? 'white--text' : 'black--text' )" v-html="item.details"></div>
                                    </edit-dialog>
                                </v-col>
                            </v-row>
                            <messages messageable_type="story" :messageable_id="item.id" :key="item.id" dark/>
                        </td>
                    </tr>
                </template>
            </draggable>
        </template>

    </v-data-table>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    components: {
        draggable,
    },
    props: {
        value: {type: Array, required: true},
        project_id: {type: [String, Number], required: false,},
        feature: {type: Object, required: false},
        // filters: {type: Object, default: () => ({}),},
    },
    data(){
        return {
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            selectedStories: [],
        }
    },
    mounted() {
    },
    watch: {
        selectedStories(newVal, oldVal) {
            // BUG: called twice
            this.$emit('selectedStories', newVal, oldVal)
        }
    },
    computed: {
        // filteredItems() {
        //     if (this.filters.stories.status)
        //         return this.value.filter(s => this.filters.stories.status.indexOf(s.status) > -1)
        //     return this.value
        // }
    },
    asyncComputed: {
        fetchTags() {
            let queries = {search: JSON.stringify({taggable_type: 'story'}), per_page: -1}
            return this.$store.dispatch('tagsRequest', queries).then(result => result.data)
        },
    }, 
    methods: {
        save(item) {
            this.$store.dispatch('storyEditRequest', {id: item.id, datas: item})
                .then((story) => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.saved')});
                    let i = this.value.findIndex(s => s.id == story.id)
                    if (i >= 0) Object.assign(this.value[i], story)
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        remove(item) {
            return this.$store.dispatch('storyDeleteRequest', {id: item.id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.deleted')});
                let i = this.value.findIndex(c => c.id == item.id)
                this.value.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        duplicated(item) {
            this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.saved')});
            if (item.feature_id == this.feature.id)
                this.value.push(item)
            else {
                let fakeEvent = {
                    from: {dataset: {capabilityId: this.feature.capability_id}},
                    to: {dataset: {capabilityId: item.feature.capability_id}},
                    newIndex: 0,
                }
                this.$emit('droppedInFeature', item, fakeEvent)
            }
        },
        updateStoriesIndex(event) {
            let mooved = this.value[event.oldIndex]
            
            // remove in from
            this.value.splice(event.oldIndex, 1)

            if (event.from.dataset.featureId != event.to.dataset.featureId) {
                // if drop in another feature, the parent get the job  
                mooved.feature_id = event.to.dataset.featureId
                this.save(mooved)
                this.$emit('droppedInFeature', mooved, event);
            } else {
                // add in to
                this.value.splice(event.newIndex, 0, mooved)

                let newPos = this.value.map((c) => c.id)
                let queries = {object: 'stories', datas: {positioning: newPos}}

                this.$store.dispatch('updatePositioningRequest', queries)
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }

        },
    }
}
</script>