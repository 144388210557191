<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form">
        <v-row>
            <v-col v-if="sprint">
                <v-autocomplete
                    v-model="sprint.project_id"
                    :items="allProjects"
                    :label="$t('object.project')"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                    filled
                    :disabled="loading" :loading="loading"
                ></v-autocomplete>

                <v-text-field
                    v-model="sprint.name"
                    filled
                    :label="$t('sprint.name')"
                    :rules="[rules.required]"
                ></v-text-field>

                <wyswyg v-model="sprint.description" :label="$t('sprint.description')"/>

                <v-select
                    v-model="sprint.status"
                    :items="[
                        {text: $t('sprint.draft'), value: 'draft'},
                        {text: $t('sprint.planified'), value: 'planified'},
                        {text: $t('sprint.open'), value: 'open'},
                        {text: $t('sprint.done'), value: 'done'},
                        {text: $t('sprint.waiting'), value: 'waiting'},
                    ]"
                    :label="$t('sprint.status')"
                    filled
                    :rules="[rules.required]"
                ></v-select>

                <v-data-table
                    v-model="sprint.stories"
                    :headers="[
                        {text: $t('story.ref'), value: 'ref', align: 'center'},
                        {text: $t('story.as'), value: 'as', align: 'start',},
                        {text: $t('story.be_able_to'), value: 'be_able_to', align: 'start',},
                        {text: $t('story.so_that'), value: 'so_that', align: 'start',},
                        {text: $t('story.priority'), value: 'priority', align: 'center'},
                        {text: $t('story.status'), value: 'status', align: 'center'},
                        {text: $t('story.estimate'), value: 'estimate', align: 'center'},
                    ]"
                    :items="allStories"
                    item-key="id"
                    :items-per-page="-1"
                    show-select
                    :class="$vuetify.breakpoint.mdAndDown ? 'mobile' : ''"
                    show-expand
                    fixed-header
                    height="600"
                >
                    <template v-slot:item.text="{ item }">
                        <span class="grey--text text--darken-2">{{ $t('story.as') }}</span> {{ item.as.charAt(0).toLowerCase() + item.as.slice(1) }},
                        <span class="grey--text text--darken-2">{{ $t('story.be_able_to') }}</span> {{ item.be_able_to.charAt(0).toLowerCase() + item.be_able_to.slice(1) }},
                        <span class="grey--text text--darken-2">{{ $t('story.so_that') }}</span> {{ item.so_that.charAt(0).toLowerCase() + item.so_that.slice(1) }} 
                    </template>

                    <template v-slot:header.estimate="{ header }">
                        {{ header.text }} ({{ sprint.stories.reduce((acc, s) => acc + parseFloat(s.estimate || 0), 0) }} sp)
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" :class="{'white': !$vuetify.theme.dark, 'pa-3': true}">
                            <div class="grey--text text--darken-2 mb-1">{{ $t('story.details') }}:</div>
                            <div :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" v-html="item.details"></div>
                        </td>
                    </template>

                    <!-- <template v-slot:body.append="{ headers }">
                        <tr>
                            <td :colspan="headers.length - 1"></td>
                            <td class="text-center font-weight-bold">{{ sprint.stories.reduce((acc, s) => acc + parseFloat(s.estimate || 0), 0) }}</td>
                        </tr>
                    </template> -->

                    <template v-slot:no-data>
                        <no-data/>
                    </template>
                </v-data-table>

            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary">{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">{{ $t('save') }}</v-btn>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            sprint: Object.assign({}, this.value),
            allStories: [],
            allProjects: [],
        }
    },
    computed: {
        // 
    },
    mounted() {
        if (!this.sprint.stories)
            this.sprint.stories = []

        this.fetchProjects()
        this.fetchStories()
    },
    methods: {
        fetchStories() {
            this.loading = true
            let queries = {
                search: JSON.stringify({
                    project_id: this.sprint.project_id, 
                    sprints: [null]
                }),
                per_page: -1,
                with: ['feature.capability.project', 'sprint'],
            }
            this.$store.dispatch('storiesRequest', queries)
                .then((result) => {
                    this.allStories = this.sprint.stories.concat(result.data)
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        fetchProjects() {
            this.loading = true
            this.$store.dispatch('projectsRequest', {per_page: -1, with: ['partner']})
                .then((result) => {
                    this.allProjects = result.data
                    this.loading = false
                })
                .catch((err) => {
                })
        },
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                this.sprint.story_ids = this.sprint.stories.map(s => s.id)

                let request = 'sprintCreateRequest'
                let queries = this.sprint

                if (this.sprint.id) {
                    request = 'sprintEditRequest'
                    queries = {id: this.sprint.id, datas: this.sprint}
                }

                this.$store.dispatch(request, queries)
                    .then((sprint) => {
                        this.loading = false;
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('sprint.saved')});
                        this.$emit('savedForm', sprint);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
    }
}
</script>
