<template>
    <v-content class="create-project">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon x-large class="mr-5">{{ $t('project.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.projects'), to: {name: 'Projects'}},
                        {text: $t('create')}
                    ]"/>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12">
                    <ProjectForm ref="ProjectForm" :value="{sp_type: 'day', sp_rate: '0'}" @savedForm="handleSavedProjectForm" @canceledForm="handleCanceledProjectForm"/>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>
<script>
import ProjectForm from '@/components/project/Form'
export default {
    components: {
        ProjectForm,
    },
    data() {
        return {
            // 
        }
    },
    mounted() {
        // 
    },
    methods: {
        handleCanceledProjectForm() {
            this.$router.push({name: 'Projects'})
        },
        handleSavedProjectForm(project) {
            this.$router.push({name: 'Project', params: {id: project.id}})
        },
    }
}
</script>
