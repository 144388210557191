<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form" class="mb-5">
        <v-row>
            <v-col cols="12" :md="6">
                <v-text-field
                    v-model="project.name"
                    v-if="fields.indexOf('name') >= 0"
                    filled
                    :label="$t('project.name')"
                    :rules="[rules.required]"
                ></v-text-field>

                <v-autocomplete
                    v-model="project.partner_id"
                    v-if="fields.indexOf('partner_id') >= 0"
                    :items="allPartners"
                    :label="$t('object.partner')"
                    item-text="name"
                    item-value="id"
                    filled
                ></v-autocomplete>

                <v-autocomplete
                    v-model="project.project_manager_id"
                    v-if="fields.indexOf('project_manager_id') >= 0"
                    :items="allUsers"
                    :label="$t('project.project_manager')"
                    clearable
                    item-text="name"
                    item-value="id"
                    filled
                ></v-autocomplete>

                <v-autocomplete
                    v-model="project.product_manager_id"
                    v-if="fields.indexOf('product_manager_id') >= 0"
                    :items="allUsers"
                    :label="$t('project.product_manager')"
                    clearable
                    item-text="name"
                    item-value="id"
                    filled
                ></v-autocomplete>

                <v-autocomplete
                    v-model="project.service_manager_id"
                    v-if="fields.indexOf('service_manager_id') >= 0"
                    :items="allUsers"
                    :label="$t('project.service_manager')"
                    clearable
                    item-text="name"
                    item-value="id"
                    filled
                ></v-autocomplete>

                <v-autocomplete
                    v-model="project.technical_manager_id"
                    v-if="fields.indexOf('technical_manager_id') >= 0"
                    :items="allUsers"
                    :label="$t('project.technical_manager')"
                    clearable
                    item-text="name"
                    item-value="id"
                    filled
                ></v-autocomplete>

                <v-autocomplete
                    v-model="project.users"
                    v-if="fields.indexOf('users') >= 0"
                    :items="allUsers"
                    item-text="name"
                    item-value="id"
                    :label="$t('object.users')"
                    multiple
                    chips
                    deletable-chips
                    return-object
                    filled
                >
                    <template v-slot:selection="data">
                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item)"
                        >
                            <v-avatar left color="primary">
                                <span class="white--text">{{ data.item.name.split(" ").map((n)=>n[0]).join("") }}</span>
                            </v-avatar>
                            {{ data.item.name }}
                            <v-icon class="ml-2" small v-if="data.item.pivot.is_subscriber">notifications_active</v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <v-row dense align="center">
                            <v-col cols="auto">
                                <v-avatar color="primary">
                                    <span class="white--text">{{ data.item.name.split(" ").map((n)=>n[0]).join("") }}</span>
                                </v-avatar>
                            </v-col>
                            <v-col>
                                {{ data.item.name }}
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox
                                    v-model="data.item.pivot.is_subscriber"
                                    :label="$t('project.notifications')"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </template>
                </v-autocomplete>
            </v-col>

            <v-col cols="12" :md="6">
                <v-select
                    v-if="fields.indexOf('status') >= 0"
                    v-model="project.status"
                    :items="[
                        {text: $t('project.draft'), value: 'draft'},
                        {text: $t('project.commercial'), value: 'commercial'},
                        {text: $t('project.design'), value: 'design'},
                        {text: $t('project.conception'), value: 'conception'},
                        {text: $t('project.maintenance'), value: 'maintenance'},
                        {text: $t('project.done'), value: 'done'},
                        {text: $t('project.cancel'), value: 'cancel'},
                    ]"
                    :label="$t('project.status')"
                    filled
                    :rules="[rules.required]"
                ></v-select>

                <v-text-field
                    v-model="project.daily_price"
                    v-if="fields.indexOf('daily_price') >= 0"
                    filled
                    :label="$t('project.daily_price')"
                    append-icon="euro_symbol"
                ></v-text-field>

                <v-row align="center" no-gutters>
                    <v-col cols="auto">
                        <v-text-field
                            v-model="project.sp_rate"
                            v-if="fields.indexOf('sp_rate') >= 0"
                            filled
                            :label="$t('project.sp_rate')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                        <v-select
                            v-model="project.sp_type"
                            v-if="fields.indexOf('sp_type') >= 0"
                            :items="[
                                {text: $t('project.day'), value: 'day'},
                                {text: $t('project.hour'), value: 'hour'},
                            ]"
                            :label="$t('project.sp_type')"
                            filled
                        ></v-select>
                    </v-col>
                    <!-- <v-col cols="auto">
                        <small>(ex: 2 jours)</small>
                    </v-col> -->
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <wyswyg v-if="fields.indexOf('description') >= 0" v-model="project.description" :label="$t('project.description')"/>

                <wyswyg v-if="fields.indexOf('requirements') >= 0" v-model="project.requirements" :label="$t('project.requirements')"/>

                <wyswyg v-if="fields.indexOf('technical_needs') >= 0" v-model="project.technical_needs" :label="$t('project.technical_needs')"/>

                <template v-if="fields.indexOf('technical_scheme') >= 0">
                    <v-file-input 
                        v-model="technical_scheme"
                        value="technical_scheme"
                        :label="$t('project.technical_scheme')"
                        clearable
                        :rules="[rules.image]"
                        @change="changeTechnicalScheme"
                    ></v-file-input>
                    <v-img v-if="project.technical_scheme_url" alt="" :src="project.technical_scheme_url" max-height="400" width="400">
                        <v-row align="end" class="fill-height">
                            <v-btn block dark small class="red" @click="deleteTechnicalScheme">{{ $t('remove') }}</v-btn>
                        </v-row>
                    </v-img>
                </template>
            </v-col>
        </v-row>

        <v-row v-if="project.original_id">
            <v-col cols="12" :md="6">
                <p>{{ $t('duplication.relationships') }}:</p>
                <v-switch 
                    v-if="! project.duplicate_relationships.includes('capabilities.features') && ! project.duplicate_relationships.includes('capabilities.features.stories')"
                    v-model="project.duplicate_relationships"
                    :label="$t('object.capabilities')"
                    value="capabilities"
                ></v-switch>
                <v-switch 
                    v-if="! project.duplicate_relationships.includes('capabilities') && ! project.duplicate_relationships.includes('capabilities.features.stories')"
                    v-model="project.duplicate_relationships"
                    :label="$t('object.capabilities')+' & '+$t('object.features')"
                    value="capabilities.features"
                ></v-switch>
                <v-switch 
                    v-if="! project.duplicate_relationships.includes('capabilities') && ! project.duplicate_relationships.includes('capabilities.features')"
                    v-model="project.duplicate_relationships"
                    :label="$t('object.capabilities')+' & '+$t('object.features')+' & '+$t('object.stories')"
                    value="capabilities.features.stories"
                ></v-switch>
                <v-switch 
                    v-model="project.duplicate_relationships"
                    :label="$t('object.lexicons')"
                    value="lexicons"
                ></v-switch>
            </v-col>
            <v-col cols="12" :md="6">
                <p>{{ $t('duplication.reset_relationships_fields') }}:</p>
                <v-switch 
                    v-model="project.reset_relationships_fields"
                    :label="$t('feature.ref')"
                    value='{"name": "ref", "method": "uniqid()"}'
                ></v-switch>
                <v-switch 
                    v-model="project.reset_relationships_fields"
                    :label="$t('story.estimate')"
                    value='{"name": "estimate", "method": "null"}'
                ></v-switch>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary" x-large>{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading" x-large>{{ $t('save') }}</v-btn>
        </v-row>
    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
        fields: {
            type: Array,
            default: () => [
                'name',
                'partner_id',
                'project_manager_id',
                'product_manager_id',
                'service_manager_id',
                'technical_manager_id',
                'users',
                'daily_price',
                'sp_rate',
                'sp_type',
                'description',
                'status',
            ],
        },
    },
    data () {
        return {
            project: Object.assign({}, this.value), 
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
                image: value => !value || value.type === 'image/jpeg' || value.type === 'image/png' || value.type === 'image/gif' || this.$t('rules.picture'),
            },
            allUsers: [],
            allPartners: [],
        }
    },
    watch: {
        project () { 
            this.$refs.form.resetValidation()
        },
    },
    computed: {
        technical_scheme : {
            get: function() {
                if (typeof this.project.technical_scheme == 'string' || ! this.project.technical_scheme) {
                    return null
                }
                return this.project.technical_scheme
            },
            set: function(val) {
                if (val instanceof File) {
                    this.project.technical_scheme_url = URL.createObjectURL(val)
                    this.prepareFile(val).then((file) => {
                        this.project.technical_scheme = file
                    })
                } else {
                    this.project.technical_scheme = val
                }
            }
        },
    },
    mounted() {
        this.fetchUsers()
        this.fetchPartners()
    },
    methods: {
        prepareFile(file) {
            return new Promise((resolve) => {
                this.readFileAsync(file).then((data) => {
                    resolve({
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        binary: data,
                    })
                })
            })

        },
        readFileAsync(file){
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = () => {
                    resolve(reader.result)
                }
                reader.readAsDataURL(file)
            })
        },
        deleteTechnicalScheme() {
            this.project.technical_scheme_url = null
            this.project.technical_scheme = null
            this.project.remove_technical_scheme = true
        },
        changeTechnicalScheme(val) {
            if (!val)
                this.deleteTechnicalScheme()
        },
        onSubmit () {
            // console.log(this.project)
            if (this.$refs.form.validate()) {
                // this.loading = true;

                let request = 'projectCreateRequest'
                let queries = this.project
                if (this.project.id) {
                    request = 'projectEditRequest'
                    queries = {id: this.project.id, datas: this.project}
                }

                this.$store.dispatch(request, queries)
                    .then((project) => {
                        this.$refs.form.resetValidation()
                        this.loading = false;
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('project.saved')});
                        this.$emit('savedForm', project);

                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
        fetchUsers() {
            this.$store.dispatch('usersRequest', {per_page: -1})
                .then((result) => {
                    result.data.forEach((u) => {
                        u.pivot = {is_subscriber: false}
                        if (this.project.users) {
                            let user = this.project.users.find(user => user.id == u.id)
                            u.pivot = {is_subscriber: user ? user.pivot.is_subscriber : false}
                        }
                    })
                    this.allUsers = result.data
                })
                .catch((err) => {
                })
        },
        fetchPartners() {
            this.$store.dispatch('partnersRequest', {per_page: -1})
                .then((result) => {
                    this.allPartners = result.data
                })
                .catch((err) => {
                })
        },
        cancel() {
            this.$emit('canceledForm');
        },
        remove(item) {
            const index = this.project.users.findIndex(user => user.id == item.id)
            if (index >= 0) this.project.users.splice(index, 1)
          },
    }
}
</script>
