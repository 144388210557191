<template>
    <div>
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="12" :md="4">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('user.email') }}</v-list-item-title>
                                <v-list-item-subtitle>{{ value.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('user.type') }}</v-list-item-title>
                                <v-list-item-subtitle>{{ value.type.map(t => $t('user.'+t)).join(', ') }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{ $t('user.color') }}</v-list-item-title>
                                <v-list-item-subtitle><v-icon class="mr-2" :color="value.color || 'primary'">lens</v-icon></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
export default{
    components: {
        // 
    },
    props: {
        value: {
            type: Object,
            required: true
        },
    },
    data(){
        return {
            ready: false, 
        }
    },
    computed: {
        // 
    },
    mounted() {
        this.ready = true
    },
    watch: {
        // 
    },
    methods: {
        // 
    }
}
</script>