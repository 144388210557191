<template>
    <div class="d-inline-block">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" text fab :small="small" :large="large" :dark="dark" class="mx-1" :to="{ name: 'Project', params: {id: value.id} }" exact>
                    <v-icon>article</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('project.informations') }}</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" text fab :small="small" :large="large" :dark="dark" class="mx-1" :to="{ name: 'ProductDefinition', params: {id: value.id} }" exact>
                    <v-icon>aspect_ratio</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('project.product_definition') }}</span>
        </v-tooltip>
        <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" text fab :small="small" :large="large" :dark="dark" class="mx-1" :to="{ name: 'ProductBacklog', params: {id: value.id} }" exact>
                    <v-icon>view_list</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('project.product_backlog') }}</span>
        </v-tooltip> -->
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" text fab :small="small" :large="large" :dark="dark" class="mx-1" :to="{ name: 'ProductBacklogV2', params: {id: value.id} }" exact>
                    <v-icon>view_list</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('project.product_backlog') }}</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" text fab :small="small" :large="large" :dark="dark" class="mx-1" :to="{ name: 'ProjectStories', params: {id: value.id} }" exact>
                    <v-icon>work</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('object.sprints')+' & '+$t('object.stories') }}</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-badge v-if="value.new_issues_count" overlap right dot color="red darken-2">
                    <v-btn v-on="on" text fab :small="small" :large="large" :dark="dark" class="mx-1" :to="{ name: 'ProjectIssues', params: {id: value.id} }" exact>
                        <v-icon>bug_report</v-icon>
                    </v-btn>
                </v-badge>
                <template v-else>
                    <v-btn v-on="on" text fab :small="small" :large="large" :dark="dark" class="mx-1" :to="{ name: 'ProjectIssues', params: {id: value.id} }" exact>
                        <v-icon>bug_report</v-icon>
                    </v-btn>
                </template>
            </template>
            <span>{{ $t('object.issues') }}</span>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        value: Object,
        small: {type: Boolean, default: false },
        large: {type: Boolean, default: false },
        dark: {type: Boolean, default: false },
    },
    data () {
        return {
            // 
        }
    },
    computed: {
        // 
    },
    mounted() {
        // 
    },
    methods: {
    }
}
</script>