<template>
    <v-dialog
        v-model="duplicateDialog"
        max-width="80%"
    >
        <template v-slot:activator="{ on }">
                <!-- :color="color" -->
            <v-btn
                v-on="on"
                :small="small"
                :fab="fab"
                :depressed="depressed"
                :text="text"
            >
                <v-icon small>content_copy</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title
                class="headline"
                primary-title
            >
                {{ $t('duplication.new') }}
            </v-card-title>

            <v-card-text>
                <component :is="form" v-model="duplicata" @savedForm="duplicated" @canceledForm="duplicateDialog = false"></component>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    components: {
        StoryForm: () => import('./story/Form.vue'),
        FeatureForm: () => import('./feature/Form.vue'),
        CapabilityForm: () => import('./capability/Form.vue'),
        ProjectForm: () => import('./project/Form.vue'),
    },
    props: {
        form: {type: String, required: true},
        item: {type: Object, required: true},
        color: {type: String, default: 'primary'},
        small: {type: Boolean, default: false },
        depressed: {type: Boolean, default: false },
        text: {type: Boolean, default: false },
        fab: {type: Boolean, default: true },
    },
    data () {
        return {
            duplicateDialog: false,
        }
    },
    computed: {
        duplicata() {
            let item = Object.assign({}, this.item)
            item.original_id = item.id
            item.duplicate_relationships = []
            item.reset_relationships_fields = []
            delete item.id
            return item
        }
    },
    mounted() {
        // 
    },
    methods: {
        duplicated(item) {
            this.duplicateDialog = false
            this.$emit('duplicated', item)
        }
    }

}
</script>