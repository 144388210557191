import Vue from 'vue'
import i18n from '@/plugins/i18n/i18n'

const state = {
    // 
}

const getters = {
    // 
}

const actions = {
    productVisionEditRequest: async ({commit}, queries) => {
        let url = `/api/product_visions/${queries.id}`;

        return new Promise((resolve, reject) => {
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    resolve(result.data.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': i18n.t('unauthorized')})
                    reject(err)
                })
        })
    },
}

const mutations = {
    // 
}

export default {
    state,
    getters,
    actions,
    mutations,
}