<template>
    <div>
        <draggable 
            :list="Object.assign([], value)"
            @end="updateFeaturesIndex"
            tag="div"
            handle=".feature-drag"
            draggable=".row"
            group="features"
            :data-capability-id="capability_id"
        >
            <v-row no-gutter v-for="(feature, index) in value" :key="'feature_'+feature.id" flat align="start">
                <v-col cols="12" :md="showStories[feature.id] ? 2 : 12" style="position:sticky;top:0;">
                    <div :class="{'d-flex primary--text': true, 'flex-column': showStories[feature.id], 'align-center': ! showStories[feature.id]}">
                        <div class="mr-5">
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="feature"
                                field="ref"
                                :label="$t('feature.ref')"
                                type="string"
                                    required
                                >#{{ feature.ref }}</edit-dialog>
                        </div>
                        <div class="flex-grow-1">
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="feature"
                                field="name"
                                :label="$t('feature.name')"
                                type="string"
                                required
                                size="600"
                            >
                                <span class="title">{{ feature.name }}</span>
                            </edit-dialog>
                        </div>
                        <div :class="{'ml-auto': !showStories[feature.id], 'text-center': showStories[feature.id]}">
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="feature"
                                field="estimate"
                                :label="$t('feature.estimate')"
                                type="string"
                            >
                                <v-chip small dark>
                                    <template v-if="feature.stories_estimate">
                                        {{ feature.stories_estimate }} sp
                                    </template>
                                    <template v-else>
                                        <span class="font-italic">{{ feature.estimate ? feature.estimate : '-' }} sp</span>
                                    </template>
                                </v-chip>
                            </edit-dialog>
                        </div>
                    </div>

                    <div :class="{'d-flex': true, 'flex-column': showStories[feature.id]}">
                        <div class="mb-3 flex-grow-1">
                            <p>{{ $t('feature.description') }}:</p>
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="feature"
                                field="description"
                                :label="$t('feature.description')"
                                type="wyswyg"
                                size="600"
                            >
                                <span v-html="feature.description"></span>
                            </edit-dialog>
                        </div>
                        <div class="mb-3 flex-grow-1">
                            <p>{{ $t('feature.comment') }}:</p>
                            <edit-dialog 
                                :key="Math.random()"
                                @save="save"
                                :value="feature"
                                field="comment"
                                :label="$t('feature.comment')"
                                type="wyswyg"
                                size="600"
                            >
                                <span v-html="feature.comment"></span>
                            </edit-dialog>
                        </div>
                    </div>

                    <edit-dialog 
                        :key="Math.random()"
                        @save="save"
                        :value="feature"
                        field="priority"
                        :label="$t('feature.priority')"
                        type="select"
                        :items="[
                            {text: $t('feature.low'), value: 'low'},
                            {text: $t('feature.medium'), value: 'medium'},
                            {text: $t('feature.high'), value: 'high'},
                        ]"
                    >
                        {{ $t('feature.priority') }}: {{ $t('feature.'+feature.priority) }}
                    </edit-dialog>

                    <edit-dialog 
                        :key="Math.random()"
                        @save="save"
                        :value="feature"
                        field="status"
                        :label="$t('feature.status')"
                        type="select"
                        :items="[
                            {text: $t('feature.draft'), value: 'draft'},
                            {text: $t('feature.backlog'), value: 'backlog'},
                            {text: $t('feature.planified'), value: 'planified'},
                            {text: $t('feature.open'), value: 'open'},
                            {text: $t('feature.cancel'), value: 'cancel'},
                            {text: $t('feature.done'), value: 'done'},
                            {text: $t('feature.waiting'), value: 'waiting'},
                        ]"
                        required
                    >
                        {{ $t('feature.status') }}: {{ $t('feature.'+feature.status) }}
                    </edit-dialog>

                    <div class="d-flex flex-wrap justify-center">
                        <v-btn text small fab class="d-inline-block" @click="addStory({feature_id: feature.id, feature: feature, priority: 'medium', status: 'draft'})">
                            <v-icon>add</v-icon>
                        </v-btn>

                        <DuplicateButton form="FeatureForm" :item="{...feature, project_id: null}" text small @duplicated="duplicated"/>

                        <v-btn text small fab class="d-inline-block">
                            <v-icon class="feature-drag">drag_indicator</v-icon>
                        </v-btn>

                        <RemoveButton text small @confirmed="remove(feature)"/>

                        <v-btn text small fab @click="toggleShowStories(feature.id)">
                            <v-icon v-if="showStories[feature.id]">keyboard_arrow_right<!-- format_indent_decrease --></v-icon>
                            <v-icon v-else>keyboard_arrow_left<!-- format_indent_increase --></v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-col v-if="showStories[feature.id]" cols="12" :md="10">
                    <StoryIndexV2 
                        v-model="feature.stories"
                        :project_id="feature.project_id"
                        :feature="feature"
                        @droppedInFeature="changeStoryFeature"
                        @selectedStories="selectedStories"
                    />
                </v-col>
                <v-col cols="12" v-if="index != value.length-1">
                    <hr class="my-2"/>
                </v-col>
            </v-row>
        </draggable>

        <v-dialog v-model="dialogAddStory" max-width="80%" persistent scrollable>
            <v-card>
                <v-card-title class="primary white--text">
                    {{ $t('object.story') }}
                </v-card-title>
                <v-card-text>
                    <StoryForm ref="StoryForm" v-model="editedStory" :key="Date.now()" @savedForm="handleSavedStoryForm" @canceledForm="handleCanceledStoryForm"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import StoryIndexV2 from '@/components/story/IndexV2'
import StoryForm from '@/components/story/Form'
import draggable from 'vuedraggable'
export default {
    components: {
        StoryIndexV2,
        StoryForm,
        draggable,
    },
    props: {
        value: {type: Array, required: true},
        capability_id: {type: [String, Number], required: false},
        // filters: {type: Object, default: () => ({}),},
    },
    computed: {
        stories_estimate() {
            let res = []
            this.value.forEach((f) => {
                if (! f.stories) f.stories = []
                res[f.id] = f.stories.reduce((acc,s) => acc + parseFloat(s.estimate || 0), 0)
            })
            return res
        }
    },
    asyncComputed: {
        fetchCapabilities() {
            let queries = {}
            if (this.value.length)
                queries = {search: JSON.stringify({project_id: this.value[0].project_id}), per_page: -1}
            return this.$store.dispatch('capabilitiesRequest', queries).then(result => result.data)
        },
    }, 
    data(){
        return {
            dialogAddStory: false,
            editedStory: {},
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            showStories: [],
        }
    },
    mounted() {
        // 
    },
    methods: {
        save(item) {
            this.$store.dispatch('featureEditRequest', {id: item.id, datas: item})
                .then((feature) => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('feature.saved')});
                    let i = this.value.findIndex(s => s.id == feature.id)
                    if (i >= 0) Object.assign(this.value[i], feature)
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        remove(item) {
            return this.$store.dispatch('featureDeleteRequest', {id: item.id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('feature.deleted')});
                let i = this.value.findIndex(c => c.id == item.id)
                this.value.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        duplicated(item) {
            this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('feature.saved')});
            if (item.capability_id == this.capability_id)
                this.value.push(item)
            else {
                let fakeEvent = {
                    from: {dataset: {capabilityId: this.capability_id}},
                    to: {dataset: {capabilityId: item.capability_id}},
                    newIndex: 0,
                }
                this.$emit('droppedInCapability', item, fakeEvent)
            }
        },
        selectedStories(newVal, oldVal) {
            this.$emit('selectedStories', newVal, oldVal)
        },
        addStory(story) {
            this.editedStory = story
            this.dialogAddStory = true
        },
        handleCanceledStoryForm() {
            this.editedStory = {}
            this.dialogAddStory = false
        },
        handleSavedStoryForm(story) {
            this.dialogAddStory = false
            let feature = this.value.find(f => f.id == story.feature_id)
            if (feature)
                feature.stories.push(story)
        },
        updateFeaturesIndex(event) {
            let mooved = this.value[event.oldIndex]

            // remove in from
            this.value.splice(event.oldIndex, 1)

            if (event.from.dataset.capabilityId != event.to.dataset.capabilityId) {
                // if drop in another capability, the parent get the job  
                mooved.capability_id = event.to.dataset.capabilityId
                this.save(mooved)
                this.$emit('droppedInCapability', mooved, event.newIndex);
            } else {
                // add in to
                this.value.splice(event.newIndex, 0, mooved)

                let newPos = this.value.map((c) => c.id)
                let queries = {object: 'features', datas: {positioning: newPos}}

                this.$store.dispatch('updatePositioningRequest', queries)
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
        changeStoryFeature(story, event) {
            // remove action is processed in child component (StoryIndex)

            if (event.from.dataset.capabilityId != event.to.dataset.capabilityId) {   
                // if drop in another capability, the parent get the job 
                this.$emit('droppedStoryInCapability', story, event);
            } else {
                // add in to
                let to = this.value.find(f => f.id == story.feature_id)
                to.stories.splice(event.newIndex, 0, story)
                // update stories positions
                let newPos = to.stories.map((s) => s.id)
                let queries = {object: 'stories', datas: {positioning: newPos}}
                this.$store.dispatch('updatePositioningRequest', queries)
                    .then(() => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
        toggleShowStories(id) {
            this.$set(this.showStories, id, ! this.showStories[id])
        }
    },
}
</script>
