<template>
    <v-content class="projects">
        <v-card color="primary" dark flat>
            <v-card-text class="py-4">
                <v-row no-gutters align="center" class="headline mb-3">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('project.icon') }}</v-icon></v-col>
                    <v-col class="text-no-wrap">{{ $t('object.projects') }}</v-col>
                    <v-col cols="12" sm="auto">
                        <v-btn 
                            block
                            color="primary"
                            :to="{name: 'CreateProject'}"
                        >
                            {{ $t('create') }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row dense justify="center" align="center">
                    <v-col style="min-width: 200px;"> 
                        <v-text-field
                            v-model="search.text"
                            clearable
                            flat
                            solo
                            hide-details
                            prepend-inner-icon="search"
                            :label="$t('search')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-data-table
                :headers="headers"
                :items="projects"
                :options.sync="options"
                :server-items-length="total"
                :loading="loading"
                :footer-props="footerProps"
            >
                <!-- @click:row="item => $router.push({name: 'Project', params: {id: item.id}})" -->
                <template v-slot:item.partners.name="{ item }">
                    <template v-if="item.partner">
                        {{ item.partner.name }}
                    </template>
                </template>

                <template v-slot:item.name="{ item }">
                    <span class="title font-weight-bold">{{ item.name }}</span>
                </template>

                <template v-slot:loading>
                    <v-skeleton-loader type="table-tbody" height="400px" width="100%"></v-skeleton-loader>
                </template>

                <template v-slot:item.status="{ item }">
                    <v-chip small>{{ $t('project.'+item.status) }}</v-chip>
                </template>

                <template v-slot:item.actions="{ item }">
                    <ProjectMenu v-model="item" small/>
                </template>

                <template v-slot:no-data>
                    <no-data/>
                </template>
            </v-data-table>
        </v-container>
    </v-content>
</template>

<script>
import ProjectMenu from '@/components/project/Menu'
export default {
    components: {
        ProjectMenu,
    },
    data () {
        return {
            ready: false,
            projects: [],
            headers: [
                {
                    text: this.$t('object.partner'),
                    align: 'end',
                    sortable: true,
                    value: 'partners.name',
                },
                {
                    text: this.$t('project.name'),
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: this.$t('project.status'),
                    align: 'center',
                    sortable: true,
                    value: 'status',
                },
                {
                    text: '',
                    align: 'end',
                    sortable: false,
                    value: 'actions',
                },
            ],
            options: {
                page: parseInt(this.$route.query.page) || 1,
                sortBy: this.$route.query.sortBy || ['partners.name'],
                sortDesc: [this.$route.query.sortDesc == 'true'],
                itemsPerPage: -1,
            },
            search: this.$route.query.search || {},
            total: 0,
            loading: false,
            allPartners: [],
            partnersLoading: false,
        }
    },
    computed: {
        footerProps() {
            let start = (((this.options.page-1)*this.options.itemsPerPage)+1)
            let end = this.options.page*this.options.itemsPerPage
            return {
                'page-text': start + '-' + (end > this.total ? this.total : end) + ' ' + this.$t('of') + ' ' + this.total, 
                'items-per-page-text': this.$t('items_per_page'), 
                'items-per-page-options': [10, 20, -1],
            }
        },
    },
    watch: {
        options: {
            handler () {
                this.fetchProjects()
            },
            deep: true,
        },
        search: {
            handler () {
                this.fetchProjects()
            },
            deep: true,
        },
    },
    mounted () {
        this.fetchPartners()
    },
    methods: {
        fetchProjects () {
            const queries = {
                page: this.options.page,
                per_page: this.options.itemsPerPage,
                sort: this.options.sortBy,
                direction: (this.options.sortDesc.length ? this.options.sortDesc[0] : false) ? 'desc' : 'asc',
                search: JSON.stringify(this.search),
                with: ['partner'],
            }

            this.loading = true
            this.projects = []
            this.$store.dispatch('projectsRequest', queries)
                .then(result => {
                    this.total = result.meta.total
                    this.projects = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        fetchPartners() {
            this.partnersLoading = true
            this.$store.dispatch('partnersRequest', {per_page: -1})
                .then((result) => {
                    this.partnersLoading = false
                    this.allPartners = result.data
                })
                .catch((err) => {
                    this.partnersLoading = false
                })
        },
    },
}
</script>
