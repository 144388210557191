import Vue from 'vue'
import i18n from '@/plugins/i18n/i18n'

const state = {
    // 
}

const getters = {
    // 
}

const actions = {
    productUsertypeRequest: async ({commit}, queries) => {
        let url = `/api/product_usertypes/${queries.id}`;

        if (queries.with) {
            let params = '?'
            queries.with.forEach((w, i) => params += (i > 0 ? '&' : '')+'with['+i+']='+w)
            url += params
        }

        return new Promise((resolve, reject) => {
            Vue.axios.get(url)
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': i18n.t('unauthorized')})
                    reject(err)
                })
        })
    },
    productUsertypesRequest: async ({commit, dispatch}, queries) => {
        //remove null values
        queries = Object.entries(queries).reduce((a,[k,v]) => (v ? {...a, [k]:v} : a), {})
        //build query url
        let url = '/api/product_usertypes?'+Object.entries(queries).map((e) => {
            if (typeof e[1] == 'object') {
                let s = ''
                e[1].forEach((q, i) => s += e[0]+'['+i+']='+q+(i<e[1].length-1 ? '&' : ''))
                return s
            }
            return e.join('=')
        }).join('&')

        return new Promise((resolve, reject) => {
            Vue.axios.get(url)
                .then(result => {
                    resolve(result.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': i18n.t('unauthorized')})
                    reject(err)
                })
        })
    },
    productUsertypeEditRequest: async ({commit}, queries) => {
        let url = `/api/product_usertypes/${queries.id}`;

        return new Promise((resolve, reject) => {
            Vue.axios.put(url, queries.datas)
                .then(result => {
                    resolve(result.data.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': i18n.t('unauthorized')})
                    reject(err)
                })
        })
    },
    productUsertypeCreateRequest: async ({commit}, queries) => {
        let url = `/api/product_usertypes`;

        return new Promise((resolve, reject) => {
            Vue.axios.post(url, queries)
                .then(result => {
                    resolve(result.data.data)
                })
                .catch(err => {
                    if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': i18n.t('unauthorized')})
                    reject(err)
                })
        })
    },
    productUsertypeDeleteRequest: async ({commit}, queries) => {
        let url = `/api/product_usertypes/${queries.id}`;

        return new Promise((resolve, reject) => {
            Vue.axios.delete(url)
                .then(result => {
                    resolve(result)
                })
                .catch(err => {
                     if (err.response.status == 403)
                        commit('setSnack', {'type': 'red', 'msg': i18n.t('unauthorized')})
                    reject(err)
                })
        })
    },
}

const mutations = {
    // 
}

export default {
    state,
    getters,
    actions,
    mutations,
}