<template>
    <v-content class="project">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('project.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.projects'), to: {name: 'Projects'}},
                        {text: project.complete_name},
                    ]"/>
                    <v-spacer/>
                    <v-col cols="auto">
                        <ProjectMenu v-model="project" small/>
                        -
                        <DuplicateButton
                            form="ProjectForm"
                            :item="{...project, product_vision_id: null, users: project.users.map((u) => {delete u.pivot.project_id;return u})}"
                            @duplicated="duplicated"
                            text
                            fab
                            small
                        />
                        <v-btn
                            :to="{name: 'EditProject', params: {id: project.id}}"
                            text
                            fab
                            small
                            exact
                        >
                            <v-icon>edit</v-icon>
                        </v-btn>
                        <RemoveButton 
                            @confirmed="deleteToApi" 
                            color="red darken-1"
                            small
                            text
                            fab
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row dense>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col cols="auto">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('object.partner') }}</v-list-item-title>
                                            <v-row>
                                                <v-col>
                                                    <v-chip v-if="project.partner" class="mr-2 pl-1">
                                                        <v-avatar small color="primary" class="mr-2">
                                                            <span class="white--text">{{ project.partner.name.split(" ").map((n)=>n[0]).join("") }}</span>
                                                        </v-avatar>
                                                        {{ project.partner.name }}
                                                    </v-chip>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="auto">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('project.project_manager') }}</v-list-item-title>
                                            <v-row>
                                                <v-col>
                                                    <v-chip v-if="project.project_manager" class="mr-2 pl-1">
                                                        <v-avatar small color="primary" class="mr-2">
                                                            <span class="white--text">{{ project.project_manager.name.split(" ").map((n)=>n[0]).join("") }}</span>
                                                        </v-avatar>
                                                        {{ project.project_manager.name }}
                                                    </v-chip>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="auto">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('project.product_manager') }}</v-list-item-title>
                                            <v-row>
                                                <v-col>
                                                    <v-chip v-if="project.product_manager" class="mr-2 pl-1">
                                                        <v-avatar small color="primary" class="mr-2">
                                                            <span class="white--text">{{ project.product_manager.name.split(" ").map((n)=>n[0]).join("") }}</span>
                                                        </v-avatar>
                                                        {{ project.product_manager.name }}
                                                    </v-chip>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="auto">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('project.service_manager') }}</v-list-item-title>
                                            <v-row>
                                                <v-col>
                                                    <v-chip v-if="project.service_manager" class="mr-2 pl-1">
                                                        <v-avatar small color="primary" class="mr-2">
                                                            <span class="white--text">{{ project.service_manager.name.split(" ").map((n)=>n[0]).join("") }}</span>
                                                        </v-avatar>
                                                        {{ project.service_manager.name }}
                                                    </v-chip>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="auto">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('project.technical_manager') }}</v-list-item-title>
                                            <v-row>
                                                <v-col>
                                                    <v-chip v-if="project.technical_manager" class="mr-2 pl-1">
                                                        <v-avatar small color="primary" class="mr-2">
                                                            <span class="white--text">{{ project.technical_manager.name.split(" ").map((n)=>n[0]).join("") }}</span>
                                                        </v-avatar>
                                                        {{ project.technical_manager.name }}
                                                    </v-chip>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="auto">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('object.users') }} <small>{{ $t('project.access_and_notifications') }}</small></v-list-item-title>
                                            <v-row>
                                                <v-col>
                                                    <v-chip v-for="user in project.users" :key="user.id" class="mr-2 pl-1 pr-2">
                                                        <v-avatar small color="primary" class="mr-2">
                                                            <span class="white--text">{{ user.name.split(" ").map((n)=>n[0]).join("") }}</span>
                                                        </v-avatar>
                                                        {{ user.name }}
                                                        <v-icon class="ml-2" v-if="user.pivot.is_subscriber">notifications_active</v-icon>
                                                    </v-chip>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>

                            <v-divider class="my-2"></v-divider>

                            <v-row>
                                <v-col>
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('project.status') }}</v-list-item-title>
                                            <v-list-item-subtitle v-if="project.daily_price">
                                                <v-chip outlined :color="$vuetify.theme.dark ? 'white' : 'primary'">{{ $t('project.'+project.status) }}</v-chip>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col>
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('project.description') }}</v-list-item-title>
                                            <v-row>
                                                <v-col v-html="project.description"></v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('project.daily_price') }}</v-list-item-title>
                                            <v-list-item-subtitle v-if="project.daily_price">
                                                <v-chip outlined :color="$vuetify.theme.dark ? 'white' : 'primary'">{{ project.daily_price }} €</v-chip>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-else>
                                                -
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('project.sp_value') }}</v-list-item-title>
                                            <v-list-item-subtitle v-if="project.sp_type == 'day'">
                                                <v-chip outlined :color="$vuetify.theme.dark ? 'white' : 'primary'">{{ project.sp_rate }} {{ project.sp_rate > 1 ? 'jours' : 'jour'}}</v-chip>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="project.sp_type == 'hour'">
                                                <v-chip outlined :color="$vuetify.theme.dark ? 'white' : 'primary'">{{ project.sp_rate }} {{ project.sp_rate > 1 ? 'heures' : 'heure'}}</v-chip>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <Lexicons :project_id="project.id"/>
                </v-col>
                <v-col pcols="12" v-if="ready">
                    <messages messageable_type="project" :messageable_id="project.id"/>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
import Lexicons from '@/components/Lexicons'
import ProjectMenu from '@/components/project/Menu'
export default {
    components: {
        Lexicons,
        ProjectMenu,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data () {
        return {
            ready: false,
            project: {users:[]},
            loading: false,
        }
    },
    mounted () {
        this.getProject()
    },
    methods: {
        getProject() {
            this.loading = true
            this.$store.dispatch('projectRequest', {id: this.id, with: ['project_manager', 'product_manager', 'service_manager', 'technical_manager', 'partner', 'users']})
                .then(result => {
                    this.project = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        deleteToApi() {
            return this.$store.dispatch('projectDeleteRequest', { id: this.id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('project.deleted')});
                this.$router.push({name: 'Projects'})
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        duplicated(item) {
            this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('project.saved')});
            if (item.id)
                this.$router.push({name: 'Project', params: {id: item.id}})
        },
    }
}
</script>