<template>
    <v-content class="timesheets">
        <v-card color="primary" dark flat>
            <v-card-text class="py-4">
                <v-row no-gutters align="center" class="headline mb-3">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('timesheet.icon') }}</v-icon></v-col>
                    <v-col class="text-no-wrap">{{ $t('object.timesheets') }}</v-col>
                    <v-col cols="12" sm="auto">
                        <v-btn 
                            block
                            color="primary"
                            @click="editTimesheet({date: $moment().format('YYYY-MM-DD'), user_id: $store.state.auth.profile.id})"
                        >
                            {{ $t('create') }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row dense justify="center" align="center">
                    <v-col style="min-width: 200px;">        
                        <v-autocomplete
                            v-model="search.project_id"
                            :items="allProjects"
                            :item-text="p => p.complete_name"
                            item-value="id"
                            :label="$t('object.projects')"
                            :loading="projectsLoading"
                            prepend-inner-icon="search"
                            flat
                            solo
                            clearable
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col style="min-width: 200px;">        
                        <v-autocomplete
                            v-model="search.user_id"
                            :items="allUsers"
                            item-text="name"
                            item-value="id"
                            :label="$t('object.users')"
                            :loading="usersLoading"
                            prepend-inner-icon="search"
                            flat
                            solo
                            clearable
                            hide-details
                        ></v-autocomplete>
                    </v-col>
                    <v-col style="min-width: 200px;">        
                        <v-text-field
                            v-model="search.description"
                            clearable
                            flat
                            solo
                            hide-details
                            prepend-inner-icon="search"
                            :label="$t('timesheet.description')"
                        ></v-text-field>
                    </v-col>
                    <v-col style="min-width: 200px;">        
                        <v-autocomplete
                            v-model="search.tags"
                            :items="allTags"
                            :label="$t('object.tags')"
                            item-text="name"
                            :return-object="false"
                            clearable
                            multiple
                            chips
                            flat
                            solo
                            deletable-chips
                            :loading="tagsLoading"
                            hide-details
                            prepend-inner-icon="search"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-data-table
                :headers="headers"
                :items="timesheets"
                :options.sync="options"
                :server-items-length="total"
                :loading="loading"
                :footer-props="footerProps"
            >
                <template v-slot:loading>
                    <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
                </template>

                <template v-slot:item.date="{ item }">
                    {{ $moment(item.date).format('DD/MM/YYYY') }}
                </template>

                <template v-slot:item.project_name="{ item }">
                    <router-link :to="{name: 'Project', params: {id: item.project_id}}" :class="($vuetify.theme.dark ? 'white--text ' : '' )+' text-no-underline'">
                        {{item.project_name}}
                    </router-link>
                </template>

                <template v-slot:item.tags="{ item }">
                    <v-chip small label class="mx-1 my-1" v-for="tag in item.tags" :key="tag.id">{{ tag.name }}</v-chip>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-btn
                        @click="editTimesheet(item)"
                        small
                        text
                        fab
                    >
                        <v-icon>edit</v-icon>
                    </v-btn>
                    <RemoveButton 
                        @confirmed="deleteToApi(item)" 
                        color="red darken-1"
                        small
                        depressed
                        text
                    />
                </template>

                <template v-slot:body.append="{ items, headers }">
                    <tr>
                        <td :colspan="headers.length-2" class="text-end">
                            Total:
                        </td>
                        <td class="text-end">{{ total_time }}</td>
                    </tr>
                </template>


                <template v-slot:no-data>
                    <no-data/>
                </template>
            </v-data-table>

            <v-dialog v-model="dialogEditTimesheet">
                <v-card>
                    <v-card-title class="secondary white--text">
                        {{ $t('object.timesheet') }}
                    </v-card-title>
                    <v-card-text class="pa-3">
                        <TimesheetForm ref="TimesheetForm" v-model="editedTimesheet" :key="Date.now()" @savedForm="handleSavedTimesheetForm" @canceledForm="handleCanceledTimesheetForm"/>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </v-content>
</template>

<script>
import TimesheetForm from '@/components/timesheet/Form'
export default {
    components: {
        TimesheetForm,
    },
    data () {
        return {
            timesheets: [],
            headers: [
                {
                    text: this.$t('timesheet.date'),
                    align: 'start',
                    sortable: true,
                    value: 'date',
                },
                {
                    text: this.$t('object.project'),
                    align: 'start',
                    sortable: false,
                    value: 'project_name',
                },
                {
                    text: this.$t('object.user'),
                    align: 'start',
                    sortable: false,
                    value: 'user_name',
                },
                // {
                //     text: this.$t('timesheet.timed'),
                //     align: 'start',
                //     sortable: false,
                //     value: 'timed',
                // },
                {
                    text: this.$t('timesheet.description'),
                    align: 'start',
                    sortable: false,
                    value: 'description',
                },
                {
                    text: this.$t('object.tags'),
                    align: 'center',
                    sortable: false,
                    value: 'tags',
                },
                {
                    text: this.$t('timesheet.time'),
                    align: 'end',
                    sortable: false,
                    value: 'time_string',
                },
                {
                    text: '',
                    align: 'end',
                    sortable: false,
                    value: 'actions',
                },
            ],
            total: 0,
            total_time: 0,
            loading: false,
            options: {
                sortBy: ['date'],
                sortDesc: [true],
            },
            search: {},
            allProjects: [],
            allUsers: [],
            allTags: [],
            projectsLoading: false,
            usersLoading: false,
            tagsLoading: false,
            dialogEditTimesheet: false,
            editedTimesheet: {},
        }
    },
    computed: {
        footerProps() {
            let start = (((this.options.page-1)*this.options.itemsPerPage)+1)
            let end = this.options.page*this.options.itemsPerPage
            return {
                'page-text': start + '-' + (end > this.total ? this.total : end) + ' ' + this.$t('of') + ' ' + this.total, 
                'items-per-page-text': this.$t('items_per_page'), 
                'items-per-page-options': [10, 20, -1],
            }
        }
    },
    watch: {
        options: {
            handler () {
                this.fetchTimesheets()
            },
            deep: true,
        },
        search: {
            handler () {
                this.fetchTimesheets()
            },
            deep: true,
        },
    },
    mounted () {
        this.fetchProjects()
        this.fetchUsers()
        this.fetchTags()
    },
    methods: {
        fetchTimesheets () {
            const queries = {
                page: this.options.page,
                per_page: this.options.itemsPerPage,
                sort: this.options.sortBy,
                direction: (this.options.sortDesc.length ? this.options.sortDesc[0] : false) ? 'desc' : 'asc',
                search: JSON.stringify(this.search),
            }

            this.loading = true
            this.timesheets = []
            this.$store.dispatch('timesheetsRequest', queries)
                .then(result => {
                    this.total = result.meta.total
                    this.total_time = this.formatFloatToTime(result.meta.total_time)
                    this.timesheets = result.data.map((timesheet) => {
                        timesheet.time_string = this.formatFloatToTime(timesheet.time)
                        return timesheet
                    })
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        fetchProjects() {
            this.projectsLoading = true
            this.$store.dispatch('projectsRequest', {per_page: -1})
                .then((result) => {
                    this.projectsLoading = false
                    this.allProjects = result.data
                })
                .catch((err) => {
                    this.projectsLoading = false
                })
        },
        fetchUsers() {
            this.usersLoading = true
            this.$store.dispatch('usersRequest', {per_page: -1})
                .then((result) => {
                    this.usersLoading = false
                    this.allUsers = result.data
                })
                .catch((err) => {
                    this.usersLoading = false
                })
        },
        fetchTags() {
            this.tagsLoading = true
            let queries = {search: JSON.stringify({taggable_type: 'timesheet'}), per_page: -1}
            this.$store.dispatch('tagsRequest', queries)
                .then((result) => {
                    this.tagsLoading = false
                    this.allTags = result.data
                })
                .catch((err) => {
                    this.tagsLoading = false
                })
        },
        editTimesheet(timesheet) {
            this.editedTimesheet = Object.assign({}, timesheet)
            this.dialogEditTimesheet = true
        },
        handleCanceledTimesheetForm() {
            this.editedTimesheet = {}
            this.dialogEditTimesheet = false
        },
        handleSavedTimesheetForm(timesheet) {
            this.dialogEditTimesheet = false
            this.fetchTimesheets()
        },
        deleteTimesheet(timesheet_id) {
            return this.$store.dispatch('timesheetDeleteRequest', { id: timesheet_id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('timesheet.deleted')});
                this.fetchTimesheets()
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.dispatch('setErrors', err.response.data)
            });
        },
        formatFloatToTime(float) {
            let time = ((float<10)?'0':'') 
                            + String(Math.trunc(float))
                            + ':'
                            +  (Math.round((float - Math.trunc(float)) * 60)<10?'0':'')
                            + String(Math.round((float - Math.trunc(float)) * 60))
            return time
        },
        deleteToApi(timesheet) {
            return this.$store.dispatch('timesheetDeleteRequest', { id: timesheet.id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('timesheet.deleted')});
                this.fetchTimesheets()
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
    },
}
</script>
