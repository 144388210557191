<style scoped>
    .v-data-table >>> td {
        vertical-align: top;
        padding: 16px;
    }
    .v-data-table >>> .remove-btn {
        margin-top: -10px;
    }
</style>
<template>
    <div>
        <v-data-table
            disable-sort
            :items="value"
            :items-per-page="-1"
            item-key="id"
            hide-default-footer
            hide-default-header
            no-data-text="No capabilities"
            show-expand
            :class="capabilitiesClass"
        >
            <template v-slot:body="{ items, expand, isExpanded, headers }">
                <draggable 
                    :list="Object.assign([], value)"
                    @end="updateCapabilitiesIndex"
                    tag="tbody"
                    handle=".capability-drag"
                    draggable="tr"
                >
                    <template v-for="item in items">
                        <tr :key="'cap'+item.id">
                            <td>
                                <RemoveButton
                                    depressed
                                    text
                                    small
                                    @confirmed="remove(item)"
                                />
                                <DuplicateButton
                                    form="CapabilityForm"
                                    :item="item"
                                    depressed
                                    text
                                    small
                                    @duplicated="duplicated"
                                />
                            </td>
                            <td>
                                <v-icon class="capability-drag">drag_indicator</v-icon>
                            </td>
                            <td>
                                <v-edit-dialog 
                                    @save="submit(item, 'refInput'+item.id)"
                                    :ref="'refInput'+item.id"
                                    :return-value.sync="item.ref"
                                >
                                    #{{ item.ref }}
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="item.ref"
                                            :label="$t('capability.ref')"
                                            single-line
                                        ></v-text-field>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['refInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'refInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-edit-dialog 
                                    @save="submit(item, 'nameInput'+item.id)"
                                    :ref="'nameInput'+item.id"
                                    :return-value.sync="item.name"
                                >
                                    <span class="text-no-wrap headline">{{ item.name }}</span>
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="item.name"
                                            :label="$t('capability.name')"
                                            single-line
                                        ></v-text-field>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['nameInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'nameInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-edit-dialog 
                                    @save="submit(item, 'descriptionInput'+item.id)"
                                    :ref="'descriptionInput'+item.id"
                                    :return-value.sync="item.description"
                                >
                                    <span v-html="item.description"></span>
                                    <template v-slot:input>
                                        <v-textarea
                                            v-model="item.description"
                                            filled
                                            :label="$t('capability.description')"
                                        ></v-textarea>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['descriptionInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'descriptionInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-edit-dialog 
                                    @save="submit(item, 'profitsInput'+item.id)"
                                    :ref="'profitsInput'+item.id"
                                    :return-value.sync="item.profits"
                                >
                                    <span v-html="item.profits"></span>
                                    <template v-slot:input>
                                        <v-textarea
                                            v-model="item.profits"
                                            filled
                                            :label="$t('capability.profits')"
                                        ></v-textarea>
                                        <div class="d-flex mb-2">
                                            <v-btn text depressed small @click="$refs['profitsInput'+item.id][0].cancel()"><v-icon>clear</v-icon></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn text depressed small @click="submit(item, 'profitsInput'+item.id)"><v-icon>save</v-icon></v-btn>
                                        </div>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td>
                                <v-chip small dark v-if="capabilities_estimate[item.id] > 0">{{ capabilities_estimate[item.id] }} sp</v-chip>
                            </td>
                            <td class="text-end">
                                <v-icon :class="capabilitiesClass" @click="expand(item, !isExpanded(item))">{{ isExpanded(item) ? 'remove' : 'keyboard_arrow_down' }}</v-icon>
                            </td>
                        </tr>
                        <tr :key="'capExp'+item.id" v-if="isExpanded(item)">
                            <td colspan="8" class="pa-0">
                                <messages messageable_type="capability" :messageable_id="item.id" :key="item.id" :dark="false" :headerClass="capabilitiesClass" :contentClass="capabilitiesClass"/>
                                <FeatureIndex 
                                    v-model="item.features" 
                                    :featuresClass="featuresClass" 
                                    :storiesClass="storiesClass" 
                                    :capability_id="item.id" 
                                    @droppedInCapability="changeFeatureCapability"
                                    @droppedStoryInCapability="changeStoryCapability"
                                />
                                <v-btn
                                    @click="addFeature({capability_id: item.id, priority: 'medium', status: 'draft', project_id: item.project_id})"
                                    depressed
                                    block
                                    x-large
                                    :class="featuresClass"
                                >
                                    <v-icon>add</v-icon>
                                    {{ $t('add') }} {{ $t('object.feature') }}
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </draggable>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogAddFeature" max-width="80%" persistent scrollable>
            <v-card>
                <v-card-title class="primary white--text">
                    {{ $t('object.feature') }}
                </v-card-title>
                <v-card-text>
                    <FeatureForm ref="FeatureForm" v-model="editedFeature" :key="Date.now()" @savedForm="handleSavedFeatureForm" @canceledForm="handleCanceledFeatureForm"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import FeatureIndex from '@/components/feature/Index'
import FeatureForm from '@/components/feature/Form'
import draggable from 'vuedraggable'
export default {
    components: {
        FeatureIndex,
        FeatureForm,
        draggable,
    },
    props: {
        value: {
            type: Array,
            required: true
        },
        capabilitiesClass: {
            type: String,
            default: '',
        },
        featuresClass: {
            type: String,
            default: '',
        },
        storiesClass: {
            type: String,
            default: '',
        },
    },
    computed: {
        capabilities_estimate() {
            let res = []
            this.value.forEach(function(c) {
                c.features.forEach(function(f) {
                    f.stories_estimate = f.stories.reduce((acc,s) => acc + parseFloat(s.estimate || 0), 0)
                })
                res[c.id] = c.features.reduce((acc, f) => acc + parseFloat(f.stories_estimate || f.estimate || 0), 0)
                c.estimate = res[c.id]
            })
            return res
        },
    },
    data(){
        return {
            dialogAddFeature: false,
            editedFeature: {},
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
        }
    },
    methods: {
        save(item) {
            this.$store.dispatch('capabilityEditRequest', {id: item.id, datas: item})
                .then((capability) => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('capability.saved')});
                    let i = this.value.findIndex(s => s.id == capability.id)
                    if (i >= 0) Object.assign(this.value[i], capability)
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        submit(item, ref) {
            this.save(Object.assign({}, item))
            this.$refs[ref][0].cancel()
        },
        remove(item) {
            return this.$store.dispatch('capabilityDeleteRequest', {id: item.id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('capability.deleted')});
                let i = this.value.findIndex(c => c.id == item.id)
                this.value.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        addFeature(feature) {
            this.editedFeature = feature
            this.dialogAddFeature = true
        },
        handleCanceledFeatureForm() {
            this.editedFeature = {}
            this.dialogAddFeature = false
        },
        handleSavedFeatureForm(feature) {
            this.dialogAddFeature = false
            let capability = this.value.find(c => c.id == feature.capability_id)
            if (capability)
                capability.features.push(feature)
        },
        updateCapabilitiesIndex(event) {
            let mooved = this.value[event.oldIndex]
            this.value.splice(event.oldIndex, 1)
            this.value.splice(event.newIndex, 0, mooved)

            let newPos = this.value.map((c) => c.id)
            let queries = {object: 'capabilities', datas: {positioning: newPos}}

            this.$store.dispatch('updatePositioningRequest', queries)
                .then(() => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                })
        },
        duplicated(item) {
            this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('capability.saved')});
            if (item.project_id == this.value[0].project_id)
                this.value.push(item)
        },
        changeFeatureCapability(feature, position) {
            // remove action is processed in child component (FeatureIndex)
            // add in to
            let to = this.value.find(c => c.id == feature.capability_id)
            to.features.splice(position, 0, feature)
            // update features positions
            let newPos = to.features.map((f) => f.id)
            let queries = {object: 'features', datas: {positioning: newPos}}
            this.$store.dispatch('updatePositioningRequest', queries)
                .then(() => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                })
        },
        changeStoryCapability(story, event) {
            // remove action is processed in child component (FeatureIndex)
            // add in to
            let to_capabilty = this.value.find(c => c.id == event.to.dataset.capabilityId)
            let to_feature = to_capabilty.features.find(f => f.id == story.feature_id)
            to_feature.stories.splice(event.newIndex, 0, story)
            // update stories positions
            let newPos = to_feature.stories.map((s) => s.id)
            let queries = {object: 'stories', datas: {positioning: newPos}}
            this.$store.dispatch('updatePositioningRequest', queries)
                .then(() => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                })
        },
    },
}
</script>