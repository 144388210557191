<template>
    <div>
        <v-expansion-panels dark>
            <v-expansion-panel>
                <v-expansion-panel-header @click="!is_default ? getLexiconsFromApi : null">
                        {{ is_default ? $t('object.default_lexicon') : $t('object.lexicon') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row align="center">
                        <v-col>
                            <v-text-field
                                v-model="lexiconSearch"
                                append-icon="search"
                                :label="$t('search')"
                                filled
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-spacer/>
                    </v-row>
                    <v-row>
                        <v-col v-if="lexicons">
                            <v-data-table
                                :headers="[
                                    { text: $t('lexicon.title'), value: 'title' },
                                    { text: $t('lexicon.description'), value: 'description' },
                                    { text: '', value: '' },
                                ]"
                                :items="lexicons"
                                :items-per-page="is_default ? 10 : lexicons.length + 1"
                                :search="lexiconSearch"
                                :hide-default-footer="!is_default"
                                :class="$vuetify.breakpoint.smAndDown ? 'mobile' : ''"
                            >
                                <template v-slot:item="{item}">
                                    <v-hover>
                                        <template v-slot:default="{ hover }">
                                            <tr>
                                                <td :data-label="$t('lexicon.title')">
                                                    {{ item.title }}
                                                </td>
                                                <td :data-label="$t('lexicon.description')" v-html="item.description">
                                                    <!-- {{ item.description }} -->
                                                </td>
                                                <td class="justify-center">
                                                    <div :style="! hover ? 'visibility: hidden' : ''">
                                                        <v-btn
                                                            fab
                                                            color="primary"
                                                            @click.stop="editLexicon(item)"
                                                            small
                                                            class="mr-2"
                                                            depressed
                                                        >
                                                            <v-icon>edit</v-icon>
                                                        </v-btn>
                                                        <RemoveButton 
                                                            color="red darken-1"
                                                            small
                                                            depressed
                                                            @confirmed="deleteLexicon(item.id)"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-hover>
                                </template>
                                <template v-slot:no-data>
                                    <no-data/>
                                </template>
                            </v-data-table>
                        </v-col>
                        <v-col v-else>
                            {{ $t('no_datas') }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="auto">
                            <v-btn @click="editLexicon({'project_id': project_id, description: '', title: ''})">
                                <v-icon>add</v-icon>
                                {{ $t('add') }}
                            </v-btn>
                        </v-col>

                        <v-dialog v-model="dialogAddLexicon" max-width="80%" persistent scrollable>
                            <v-card>
                                <v-card-title class="primary white--text">
                                    {{ $t('object.lexicon') }}
                                </v-card-title>
                                <v-card-text>
                                    <v-form v-on:submit.prevent="onSubmit" ref="form">
                                        <v-row justify="center">
                                            <v-col cols="12">
                                                <v-text-field
                                                    v-model="editedLexicon.title"
                                                    filled
                                                    :label="$t('lexicon.title')"
                                                    :rules="[rules.required]"
                                                ></v-text-field>

                                                <wyswyg v-if="dialogAddLexicon" v-model="editedLexicon.description" :label="$t('lexicon.description')"/>
                                            </v-col>
                                        </v-row>
                                        <v-row justify="center" class="mt-5">
                                            <v-btn @click.stop="cancelForm" text color="primary">{{ $t('cancel') }}</v-btn>
                                            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">{{ $t('save') }}</v-btn>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
export default {
    props: {
        project_id: {
            type: [String,Number],
            required: false
        },
        values: {
            type: Array,
            required: false
        },
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
            },
            lexicons : [],
            ready: false,
            lexiconSearch: '',
            dialogAddLexicon: false,
            editedLexicon: {},
            is_default: false,
        }
    },
    mounted () {
        if (this.project_id) {
            this.getLexiconsFromApi()
        }
        else {
            this.is_default = true
            this.lexicons = this.values
        }

        this.ready = true
    },
    methods: {
        getLexiconsFromApi() {
            this.$store.dispatch('lexiconsRequest', { 
                search: JSON.stringify({project_id: this.project_id}),
                per_page: -1
            }).then(data => {
                    this.lexicons = data.data
                    this.ready = true
                })
        },
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true

                let request = 'lexiconCreateRequest'
                let queries = this.editedLexicon

                if (this.editedLexicon.id) {
                    request = 'lexiconEditRequest'
                    queries = {id: this.editedLexicon.id, datas: this.editedLexicon}
                }

                if (this.is_default)
                    request = 'default' + request.charAt(0).toUpperCase() + request.slice(1)

                this.$store.dispatch(request, queries)
                    .then((lexicon) => {
                        this.$refs.form.resetValidation()
                        this.loading = false;
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('lexicon.saved')});

                        this.dialogAddLexicon = false
                        let i = this.lexicons.findIndex(l => l.id == lexicon.id)
                        if (i >= 0)
                            Object.assign(this.lexicons[i], lexicon)
                        else
                            this.lexicons.push(lexicon)
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
        deleteLexicon(lexicon_id) {
            let request = 'lexiconDeleteRequest'
            if (this.is_default)
                request = 'defaultLexiconDeleteRequest'

            return this.$store.dispatch(request, { id: lexicon_id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('lexicon.deleted')});
                let i = this.lexicons.findIndex(l => l.id == lexicon_id)
                this.lexicons.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        editLexicon(lexicon) {
            this.editedLexicon = Object.assign({}, lexicon)
            this.dialogAddLexicon = true
        },
        cancelForm() {
            this.editedLexicon = {'project_id': this.project_id}
            this.dialogAddLexicon = false
        },
    }
}
</script>