<template>
    <v-content class="stories" v-if="ready">    
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon x-large class="mr-5">{{ $t('story.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.projects'), to: {name: 'Projects'}},
                        {text: project.complete_name, to: {name: 'Project', params: {id: project.id}}},
                        {text: $t('object.sprints')+' & '+$t('object.stories') },
                    ]"/>
                    <v-spacer/>
                    <v-col cols="auto">
                        <ProjectMenu v-model="project" small/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid fill-height class="pa-0">
            <v-row class="fill-height flex-column ma-0">
                <v-col cols="12" class="flex-0">
                    <v-row align="center" no-gutters>
                        <v-col>
                            <v-tabs grow show-arrows :color="$vuetify.theme.dark ? 'white' : 'primary'">
                                <v-tab @click="search.sprints = [...project.sprints.map(s => s.id), null]" class="justify-start">{{ $t('story.all') }}</v-tab>
                                <v-tab @click="search.sprints = [sprint.id]" v-for="sprint in project.sprints" :key="sprint.id">
                                    <v-hover>
                                        <template v-slot:default="{ hover }">
                                            <v-row align="center" justify="space-between">
                                                <div>
                                                    {{ sprint.name }}
                                                    <small>({{ sprint.stories.reduce((acc, s) => acc + parseFloat(s.estimate || 0), 0) }} sp)</small>
                                                </div>
                                                <v-btn
                                                    :style="{opacity: hover ? 1 : 0}"
                                                    @click.stop="showSprint(sprint)"
                                                    x-small
                                                    fab
                                                    depressed
                                                    class="mx-2"
                                                >
                                                    <v-icon>visibility</v-icon>
                                                </v-btn>
                                            </v-row>
                                        </template>
                                    </v-hover>
                                </v-tab>
                                <v-tab @click="search.sprints = [null]" class="justify-start">{{ $t('story.no_sprint') }}</v-tab>
                            </v-tabs>
                        </v-col>
                        <v-col cols="12" md="auto" class="text-center">
                            <v-btn
                                text
                                large
                                @click="editSprint({project_id: project.id})"
                            >
                                <v-icon>add</v-icon>
                                {{ $t('add') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="search.tags"
                                :items="allTags"
                                :label="$t('object.tags')"
                                item-text="name"
                                clearable
                                dense
                                multiple
                                chips
                                solo
                                flat
                                deletable-chips
                                hide-details
                                :return-object="false"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12" class="flex-1">
                    <StoryKanban :project_id="project.id" :stages="stages" :stagesFilter="showStages" :filter="search" @savedStoryForm="handleSavedStoryForm" @savedForm="handleSavedStoryForm"/>
                </v-col>
            </v-row>

            <v-dialog v-model="dialogShowSprint" max-width="80%">
                <v-card v-if="showedSprint">
                    <v-card-title class="secondary white--text">
                        <RemoveButton 
                            color="secondary"
                            small
                            depressed
                            @confirmed="deleteSprint(showedSprint.id)"
                        />
                        {{ showedSprint.name }}
                        <v-spacer/>
                        <v-btn icon dark @click="exportSprint(showedSprint)" :loading="loadingExportPrint"><v-icon>download</v-icon></v-btn>
                        <v-btn @click="dialogPrintSprint = true" icon dark><v-icon>print</v-icon></v-btn>
                        <v-btn icon dark @click="editSprint(showedSprint)"><v-icon>edit</v-icon></v-btn>
                        <v-btn icon dark @click="dialogShowSprint = false"><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('object.project') }}</v-list-item-title>
                                        <v-row>
                                            <v-col>{{ showedSprint.project_name }}</v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('sprint.description') }}</v-list-item-title>
                                        <v-row>
                                            <v-col v-html="showedSprint.description"></v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('sprint.status') }}</v-list-item-title>
                                        <v-row>
                                            <v-col><v-chip outlined :color="$vuetify.theme.dark ? 'white' : 'primary'">{{ $t('sprint.'+showedSprint.status) }}</v-chip></v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('sprint.estimate') }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-chip outlined :color="$vuetify.theme.dark ? 'white' : 'primary'">{{ showedSprint.stories.reduce((acc, s) => acc + parseFloat(s.estimate || 0), 0) }}</v-chip>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ $t('object.stories') }}</v-list-item-title>
                                        <v-row>
                                            <v-col>
                                                <v-data-table 
                                                    :items-per-page="-1"
                                                    :items="showedSprint.stories"
                                                    item-key="id"
                                                    :headers="[
                                                        {text: $t('story.ref'), value: 'ref', align: 'center'},
                                                        {text: $t('story.as'), value: 'as', align: 'start',},
                                                        {text: $t('story.be_able_to'), value: 'be_able_to', align: 'start',},
                                                        {text: $t('story.so_that'), value: 'so_that', align: 'start',},
                                                        {text: $t('story.priority'), value: 'priority', align: 'center'},
                                                        {text: $t('story.status'), value: 'status', align: 'center'},
                                                        {text: $t('story.estimate'), value: 'estimate', align: 'center'},
                                                    ]"
                                                    :class="$vuetify.breakpoint.mdAndDown ? 'mobile' : ''"
                                                    show-expand
                                                >
                                                    <!-- <template v-slot:item.text="{ item }">
                                                        <span class="grey--text text--darken-2">{{ $t('story.as') }}</span> {{ item.as.charAt(0).toLowerCase() + item.as.slice(1) }},
                                                        <span class="grey--text text--darken-2">{{ $t('story.be_able_to') }}</span> {{ item.be_able_to.charAt(0).toLowerCase() + item.be_able_to.slice(1) }},
                                                        <span class="grey--text text--darken-2">{{ $t('story.so_that') }}</span> {{ item.so_that.charAt(0).toLowerCase() + item.so_that.slice(1) }} 
                                                    </template> -->

                                                    <template v-slot:expanded-item="{ headers, item }">
                                                        <td :colspan="headers.length" :class="{'white': !$vuetify.theme.dark, 'pa-3': true}">
                                                            <div class="grey--text text--darken-2 mb-1">{{ $t('story.details') }}:</div>
                                                            <div :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" v-html="item.details"></div>
                                                        </td>
                                                    </template>

                                                    <template v-slot:item.status="{ item }">
                                                        <edit-dialog 
                                                            :key="Math.random()"
                                                            @save="saveStoryStatus"
                                                            :value="item"
                                                            field="status"
                                                            :label="$t('story.status')"
                                                            type="select"
                                                            :items="[
                                                                {text: $t('story.draft'), value: 'draft'},
                                                                {text: $t('story.backlog'), value: 'backlog'},
                                                                {text: $t('story.planified'), value: 'planified'},
                                                                {text: $t('story.open'), value: 'open'},
                                                                {text: $t('story.cancel'), value: 'cancel'},
                                                                {text: $t('story.done'), value: 'done'},
                                                                {text: $t('story.waiting'), value: 'waiting'},
                                                            ]"
                                                        >
                                                            {{ $t('story.'+item.status) }}
                                                        </edit-dialog>
                                                    </template>

                                                    <template v-slot:no-data>
                                                        <no-data/>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                        <v-dialog v-model="dialogEditStory" max-width="80%" persistent scrollable>
                                            <v-card>
                                                <v-card-title class="secondary white--text">
                                                    {{ $t('object.story') }}
                                                </v-card-title>
                                                <v-card-text>
                                                    <StoryForm ref="StoryForm" v-model="editedStory" :key="Date.now()" @savedForm="handleSavedStoryForm" @canceledForm="handleCanceledStoryForm"/>
                                                </v-card-text>
                                            </v-card>
                                        </v-dialog>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-0 py-0">
                        <v-row no-gutters>
                            <v-col>
                                <messages messageable_type="sprint" :messageable_id="showedSprint.id" :key="Date.now()"/>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEditSprint" max-width="95%" persistent scrollable>
                <v-card>
                    <v-card-title class="secondary white--text">
                        {{ $t('object.sprint') }}
                    </v-card-title>
                    <v-card-text>
                        <SprintForm ref="SprintForm" v-model="editedSprint" :key="Date.now()" @savedForm="handleSavedSprintForm" @canceledForm="handleCanceledSprintForm"/>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogPrintSprint" max-width="80%" persistent scrollable>
                <v-card>
                    <v-card-title class="secondary white--text">
                        {{ $t('printParams') }}
                    </v-card-title>
                    <v-card-text>
                        <v-form v-on:submit.prevent="printSprint">
                            <v-switch v-model="printParams.showDetails" 
                                :label="`${$t('story.details')}: ${printParams.showDetails ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>

                            <v-select
                                v-model="printParams.statusStories"
                                :items="storyStatus"
                                :label="$t('story.status')"
                                filled
                                multiple
                            ></v-select>

                            <v-row justify="center" class="mt-5">
                                <v-btn @click.stop="dialogPrintSprint = false" text color="secondary" x-large>{{ $t('cancel') }}</v-btn>
                                <v-btn color="primary" type="submit" :loading="loadingSprintPrint" x-large>{{ $t('print') }}</v-btn>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </v-content>
</template>
<script>
import StoryKanban from '@/components/story/Kanban'
import SprintForm from '@/components/sprint/Form'
import StoryForm from '@/components/story/Form'
import ProjectMenu from '@/components/project/Menu'
import i18n from '@/plugins/i18n/i18n'

const storyStatus = [
    {text: i18n.t('story.draft'), value: 'draft'},
    {text: i18n.t('story.backlog'), value: 'backlog'},
    {text: i18n.t('story.planified'), value: 'planified'},
    {text: i18n.t('story.open'), value: 'open'},
    {text: i18n.t('story.cancel'), value: 'cancel'},
    {text: i18n.t('story.done'), value: 'done'},
    {text: i18n.t('story.waiting'), value: 'waiting'},
]

export default {
    components: {
        StoryKanban,
        SprintForm,
        StoryForm,
        ProjectMenu,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data () {
        return {
            ready: false,
            project: {},
            loading: false,
            stages: ['draft', 'backlog', 'planified', 'open', 'cancel', 'done', 'waiting'],
            showStages: ['draft', 'backlog', 'planified', 'open', 'done'],
            search: this.$route.query.search ? JSON.parse(this.$route.query.search) : {sprints: []},
            dialogShowSprint: false,
            dialogEditSprint: false,
            showedSprint: {stories: []},
            editedSprint: {},
            allTags: [],
            dialogEditStory: false,
            editedStory: {},
            loadingSprintPrint: false,
            loadingExportPrint: false,
            dialogPrintSprint: false,
            storyStatus: storyStatus,
            printParams: {
                showDetails: 1,
                statusStories: storyStatus.map(s => s.value),
            },
        }
    },
    mounted () {
        this.fetchProject()
        this.fetchTags()
    },
    computed: {
        // 
    },
    methods: {
        fetchProject() {
            this.loading = true
            this.$store.dispatch('projectRequest', {id: this.id, with: ['sprints.stories']})
                .then(result => {
                    this.project = result.data
                    if (! this.project.stories) this.project.stories = []
                    this.loading = false
                    this.ready = true
                    if (! this.search.sprints.length) {
                        this.search.sprints = this.project.sprints.map(s => s.id)
                        this.search.sprints.push(null)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        fetchTags() {
            let queries = {search: JSON.stringify({taggable_type: 'story'}), per_page: -1}
            this.$store.dispatch('tagsRequest', queries)
                .then((result) => {
                    this.allTags = result.data
                })
                .catch((err) => {
                })
        },
        showSprint(sprint) {
            this.showedSprint = sprint
            this.dialogShowSprint = true
        },
        editSprint(sprint) {
            this.editedSprint = sprint
            this.dialogEditSprint = true
        },
        handleCanceledSprintForm() {
            this.editedSprint = {}
            this.dialogEditSprint = false
        },
        handleSavedSprintForm(sprint) {
            this.dialogEditSprint = false
            let i = this.project.sprints.findIndex(m => m.id == sprint.id)
            if (i >= 0)
                Object.assign(this.project.sprints[i], sprint)
            else
                this.project.sprints.push(sprint)
        },
        // handleSavedStoryForm(story) {
        //     this.fetchProject()
        // },
        deleteSprint(sprint_id) {
            return this.$store.dispatch('sprintDeleteRequest', { id: sprint_id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': 'Sprint supprimée !'});
                let i = this.project.sprints.findIndex(m => m.id == sprint_id)
                this.project.sprints.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        printSprint() {
            this.loadingSprintPrint = true
            let queries = {
                id: this.showedSprint.id,
                datas: this.printParams,
            }
            let filename = '['+this.project.name+'] '+this.showedSprint.name+'.pdf'
            return this.$store.dispatch('sprintPrintRequest', queries)
                .then((data) => {
                    const url = window.URL.createObjectURL(new Blob([data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }).finally(() => this.loadingSprintPrint = false);
        },
        editStory(story) {
            this.editedStory = story
            this.dialogEditStory = true
        },
        handleCanceledStoryForm() {
            this.editedStory = {}
            this.dialogEditStory = false
        },
        handleSavedStoryForm(story) {
            this.dialogEditStory = false
            if (story.sprint_id) {
                let i = this.project.sprints.findIndex(m => m.id == story.sprint_id)
                if (i >= 0) {
                    let k = this.project.sprints[i].stories.findIndex(s => s.id == story.id)
                    if (k >= 0) {
                        Object.assign(this.project.sprints[i].stories[k], story)
                        return
                    }
                }
            }
            let i = this.project.stories.findIndex(m => m.id == story.id)
            if (i >= 0) {
                Object.assign(this.project.stories[i], story)
                return
            }

            this.project.stories.push(story)
        },
        deleteStory(story_id) {
            return this.$store.dispatch('storyDeleteRequest', { id: story_id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.deleted')});
                let i = this.project.stories.findIndex(m => m.id == story_id)
                this.project.stories.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        saveStoryStatus(item) {
            this.$store.dispatch('storyEditRequest', {id: item.id, datas: item})
                .then((story) => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.saved')});
                    this.handleSavedStoryForm(story)
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        exportSprint(sprint) {
            this.loadingExportPrint = true
            let queries = {
                id: this.showedSprint.id,
                export: true,
            }
            let filename = '['+this.project.name+'] '+this.showedSprint.name+'.csv'
            return this.$store.dispatch('sprintExportRequest', queries)
                .then((data) => {
                    const url = window.URL.createObjectURL(new Blob([data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }).finally(() => this.loadingExportPrint = false);
        }
    }
}
</script>
