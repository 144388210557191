<template>
    <div class="wyswyg mb-7" :style="!! label ? 'padding-top:30px;' : ''">
        <div class="label">{{ label }}</div>
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
            <div>
                <v-btn text :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
                    <v-icon>format_bold</v-icon>
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
                    <v-icon>format_italic</v-icon>
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
                    <v-icon>strikethrough_s</v-icon>
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
                    <v-icon>format_underline</v-icon>
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.code() }" @click="commands.code">
                    <v-icon>code</v-icon>
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
                    p
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })">
                    H1
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })">
                    H2
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })">
                    H3
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
                    <v-icon>format_list_bulleted</v-icon>
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list">
                    <v-icon>format_list_numbered</v-icon>
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote">
                    <v-icon>format_quote</v-icon>
                </v-btn>
                <v-btn text :class="{ 'is-active': isActive.code_block() }" @click="commands.code_block">
                    <v-icon>code</v-icon>
                </v-btn>
                <v-btn text @click="commands.horizontal_rule">
                    <v-icon>horizontal_rule</v-icon>
                </v-btn>
                <v-btn text @click="commands.undo">
                    <v-icon>undo</v-icon>
                </v-btn>
                <v-btn text @click="commands.redo">
                    <v-icon>redo</v-icon>
                </v-btn>
                <hr>
            </div>
        </editor-menu-bar>
        <v-input hide-details>
            <editor-content class="editor" :editor="editor"/>
        </v-input>
    </div>
</template>
<style>
    .wyswyg {
        background-color: rgba(0, 0, 0, 0.06);
        position: relative;
    }
    .wyswyg .label {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
        font-size: 16px;
        position: absolute;
        top: 8px;
    }
    .wyswyg .editor {
        width: 100%;
    }
    .wyswyg .editor .ProseMirror:not(.ProseMirror-focused):hover {
        background-color: rgba(0, 0, 0, 0.12);
        cursor: text;
    }
    .wyswyg .editor .ProseMirror {
        width: 100%;
        height: 100%;
        padding: 8px;
    }
    .wyswyg .editor .ProseMirror:focus {
        outline: none;
    }
    .wyswyg .editor .ProseMirror:not(.ProseMirror-focused) p.is-editor-empty:first-child::before {
        content: attr(data-empty-text);
        float: left;
        pointer-events: none;
        height: 0;
    }
</style>
<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
    Blockquote,
    BulletList,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    ListItem,
    OrderedList,
    TodoItem,
    TodoList,
    Link,
    Bold,
    Code,
    Italic,
    Strike,
    Underline,
    History,
    Placeholder,
} from 'tiptap-extensions'
export default {
    components: {
        EditorContent,
        EditorMenuBar,
    },
    props: {
        value: {type: String, required: true},
        label: {type: String, default: ''},
        placeholder: {type: String, default: ''},
    },
    data () {
        return {
            editor: null,
        }
    },
    mounted () {
        this.initEditor(this.value)
    },
    beforeDestroy() {
        this.editor.destroy()
    },
    methods: {
        initEditor(value) {
            if (this.editor)
                this.editor.destroy()
            this.editor = new Editor({
                content: value,
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({ levels: [1, 2, 3] }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Strike(),
                    new Underline(),
                    new History(),
                    new Placeholder({
                        emptyEditorClass: 'is-editor-empty',
                        emptyNodeClass: 'is-empty',
                        emptyNodeText: this.placeholder,
                        showOnlyWhenEditable: true,
                        showOnlyCurrent: true,
                    }),
                ],
                onUpdate: ({getHTML}) => {
                    this.$emit('input', getHTML())
                }
            })
        }
    },
}
</script>