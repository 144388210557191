<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form">
        <v-row no-gutters>
            <v-col cols="auto">
                <v-text-field
                    v-model="timesheet.date"
                    filled
                    :label="$t('timesheet.date')"
                    :rules="[rules.required]"
                    type="date"
                    hide-details
                    height="68px"
                ></v-text-field>
            </v-col>
            <v-col cols="auto">
                <v-autocomplete
                    v-model="timesheet.project_id"
                    :items="allProjects"
                    :label="$t('object.project')"
                    :item-text="p => p.complete_name"
                    item-value="id"
                    :rules="[rules.required]"
                    filled
                    hide-details
                    height="68px"
                    autofocus
                ></v-autocomplete>
            </v-col>
            <v-col cols="auto">
                <v-autocomplete
                    v-model="timesheet.user_id"
                    :items="allUsers"
                    :label="$t('object.user')"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                    filled
                    hide-details
                    height="68px"
                ></v-autocomplete>
            </v-col>
            <!-- <v-col cols="auto">
                <v-autocomplete
                    v-model="timesheet.timed"
                    :items="allTimables"
                    :search-input.sync="searchTimable"
                    :label="$t('timesheet.timed')"
                    item-text="timable_ref"
                    return-object
                    filled
                    hide-no-data
                    hide-details
                ></v-autocomplete>
            </v-col> -->
            <v-col>
                <v-text-field
                    v-model="timesheet.description"
                    filled
                    :label="$t('timesheet.description')"
                    :rules="[rules.required]"
                    hide-details
                    height="68px"
                ></v-text-field>
            </v-col>
            <v-col>
                <v-combobox
                    v-model="timesheet.tags"
                    :items="allTags"
                    :label="$t('object.tags')"
                    item-text="name"
                    clearabme
                    multiple
                    chips
                    filled
                    deletable-chips
                    hide-details
                ></v-combobox>
            </v-col>
            <v-col cols="auto">
                <v-text-field
                    v-model="timesheet.time"
                    filled
                    :label="$t('timesheet.time')"
                    type="time"
                    hide-details
                    height="68px"
                ></v-text-field>
            </v-col>
            <v-col cols="auto">
                <v-btn color="primary" type="submit" depressed :disabled="loading" :loading="loading" :style="{height: '100%'}">
                    <v-icon>done</v-icon>
                </v-btn>
                <v-btn @click.stop="cancel" text color="primary" :style="{height: '100%'}">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-col>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            timesheet: Object.assign({}, this.value),
            allProjects: [],
            allUsers: [],
            allTags: [],
            // allTimables: [],
            // searchTimable: null,
        }
    },
    computed: {
        // 
    },
    mounted() {
        this.timesheet.time = this.formatFloatToTime(this.timesheet.time)
        this.fetchProjects()
        this.fetchUsers()
        this.fetchTags()
    },
    // watch: {
    //     searchTimable (val) {
    //         val && val !== (this.timesheet.timed ? this.timesheet.timed.timable_ref : '') && this.fetchTimables(val)
    //     },
    // },
    methods: {
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                this.timesheet.time = this.formatTimeToFloat(this.timesheet.time)

                let request = 'timesheetCreateRequest'
                let queries = this.timesheet

                if (this.timesheet.id) {
                    request = 'timesheetEditRequest'
                    queries = {id: this.timesheet.id, datas: this.timesheet}
                }

                this.$store.dispatch(request, queries)
                    .then((timesheet) => {
                        this.loading = false;
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('timesheet.saved')});
                        this.$emit('savedForm', timesheet);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
        fetchProjects() {
            this.$store.dispatch('projectsRequest', {per_page: -1, with: ['partner']})
                .then((result) => {
                    this.allProjects = result.data
                })
                .catch((err) => {
                })
        },
        fetchUsers() {
            let queries = {search: JSON.stringify({type: 'project'}), per_page: -1}
            this.$store.dispatch('usersRequest', queries)
                .then((result) => {
                    this.allUsers = result.data
                })
                .catch((err) => {
                })
        },
        fetchTags() {
            let queries = {search: JSON.stringify({taggable_type: 'timesheet'}), per_page: -1}
            this.$store.dispatch('tagsRequest', queries)
                .then((result) => {
                    this.allTags = result.data
                })
                .catch((err) => {
                })
        },
        formatFloatToTime(float) {
            let time = ((float<10)?'0':'') 
                            + String(Math.trunc(float))
                            + ':'
                            +  (Math.round((float - Math.trunc(float)) * 60)<10?'0':'')
                            + String(Math.round((float - Math.trunc(float)) * 60))
            return time
        },
        formatTimeToFloat(time) {
            let [hours, minutes] = time.split(':')
            let float = parseFloat(parseInt(hours) + parseInt(minutes) / 60)
            return float
        },
        // fetchTimables() {
        //     let queries = {
        //         search: JSON.stringify({project_id: this.timesheet.project_id, text: this.searchTimable})
        //     }
        //     this.$store.dispatch('timablesRequest', queries)
        //         .then((result) => {
        //             this.allTimables = result.data.map((timable) => {
        //                 timable.timable_ref = this.$t('object.'+timable.classname)+' '+timable.id
        //                 return timable
        //             })
        //         })
        //         .catch((err) => {
        //         })
        // },
    }
}
</script>
