<template>
    <v-dialog
        v-model="confirmDialog"
        width="500"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                :color="color"
                v-on="on"
                dark
                :depressed="depressed"
                :fab="fab"
                :small="small"
                :text="text"
                class="remove-btn"
            >
                <v-icon>delete</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title
                class="headline"
                primary-title
            >
                {{ $t('confirm_text') }}
            </v-card-title>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn
                    color="primary"
                    depressed
                    @click="confirmDialog = false"
                    class="mx-2 my-2"
                >
                    {{ $t('cancel') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="error"
                    dark
                    depressed
                    @click="toconfirm"
                    class="mx-2 my-2"
                >
                    {{ $t('confirm') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        color: { type: String, default: 'error' },
        small: { type: Boolean, default: false },
        depressed: { type: Boolean, default: false },
        text: { type: Boolean, default: false },
        fab: { type: Boolean, default: true },
    },
    data () {
        return {
            confirmDialog: false,
        }
    },
    methods: {
        toconfirm() {
            this.confirmDialog = false
            this.$emit('confirmed')
        }
    }

}
</script>