<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form" class="mb-5">
        <v-row>
            <v-col cols="6">
                <v-text-field
                    v-model="user.name"
                    :label="$t('user.name')"
                    :rules="[rules.required]"
                    filled
                ></v-text-field>

                <v-text-field
                    v-model="user.email"
                    :label="$t('user.email')"
                    :rules="[rules.required]"
                    filled
                ></v-text-field>

                <v-select
                    v-model="user.type"
                    :label="$t('user.type')"
                    :items="[{value:'project',text:'projet'},{value:'crm',text:'crm'},{value:'portal',text:'portail'}]"
                    :rules="[rules.required]"
                    multiple
                    clearable
                    filled
                ></v-select>


                <label class="caption">{{ $t('user.color') }}</label>
                <v-color-picker
                    v-model="color"
                    hide-inputs
                    hide-canvas
                    flat
                    show-swatches
                    mode="hex"
                ></v-color-picker>
            </v-col>

            <v-col cols="6">
                <div v-if="! user.id">
                    <v-text-field
                        v-model="user.password"
                        :append-icon="show_password ? 'visibility' : 'visibility_off'"
                        :label="$t('user.password')"
                        :rules="[rules.required, rules.minlength]"
                        counter
                        @click:append="toggleShowPassword"
                        :type="show_password ? 'text' : 'password'"
                        hint="8 caractères min."
                        filled
                    ></v-text-field>

                    <v-text-field
                        v-model="user.confirm"
                        :append-icon="show_confirm ? 'visibility' : 'visibility_off'"
                        :label="$t('user.password_confirmation')"
                        :rules="[rules.required]"
                        counter
                        @click:append="toggleShowConfirm"
                        :type="show_confirm ? 'text' : 'password'"
                        filled
                    ></v-text-field>
                </div>

                <div v-if="user.id">
                    <v-text-field
                        v-model="user.old_password"
                        :append-icon="show_old_password ? 'visibility' : 'visibility_off'"
                        :label="$t('user.old_password')"
                        counter
                        @click:append="toggleShowOldPassword"
                        :type="show_old_password ? 'text' : 'password'"
                        filled
                    ></v-text-field>

                    <v-text-field
                        v-model="user.password"
                        :append-icon="show_password ? 'visibility' : 'visibility_off'"
                        :label="$t('user.password')"
                        :rules="[rules.minlength]"
                        counter
                        @click:append="toggleShowPassword"
                        :type="show_password ? 'text' : 'password'"
                        hint="8 caractères min."
                        filled
                    ></v-text-field>

                    <v-text-field
                        v-model="user.password_confirmation"
                        :append-icon="show_confirm ? 'visibility' : 'visibility_off'"
                        :label="$t('user.password_confirmation')"
                        counter
                        @click:append="toggleShowConfirm"
                        :type="show_confirm ? 'text' : 'password'"
                        filled
                    ></v-text-field>
                </div>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary" x-large>{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading" x-large>{{ $t('save') }}</v-btn>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
        callback: String,
    },
    data () {
        return {
            user: Object.assign({}, this.value),
            show_old_password: false,
            show_password: false,
            show_confirm: false,
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            color: null,
        }
    },
    watch: {
        user () {
            this.$refs.form.resetValidation()
        },
    },
    mounted() {
        if (this.user) this.color = this.user.color || '#000'
    },
    methods: {
        toggleShowConfirm () {
            this.show_confirm = !this.show_confirm
        },
        toggleShowPassword () {
            this.show_password = !this.show_password
        },
        toggleShowOldPassword () {
            this.show_old_password = !this.show_old_password
        },
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true

                this.user.color = this.color

                let request = 'userCreateRequest'
                let queries = this.user

                if (this.user.id) {
                    request = 'userEditRequest'
                    queries = {id: this.user.id, datas: this.user}
                }

                this.$store.dispatch(request, queries)
                    .then((user) => {
                        this.$refs.form.resetValidation()
                        this.loading = false
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('user.saved')})
                        if (user.id == this.$store.state.auth.profile.id)
                            this.$store.dispatch('profileRequest')
                        this.$router.push({name: 'User', params: {id: user.id}})
                    })
                    .catch((err) => {
                        this.loading = false
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                    })
            }
        },
        cancel () {
            this.user = Object.assign({}, this.value)
            // this.$emit('canceledForm');
            this.$router.push({name: 'Users'})
        },
    }
}
</script>
