import Vue from 'vue'
import i18n from '@/plugins/i18n/i18n'

const state = {
    // 
}

const getters = {
    // 
}

const actions = {
    mappingRequest: async ({commit}, queries) => {
        let url = '/api/_mapping?'+Object.entries(queries).map(e => e.join('=')).join('&')

        return new Promise((resolve, reject) => {
            Vue.axios.get(url)
                .then((resp) => {
                    resolve(resp.data)
                })
                .catch((err) => {
                    reject(err)
                })
            })
    },

}

const mutations = {
    // 
}

export default {
    state,
    getters,
    actions,
    mutations,
}