var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mobile",attrs:{"headers":[
        {text: _vm.$t('story.ref'), align: 'start', value: 'ref'},
        {text: _vm.$t('story.as'), align: 'start', value: 'as'},
        {text: _vm.$t('story.be_able_to'), align: 'start', value: 'be_able_to'},
        {text: _vm.$t('story.so_that'), align: 'start', value: 'so_that'},
        {text: 'estim.', align: 'start', value: 'estimate'},
        {text: _vm.$t('story.status'), align: 'start', value: 'status'},
        {text: '', align: 'start', value: 'actions'} ],"disable-sort":"","items":_vm.value,"items-per-page":-1,"item-key":"id","hide-default-footer":"","no-data-text":"No stories","show-select":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var items = ref.items;
    var expand = ref.expand;
    var isExpanded = ref.isExpanded;
    var headers = ref.headers;
    var isSelected = ref.isSelected;
    var select = ref.select;
return [_c('draggable',{attrs:{"list":Object.assign([], _vm.value),"tag":"tbody","handle":".story-drag","draggable":"tr","group":"stories","data-feature-id":_vm.feature.id},on:{"end":_vm.updateStoriesIndex}},[_vm._l((items),function(item){return [_c('tr',{key:'story'+item.id},[_c('td',[_c('v-checkbox',{attrs:{"multiple":"","value":item},model:{value:(_vm.selectedStories),callback:function ($$v) {_vm.selectedStories=$$v},expression:"selectedStories"}})],1),_c('td',[_c('edit-dialog',{key:Math.random(),attrs:{"value":item,"field":"ref","label":_vm.$t('story.ref'),"type":"string","required":""},on:{"save":_vm.save}},[_vm._v(" #"+_vm._s(item.ref)+" ")])],1),_c('td',[_c('edit-dialog',{key:Math.random(),attrs:{"value":item,"field":"as","label":_vm.$t('story.as'),"type":"string","required":""},on:{"save":_vm.save}},[_vm._v(" "+_vm._s(item.as)+" ")])],1),_c('td',[_c('edit-dialog',{key:Math.random(),attrs:{"value":item,"field":"be_able_to","label":_vm.$t('story.be_able_to'),"type":"text","required":"","size":"600"},on:{"save":_vm.save}},[_vm._v(" "+_vm._s(item.be_able_to)+" ")])],1),_c('td',[_c('edit-dialog',{key:Math.random(),attrs:{"value":item,"field":"so_that","label":_vm.$t('story.so_that'),"type":"text","required":"","size":"600"},on:{"save":_vm.save}},[_vm._v(" "+_vm._s(item.so_that)+" ")])],1),_c('td',[_c('edit-dialog',{key:Math.random(),attrs:{"value":item,"field":"estimate","label":_vm.$t('story.estimate'),"type":"string"},on:{"save":_vm.save}},[(['number', 'string'].indexOf(typeof item.estimate) > -1)?_c('span',[_vm._v(_vm._s(item.estimate)+" sp")]):_c('span')])],1),_c('td',[_c('edit-dialog',{key:Math.random(),attrs:{"value":item,"field":"status","label":_vm.$t('story.status'),"type":"select","items":[
                                {text: _vm.$t('story.draft'), value: 'draft'},
                                {text: _vm.$t('story.backlog'), value: 'backlog'},
                                {text: _vm.$t('story.planified'), value: 'planified'},
                                {text: _vm.$t('story.open'), value: 'open'},
                                {text: _vm.$t('story.cancel'), value: 'cancel'},
                                {text: _vm.$t('story.done'), value: 'done'},
                                {text: _vm.$t('story.waiting'), value: 'waiting'} ]},on:{"save":_vm.save}},[_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(_vm._s(_vm.$t('story.'+item.status)))])],1)],1),_c('td',{staticClass:"text-center text-no-wrap"},[_c('DuplicateButton',{attrs:{"form":"StoryForm","item":item,"text":"","small":""},on:{"duplicated":_vm.duplicated}}),_c('v-btn',{staticClass:"d-inline-block",attrs:{"text":"","small":"","fab":""}},[_c('v-icon',{staticClass:"story-drag"},[_vm._v("drag_indicator")])],1),_c('RemoveButton',{attrs:{"text":"","small":""},on:{"confirmed":function($event){return _vm.remove(item, _vm.feature)}}}),_c('v-btn',{staticClass:"d-inline-block",attrs:{"text":"","small":"","fab":""},on:{"click":function($event){expand(item, !isExpanded(item))}}},[_c('v-icon',[_vm._v(_vm._s(isExpanded(item) ? 'remove' : 'keyboard_arrow_down'))])],1)],1)]),(isExpanded(item))?_c('tr',{key:'storyExp'+item.id},[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"justify":"space-between"}},[(item.sprint)?_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(_vm._s(_vm.$t('object.sprint'))+": "),_c('v-chip',{attrs:{"x-small":"","label":""}},[_vm._v(_vm._s(item.sprint.name))])],1):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('story.priority'))+": "),_c('edit-dialog',{key:Math.random(),attrs:{"value":item,"field":"priority","label":_vm.$t('story.priority'),"type":"select","items":[
                                        {text: _vm.$t('story.low'), value: 'low'},
                                        {text: _vm.$t('story.medium'), value: 'medium'},
                                        {text: _vm.$t('story.high'), value: 'high'} ]},on:{"save":_vm.save}},[_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(_vm._s(_vm.$t('story.'+item.priority)))])],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('object.tags'))+": "),_c('edit-dialog',{key:Math.random(),attrs:{"value":item,"field":"tags","label":_vm.$t('object.tags'),"type":"select","items":_vm.fetchTags,"item-text":"name","item-value":"name","multiple":"","chips":"","deletable-chips":""},on:{"save":_vm.save}},[_vm._l((item.tags),function(tag){return _c('v-chip',{key:tag.id,staticClass:"mx-1 my-1",attrs:{"small":"","label":""}},[_vm._v(_vm._s(tag.name))])}),_c('span')],2)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('story.details'))+":")]),_c('edit-dialog',{key:Math.random(),attrs:{"value":item,"field":"details","label":_vm.$t('story.details'),"type":"wyswyg","size":"600"},on:{"save":_vm.save}},[_c('div',{class:'subtitle-1 '+( _vm.$vuetify.theme.dark ? 'white--text' : 'black--text' ),domProps:{"innerHTML":_vm._s(item.details)}})])],1)],1),_c('messages',{key:item.id,attrs:{"messageable_type":"story","messageable_id":item.id,"dark":""}})],1)]):_vm._e()]})],2)]}}]),model:{value:(_vm.selectedStories),callback:function ($$v) {_vm.selectedStories=$$v},expression:"selectedStories"}})}
var staticRenderFns = []

export { render, staticRenderFns }