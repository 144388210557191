<template>
    <v-edit-dialog 
        @save="save"
        :ref="customRef"
        :return-value.sync="data"
    >
        <!-- :dark="!$vuetify.theme.dark"
        :light="$vuetify.theme.dark" -->
        <slot></slot>

        <template v-slot:input>
            <div :style="{'min-width': size+'px'}" class="pa-2">
                <v-text-field
                    v-if="type == 'string'"
                    v-model="data"
                    :label="label"
                    hide-details
                    filled
                    :rules="required ? [rules.required] : []"
                ></v-text-field>

                <v-textarea
                    v-if="type == 'text'"
                    v-model="data"
                    filled
                    :label="label"
                    hide-details
                    auto-grow
                    :rules="required ? [rules.required] : []"
                ></v-textarea>

                <v-select
                    v-if="type == 'select'"
                    v-model="data"
                    :items="items"
                    :item-text="itemText"
                    :item-value="itemValue"
                    :label="label"
                    filled
                    hide-details
                    :rules="required ? [rules.required] : []"
                ></v-select>

                <v-combobox
                    v-if="type == 'combobox'"
                    v-model="data"
                    :label="label"
                    :items="items"
                    :item-text="itemText"
                    :item-value="itemValue"
                    :multiple="multiple"
                    :chips="chips"
                    hide-details
                    :deletable-chips="deletableChips"
                ></v-combobox>

                <wyswyg v-if="type == 'wyswyg'" v-model="data" :label="label"/>

                <div class="d-flex mt-2">
                    <v-btn text depressed small @click="cancel"><v-icon>clear</v-icon></v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text depressed small @click="save"><v-icon>save</v-icon></v-btn>
                </div>
            </div>
        </template>
    </v-edit-dialog>
</template>
<script>
export default {
    props: {
        type: {type: String, required: true},
        value: {required: true},
        field: {type: String, required: true},

        label: {type: String, required: true},

        items: {type: Array},
        required: {type: Boolean},
        itemText: {type: String, default: 'text'},
        itemValue: {type: String, default: 'value'},
        multiple: {type: Boolean},
        chips: {type: Boolean},
        deletableChips: {type: Boolean},
        size: {type: [String,Number], default: 300},
    },
    data () {
        return {
            rules: {
                required: value => !!value || 'Champ requis',
            },
            data: null,
            customRef: Date.now(),
        }
    },
    mounted () {
        this.data = this.value[this.field]
    },
    methods: {
        save() {
            this.value[this.field] = this.data
            this.$emit('save', this.value)
            this.$refs[this.customRef].cancel()
        },
        cancel() {
            this.$refs[this.customRef].cancel()
        }
    }
}
</script>