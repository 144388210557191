<template>
    <v-content class="profile">
        <v-container fluid>
            <v-row>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-else>
                    <UserDetails ref="UserDetails" v-model="profile" v-if="profile.id" color="secondary"/>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>
<script>
import UserDetails from '@/components/user/Details'

export default {
    components: {
        UserDetails,
    },
    data() {
        return {
            dialogEdit: false,
            profile: this.$store.state.auth.profile,
            loading: false,
        }
    },
    mounted() {
        // 
    },
    methods: {
        // 
    }
}
</script>
