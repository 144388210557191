<template>
    <div class="stories-kanban fill-height">
        <KanbanBoard ref="StoryKanban" :config="kanbanConfig" :stages="stages" :stagesFilter="stagesFilter" :blocks="blocks" :loading="loading" @update-block="moveBlock">
            <v-row no-gutters v-for="stage in stagesFilter" :slot="stage" :key="stage">
                <v-col cols="12">
                    <h2>{{ $t('story.'+stage) }}</h2>
                </v-col>
            </v-row>

            <v-card v-for="story in blocks" :slot="story.id" :key="story.id" @click="showStory(story)">
                <v-card-text :class="'px-1 py-1 '+($vuetify.theme.dark ? 'white--text' : 'black--text')">
                    <v-row no-gutters justify="space-between">
                        <v-col cols="auto">#{{ story.ref }}</v-col>
                        <v-col cols="auto" v-if="story.sprint">
                            <v-chip x-small label>{{ story.sprint.name }}</v-chip>
                        </v-col>
                        <v-col cols="auto">
                            <v-chip small label>{{ $t('story.'+story.priority) }}</v-chip>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <span class="grey--text text--darken-2">{{ $t('story.as') }}</span> {{ story.as.charAt(0).toLowerCase() + story.as.slice(1) }},
                            <span class="grey--text text--darken-2">{{ $t('story.be_able_to') }}</span> {{ story.be_able_to.charAt(0).toLowerCase() + story.be_able_to.slice(1) }},
                            <span class="grey--text text--darken-2">{{ $t('story.so_that') }}</span> {{ story.so_that.charAt(0).toLowerCase() + story.so_that.slice(1) }} 
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col>
                            <v-chip small label class="mx-1 my-1" v-for="tag in story.tags" :key="tag.id">{{ tag.name }}</v-chip>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="overline" justify="space-between">
                        <v-col cols="auto">{{ $t('story.estim') }}: {{ story.estimate ? story.estimate : '-' }}</v-col>
                        <v-spacer/>
                        <v-col cols="auto">{{ $t('story.updated_at', {at: $moment(story.updated_at).format('DD/MM/YYYY')}) }}</v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </KanbanBoard>

        <v-dialog v-model="dialogShowStory" max-width="80%">
            <v-card v-if="showedStory">
                <v-card-title class="primary white--text">
                    <RemoveButton 
                        color="primary"
                        small
                        depressed
                        @confirmed="deleteStory(showedStory.id)"
                    />
                    {{ $t('object.story') }} #{{ showedStory.ref }}
                    <v-spacer/>
                    <v-btn icon dark @click="editStory(showedStory)"><v-icon>edit</v-icon></v-btn>
                    <v-btn icon dark @click="dialogShowStory = false"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="py-0">
                    <v-row>
                        <v-col cols="12" sm="auto" v-if="showedStory.ref">
                            <v-chip small>ID: {{ showedStory.ref }}</v-chip>
                        </v-col>
                        <v-col cols="12" sm="auto" v-if="showedStory.sprint_name">
                            <v-chip small>{{ showedStory.sprint_name }}</v-chip>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="12" sm="auto">
                            <v-chip small>{{ $t('story.'+showedStory.priority) }}</v-chip>
                        </v-col>
                        <v-col cols="12" sm="auto">
                            <v-chip small>{{ $t('story.'+showedStory.status) }}</v-chip>
                        </v-col>
                    </v-row>

                    <v-row :class="'subtitle-1 '+( $vuetify.theme.dark ? 'white--text' : 'black--text' )">
                        <v-col>
                            <span class="grey--text text--darken-2">{{ $t('story.as') }}</span> {{ showedStory.as.charAt(0).toLowerCase() + showedStory.as.slice(1) }},
                            <span class="grey--text text--darken-2">{{ $t('story.be_able_to') }}</span> {{ showedStory.be_able_to.charAt(0).toLowerCase() + showedStory.be_able_to.slice(1) }},
                            <span class="grey--text text--darken-2">{{ $t('story.so_that') }}</span> {{ showedStory.so_that.charAt(0).toLowerCase() + showedStory.so_that.slice(1) }} 
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <span class="grey--text text--darken-2">{{ $t('story.details') }}:</span>
                            <div :class="'subtitle-1 '+( $vuetify.theme.dark ? 'white--text' : 'black--text' )" v-html="showedStory.details"></div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <span class="grey--text text--darken-2">{{ $t('object.tags') }}:</span>
                        </v-col>
                        <v-col>
                            <v-chip small class="mx-1 my-1" v-for="tag in showedStory.tags" :key="tag.id">{{ tag.name }}</v-chip>
                        </v-col>
                    </v-row>
                    
                    <v-row justify="space-between">
                        <v-col cols="auto">{{ $t('story.estimate') }}: {{ showedStory.estimate ? showedStory.estimate : '-' }}</v-col>
                        <v-spacer/>
                        <v-col cols="auto">
                            {{ $t('story.updated_at', {at: $moment(showedStory.updated_at).format('DD/MM/YYYY [à]  HH:mm')}) }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="px-0 py-0">
                    <v-row no-gutters>
                        <v-col>
                            <messages messageable_type="story" :messageable_id="showedStory.id" :key="Date.now()"/>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEditStory" max-width="80%" persistent scrollable>
            <v-card>
                <v-card-title class="primary white--text">
                    {{ $t('object.story') }}
                </v-card-title>
                <v-card-text>
                    <StoryForm ref="StoryForm" v-model="editedStory" :key="Date.now()" @savedForm="handleSavedStoryForm" @canceledForm="handleCanceledStoryForm"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import StoryForm from '@/components/story/Form'

export default{
    components: {
        // StoryCard,
        StoryForm,
    },
    props: {
        project_id: { 
            type: [String, Number], 
            required: false
        },
        filter: {
            type: Object,
            default: () => ({}),
        },
        stages: {
            type: Array,
            required: true,
        },
        stagesFilter: {
            type: Array,
        }
    },
    data(){
        return {
            ready: false,
            blocks: [],
            showedStory: null,
            dialogShowStory: false,
            editedStory: null,
            dialogEditStory: false,
            kanbanConfig: {
                revertOnSpill: true,
            },
            loading: false,
        }
    },
    watch: {
        filter: {
            handler() {
                this.fetchStories()
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
        this.ready = true
    },
    methods: {
        fetchStories() {
            this.loading = true
            let queries = {
                search: JSON.stringify({
                    project_id: this.project_id,
                    sprints: this.filter.sprints,
                    tags: this.filter.tags,
                }),
                per_page: -1,
                with: ['feature.capability.project', 'sprint'],
            }
            this.$store.dispatch('storiesRequest', queries)
                .then((result) => {
                    this.loading = false
                    this.blocks = result.data
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        showStory(story) {
            this.showedStory = story
            this.dialogShowStory = true
        },
        createStory() {
            this.editedStory = {project_id: this.project_id}
            this.dialogEditStory = true
        },
        editStory(story) {
            this.editedStory = story
            this.dialogEditStory = true
        },
        handleCanceledStoryForm() {
            this.editedStory = {}
            this.dialogEditStory = false
        },
        handleSavedStoryForm(story) {
            this.$emit('savedStoryForm', story);
            this.dialogEditStory = false
            this.fetchStories()
            this.showStory(story)
        },
        deleteStory(story_id) {
            return this.$store.dispatch('storyDeleteRequest', { id: story_id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.deleted')});
                this.dialogShowStory = false
                this.fetchStories()
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        moveBlock(id, status) {
            let story = this.blocks.find(b => b.id === Number(id))

            // update status:
            if (story.status != status) {
                story.status = status
                let queries = {id: story.id, datas: story}

                this.$store.dispatch('storyEditRequest', queries)
                    .then((story) => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.saved')});
                        this.$emit('savedForm', story);
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }

            // update position:
            let ul = this.$refs.StoryKanban.$el.querySelector('[data-status='+status+']')
            let newPos = Array.from(ul.children).map(li => li.getAttribute('data-block-id'))
            let queries = {object: 'stories', datas: {positioning: newPos}}

            this.$store.dispatch('updatePositioningRequest', queries)
                .then((project) => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                })
        },
    }
}
</script>