<template>
    <v-expansion-panels :dark="dark" :value="expanded ? 0 : -1">
        <v-expansion-panel>
            <v-expansion-panel-header :class="headerClass" @click="getMessagesFromApi" expand-icon="chat">{{ $t('message.thread') }}</v-expansion-panel-header>
            <v-expansion-panel-content :class="contentClass">
                <div>
                    <v-timeline dense>
                        <v-timeline-item>
                            <template v-slot:icon>
                                <v-avatar color="white">
                                    <span class="black--text">{{ $store.state.auth.profile.name.split(" ").map((n)=>n[0]).join("") }}</span>
                                </v-avatar>
                            </template>
                                <v-card light>
                                    <v-card-text class="pt-0">
                                        <v-form ref="message-form">
                                            <v-row no-gutters justify="end">
                                                <v-col cols="auto">
                                                    <v-checkbox 
                                                        v-model="newMessage.is_internal" 
                                                        :label="$t('message.is_internal')"
                                                        hide-details
                                                        dense
                                                        class="ma-0"
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                            <wyswyg v-model="newMessage.content" ref="wyswyg" :placeholder="$t('message.new')"/>
                                            <v-row no-gutters>
                                                <v-col><btn-upload v-model="newMessage.attachments" multiple :key="Date.now()"></btn-upload></v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col>
                                                    <v-btn
                                                        block
                                                        depressed
                                                        color="primary"
                                                        @click="postDataToApi"
                                                        :loading="sendLoading"
                                                        :disabled="! newMessage.content.length && ! newMessage.attachments.length"
                                                    >{{ $t('message.ok') }}</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                </v-card>
                        </v-timeline-item>
                    </v-timeline>
                </div>
                <div class="text-center" v-if="loading">
                    <v-progress-circular
                        indeterminate
                        color="white"
                    ></v-progress-circular>
                </div>
                <div v-else>
                    <v-timeline dense>
                        <v-timeline-item v-for="message in this.messages" :key="message.id">
                            <template v-slot:icon>
                                <v-avatar color="white">
                                    <span class="black--text">{{ message.user ? message.user.name.split(" ").map((n)=>n[0]).join("") : 'N' }}</span>
                                </v-avatar>
                            </template>
                            <Message :message="message" color="dark"/>
                        </v-timeline-item>
                    </v-timeline>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script>
import Message from '@/components/Message'
export default {
    components: {
        Message,
    },
    props: {
        messageable_type: {type: String, required: true},
        messageable_id: {type: [String, Number], required: true},
        expanded: {type: Boolean, default: false},
        dark: {type: Boolean, default: true},
        headerClass: {type: String, default: ''},
        contentClass: {type: String, default: ''},
    },
    data () {
        return {
            newMessage: {is_internal: true, content: '', attachments: []},
            messages : [],
            ready: false,
            loading: false,
            sendLoading: false,
        }
    },
    mounted () {
        this.getMessagesFromApi()
    },
    methods: {
        getMessagesFromApi() {
            this.loading = true
            let queries = { 
                messageable_type: this.$props.messageable_type, 
                messageable_id: this.$props.messageable_id 
            }
            this.$store.dispatch('messagesRequest', queries)
                .then(data => {
                    this.messages = data.data
                    this.loading = false
                    this.ready = true
                }).catch(error => {
                    this.loading = false
                })
        },
        postDataToApi() {
            this.sendLoading = true
            let queries = {
                messageable_id: this.messageable_id,
                messageable_type: this.messageable_type,
                ...this.newMessage,
            }
            return this.$store.dispatch('messageCreateRequest', queries)
                .then(() => {
                    this.sendLoading = false
                    this.getMessagesFromApi()
                    this.newMessage = {is_internal: true, content: '', attachments: []}
                    this.$refs.wyswyg.initEditor('')
                }).catch(error => {
                    this.sendLoading = false
                })
        },
    }
}
</script>