const translations = {

    add: 'ajouter',
    add_file: 'ajouter un fichier',
    cancel: 'annuler',
    confirm: 'confirmer',
    confirm_text: 'merci de confirmer la suppression',
    create: 'créer',
    loadmore: 'voir plus',
    new: 'nouveau',
    no_datas: 'aucune donnée',
    pagination: 'page {current} sur {last}',
    items_per_page: 'par page',
    remove: 'supprimer',
    save: 'sauvegarder',
    search: 'rechercher',
    sort_by: 'trier par',
    update: 'modifier',
    back: 'retour',
    print: 'imprimer',
    printParams: 'configuration d\'impression',
    yes: 'oui',
    no: 'non',
    of: 'sur',
    pricing: 'coût',
    show_mailthread: 'voir fil de discussion', 
    import: 'importer',

    object: {
        capabilities: 'capabilities',
        capability: 'capability',
        default_lexicon: 'lexique générale',
        default_lexicons: 'lexiques générales',
        feature: 'feature',
        features: 'features',
        issue: 'incident',
        issues: 'incidents',
        lexicon: 'lexique',
        lexicons: 'lexiques',
        partner: 'client',
        partners: 'clients',
        project: 'projet',
        projects: 'projets',
        sprint: 'sprint',
        sprints: 'sprints',
        stories: 'stories',
        story: 'story',
        user: 'utilisateur',
        users: 'utilisateurs',
        tag: 'mot-clé',
        tags: 'mots-clés',
        timesheet: 'feuille de temps',
        timesheets: 'feuilles de temps',
        todo: 'todo',
        todos: 'todos',
        event: 'évènement',
        events: 'évènements',
        ethercalc: 'tableur',
        ethercalcs: 'tableurs',
    },

    project: {
        icon: 'developer_board',
        access_and_notifications: 'accés et notifications',
        daily_price: 'tarif jour',
        deleted: 'projet supprimé !',
        description: 'description',
        estimate: 'estimation',
        short_estimate: 'estim.',
        fold: 'replier',
        fold_all: 'tout replier',
        informations: 'informations',
        name: 'nom',
        notifications: 'notifications',
        product_backlog: 'backlog produit',
        product_card: 'carte produit',
        product_definition: 'définition produit',
        product_manager: 'produit manager',
        service_manager: 'service manager',
        product_usertype: 'type d\'utilisateur',
        product_usertypes: 'types d\'utilisateur',
        project_manager: 'chef de projet',
        requirements: 'pré-requis',
        saved: 'projet sauvegardé !',
        scheme: 'schema',
        show_stories: 'voir stories',
        sp_rate: "taux d'sp",
        sp_type: "type d'sp",
        sp_value: "valeur d'un sp",
        status: 'statut',
        technical_env: 'environnement technique',
        technical_manager: 'directeur technique',
        technical_needs: 'contraint / besoins',
        technical_scheme: 'schema technique',
        unfold: 'déplier',
        unfold_all: 'tout déplier',
        day: 'jour',
        hour: 'heure',
        draft: 'brouillon',
        commercial: 'commercial',
        design: 'design',
        conception: 'conception',
        maintenance: 'maintenance',
        done: 'terminé',
        cancel: 'annulé',
        show_sprintzero: 'sprint 0',
        show_synthesis: 'synthèse',
    },

    capability: {
        deleted: 'capability supprimée !',
        description: 'description',
        estimate: 'estimation',
        short_estimate: 'estim.',
        name: 'nom',
        profits: 'profits',
        ref: 'ref',
        saved: 'capability sauvegardée !',
    },

    feature: {
        deleted: 'feature supprimée !',
        description: 'description',
        estimate: 'pré estimatation',
        short_estimate: 'pré estim.',
        name: 'nom',
        priority: 'priorité',
        ref: 'ref',
        saved: 'feature sauvegardée !',
        status: 'statut',
        stories_estimate: 'estimation',
        short_stories_estimate: 'estim.',
        low: 'faible',
        medium: 'moyenne',
        high: 'haute',
        draft: 'brouillon',
        backlog: 'backlog',
        planified: 'planifié',
        open: 'en cours',
        cancel: 'annulé',
        done: 'terminé',
        waiting: 'en attente',
        comment: 'commentaire',
    },

    issue: {
        icon: 'bug_report',
        'in-progress': 'en cours',
        'to-test': 'à tester',
        blocking: 'bloquant',
        canceled: 'annulé',
        closed: 'fermé',
        created: 'créé par {by} le {at}',
        created_at: 'créé le {at}',
        deleted: 'incident supprimé !',
        description: 'description',
        major: 'majeur',
        minor: 'mineur',
        new: 'nouveau',
        priority: 'criticité',
        saved: 'incident sauvegardé !',
        title: 'titre',
        waiting: 'en attente',
        status: 'statut',
    },

    lexicon: {
        deleted: 'lexique supprimé !',
        description: 'description',
        saved: 'lexique sauvegardé !',
        title: 'titre',
    },

    story: {
        icon: 'extension',
        as: 'en tant que',
        be_able_to: 'je veux pouvoir',
        deleted: 'story supprimée !',
        imported: 'stories importées !',
        details: 'détails',
        estimate: 'estimation',
        short_estimate: 'estim.',
        estim: 'estim.',
        priority: 'priorité',
        saved: 'story sauvegardée !',
        so_that: 'afin de',
        status: 'statut',
        ref: 'ref',
        low: 'faible',
        medium: 'moyenne',
        high: 'haute',
        draft: 'brouillon',
        backlog: 'backlog',
        planified: 'planifié',
        open: 'en cours',
        cancel: 'annulé',
        done: 'terminé',
        waiting: 'en attente',
        null: 'ERROR',
        created_at: 'créée le {at}',
        updated_at: 'modifiée le {at}',
        all: 'toutes les stories',
        no_sprint: 'sans sprint',
    },

    message: {
        is_internal: 'interne',
        new: 'saisir votre message ici',
        ok: 'envoyer',
        thread: 'fil de discussion',
        saved: 'message sauvegardé !',
        deleted: 'message supprimé !',
    },

    navigation: {
        configuration: 'paramètres',
        logout: 'déconnecter',
        profile: 'mon profil',
    },

    partner: {
        icon: 'face',
        city: 'ville',
        country: 'pays',
        deleted: 'client supprimé !',
        email: 'email',
        name: 'nom',
        phone: 'téléphone',
        saved: 'client sauvegardé !',
        street2: 'adresse comp.',
        street: 'adresse',
        zip: 'code postal',
    },

    filemanager: {
        icon: 'file_copy',
        ged: 'ged',
    },

    user: {
        icon: 'directions_run',
        deleted: 'utilisateur supprimé !',
        email: 'email',
        name: 'nom',
        old_password: 'ancien mot de passe',
        password: 'mot de passe',
        password_confirmation: 'confirmation du mot de passe',
        saved: 'utilisateur sauvegardé !',
        type: 'type',
        project: 'projet',
        crm: 'crm',
        portal: 'portail',
        color: 'couleur',
        internal: 'interne',
        external: 'externe',
    },

    productUsertype: {
        deleted: 'type d\'utilisateur supprimé !',
        goals: 'objectifs',
        motivation: 'motivation',
        name: 'nom',
        pain_points: 'pain points',
        saved: 'type d\'utilisateur sauvegardé !',
    },

    productVision: {
        deleted: 'vision produit supprimée !',
        description: 'vision produit',
        goals: 'objectifs',
        needs: 'besoins',
        product: 'produit',
        saved: 'vision produit sauvegardée !',
        target: 'cibles',
    },

    sprint: {
        name: 'nom',
        deleted: 'sprint supprimé !',
        description: 'description',
        saved: 'sprint sauvegardé !',
        status: 'statut',
        draft: 'brouillon',
        planified: 'planifié',
        open: 'en cours',
        done: 'terminé',
        waiting: 'en attente',
        estimate: 'estimation',
        short_estimate: 'estim.',
    },

    timesheet: {
        icon: 'timer',
        date: 'date',
        timed: 'action',
        description: 'description',
        time: 'temps',
        saved: 'feuille de temps sauvegardée !',
        deleted: 'feuille de temps supprimée !',
    },

    todo: {
        icon: 'today',
        saved: 'todo sauvegardée !',
        deleted: 'todo supprimée !',
        description: 'description',
        created: 'créé par {by} le {at}',
        created_at: 'créé le {at}',
        user: 'assigné à',
        title: 'titre',
        due_at: 'échéance',
        close: 'terminer',
        done: 'terminé',
    },

    event: {
        icon: 'today',
        saved: 'évènement sauvegardée !',
        deleted: 'évènement supprimée !',
        description: 'description',
        created: 'créé par {by} le {at}',
        created_at: 'créé le {at}',
        title: 'titre',
        place: 'lieu',
        users: 'participants',
        start: 'début',
        end: 'fin',
        start_day: 'début (date)',
        start_time: 'début (heure)',
        end_day: 'fin (date)',
        end_time: 'fin (heure)',
        attendees: 'invités',
        attendee: {
            status: 'status',
            accepted: 'accepté',
            waiting: '',
            declined: 'refusé',
        },
        resend: 'renvoyer les invitations',
    },

    calendar: {
        icon: 'today',
        title: 'agenda',
        month: 'mois',
        week: 'semaine',
        day: 'jour',
        days: {
            0: 'dim',
            1: 'lun',
            2: 'mar',
            3: 'mer',
            4: 'jeu',
            5: 'ven',
            6: 'sam',
        },
        today: "aujourd'hui",
        le: 'le',
        à: 'à',
        de: 'de',
        du: 'du',
        au: 'au',
        with: 'avec',
        my: 'moi',
        all: 'tout le monde',
    },

    positioning: {
        saved: 'position sauvegardé !',
    },

    duplication: {
        new: 'nouvelle duplication',
        relationships: 'sous-objets à dupliquer',
        reset_relationships_fields: 'champs des sous-objets à réinitialiser',
    },

    rules: {
        required: 'requis',
        minlength: '8 caractères min.',
        picture: 'fichier de type image (.jpg, .png) requis.',
    },

    login: {
        email: 'email',
        forgot_password: 'mot de passe oublié ?',
        login: 'Connexion',
        ok: 'ok',
        password: 'mot de passe',
    },

    sort: {
        alphabetical: 'ordre alphabétique',
    },

    password_edit: {
        email: 'email',
        login: 'se connecter',
        ok: 'sauvegarder',
        password: 'mot de passe',
        password_confirmation: 'confirmation du mot de passe',
        password_edit: 'modifier le mot de passe',
    },

    password_reset: {
        cancel: 'annuler',
        email: 'email',
        ok: 'envoyer',
        password_reset: 'réinitialisation du mot de passe',
    },

    auth: {
        invalid_credentials: 'Identifiants incorrects',
        disconnected: 'Vous avez été déconnecté',
        password_send: 'Demande de réinitialisation envoyée',
        password_reset: 'Mot de passe modifié',
    },

    ethercalc: {
        icon: 'table_view',
        title: 'titre',
        deleted: 'tableur supprimé !',
        saved: 'tableur sauvegardé !',
        forbidden_char: 'caractères interdits',
    }
}

export default translations