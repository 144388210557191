<template>
    <v-content class="filemanager">
        <v-row class="fill-height">
            <v-col>
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.0/css/all.css">
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css">
                <file-manager :settings="settings" :class="$vuetify.theme.dark ? 'black' : ''"></file-manager>
            </v-col>
        </v-row>
    </v-content>
</template>
<style type="text/css">
    .fm .sticky-top {position: initial !important;}
    .fm-table thead th {position: initial !important;}
    .fm-table thead th, 
    .fm-tree-disk, 
    .fm-breadcrumb .breadcrumb.active-manager {
        background: transparent !important;
    }
    .fm-tree-branch li > p:hover, .fm-tree-branch li > p.selected,
    .fm .table-info, .table-info>td, .table-info>th,
    .fm-table tr:hover {
        background-color: grey !important;
    }
    .fm-modal,
    .fm-context-menu {
        color: black ! important;
    }
</style>
<script>
export default {
    data() {
        return {
            settings: {
                headers: {
                    Authorization: "Bearer" + " " + this.$store.state.auth.profile.access_token,
                },
                baseUrl: process.env.VUE_APP_API_URL+'/file-manager/',
                lang: this.$i18n.locale,
            },
        }
    },
    mounted() {
        // 
    },
    methods: {}
}
</script>
