<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form">
        <v-row>
            <v-col>
                <v-autocomplete
                    v-model="todo.project_id"
                    :items="allProjects"
                    :label="$t('object.project')"
                    :item-text="p => p.complete_name"
                    item-value="id"
                    filled
                    :disabled="loading" :loading="loading"
                    clearable
                    hide-details
                    class="mb-3"
                ></v-autocomplete>

                <v-autocomplete
                    v-model="todo.user_id"
                    :items="allUsers"
                    :label="$t('todo.user')"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                    filled
                    :disabled="loading" :loading="loading"
                    hide-details
                    class="mb-3"
                ></v-autocomplete>

                <v-text-field
                    v-model="todo.due_at"
                    filled
                    :label="$t('todo.due_at')"
                    type="date"
                    hide-details
                    class="mb-3"
                ></v-text-field>

                <v-text-field
                    v-model="todo.title"
                    filled
                    :label="$t('todo.title')"
                    :rules="[rules.required]"
                    hide-details
                    class="mb-3"
                ></v-text-field>

                <v-textarea
                    v-model="todo.description"
                    filled
                    auto-grow
                    :label="$t('todo.description')"
                    hide-details
                    class="mb-3"
                ></v-textarea>

            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary">{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">{{ $t('save') }}</v-btn>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
            },
            todo: Object.assign({}, this.value),
            allProjects: [],
            allUsers: [],
        }
    },
    computed: {
        // 
    },
    mounted() {
        this.fetchProjects()
        this.fetchUsers()
    },
    methods: {
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                let request = 'todoCreateRequest'
                let queries = this.todo

                if (this.todo.id) {
                    request = 'todoEditRequest'
                    queries = {id: this.todo.id, datas: this.todo}
                }

                this.$store.dispatch(request, queries)
                    .then((todo) => {
                        this.loading = false
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('todo.saved')});
                        this.$emit('savedForm', todo);
                    })
                    .catch((err) => {
                        this.loading = false
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
        fetchProjects() {
            this.loading = true
            this.$store.dispatch('projectsRequest', {per_page: -1, with: []})
                .then((result) => {
                    this.allProjects = result.data
                    this.loading = false
                })
                .catch((err) => {
                })
        },
        fetchUsers() {
            let queries = {search: JSON.stringify({type: 'project'}), per_page: -1}
            this.$store.dispatch('usersRequest', queries)
                .then((result) => {
                    this.allUsers = result.data
                })
                .catch((err) => {
                })
        },
    }
}
</script>
