<template>
    <v-content class="user">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('user.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.users'), to: {name: 'Users'}},
                        {text: user.name},
                    ]"/>
                    <v-spacer/>
                    <v-col cols="auto">
                        <v-btn
                            dark
                            :to="{name: 'EditUser', params: {id: user.id}}"
                            class="mr-2"
                            fab
                        >
                            <v-icon>edit</v-icon>
                        </v-btn>
                        <RemoveButton 
                            @confirmed="deleteToApi" 
                            color="red darken-1"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <UserDetails ref="UserDetails" v-model="user" v-if="user.id" color="secondary"/>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <messages messageable_type="user" :messageable_id="user.id"/>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
import UserDetails from '@/components/user/Details'
export default {
    components: {
        UserDetails,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data () {
        return {
            ready: false,
            user: {},
            loading: false,
        }
    },
    mounted () {
        this.getUser()
    },
    methods: {
        getUser() {
            this.loading = true
            this.$store.dispatch('userRequest', {id: this.id})
                .then(result => {
                    this.user = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        deleteToApi() {
            return this.$store.dispatch('userDeleteRequest', { id: this.user.id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('user.deleted')});
                this.$router.push({name: 'Users'})
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
    }
}
</script>