var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',{staticClass:"projects"},[_c('v-card',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-card-text',{staticClass:"py-4"},[_c('v-row',{staticClass:"headline mb-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{staticClass:"mr-5",attrs:{"large":""}},[_vm._v(_vm._s(_vm.$t('project.icon')))])],1),_c('v-col',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$t('object.projects')))]),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{attrs:{"block":"","color":"primary","to":{name: 'CreateProject'}}},[_vm._v(" "+_vm._s(_vm.$t('create'))+" ")])],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center","align":"center"}},[_c('v-col',{staticStyle:{"min-width":"200px"}},[_c('v-text-field',{attrs:{"clearable":"","flat":"","solo":"","hide-details":"","prepend-inner-icon":"search","label":_vm.$t('search')},model:{value:(_vm.search.text),callback:function ($$v) {_vm.$set(_vm.search, "text", $$v)},expression:"search.text"}})],1)],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projects,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.partners.name",fn:function(ref){
var item = ref.item;
return [(item.partner)?[_vm._v(" "+_vm._s(item.partner.name)+" ")]:_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"title font-weight-bold"},[_vm._v(_vm._s(item.name))])]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody","height":"400px","width":"100%"}})]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t('project.'+item.status)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ProjectMenu',{attrs:{"small":""},model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}})]}},{key:"no-data",fn:function(){return [_c('no-data')]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }