var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":6}},[_c('v-autocomplete',{attrs:{"items":_vm.allFeatures,"label":_vm.$t('object.feature'),"item-text":function (f) { return f.capability.project.complete_name+' / #'+f.capability.ref+' / '+f.name; },"item-value":"id","rules":[_vm.rules.required],"filled":"","autofocus":""},model:{value:(_vm.story.feature_id),callback:function ($$v) {_vm.$set(_vm.story, "feature_id", $$v)},expression:"story.feature_id"}}),_c('v-text-field',{attrs:{"filled":"","label":_vm.$t('story.ref')},model:{value:(_vm.story.ref),callback:function ($$v) {_vm.$set(_vm.story, "ref", $$v)},expression:"story.ref"}}),_c('v-text-field',{attrs:{"filled":"","label":_vm.$t('story.as'),"rules":[_vm.rules.required]},model:{value:(_vm.story.as),callback:function ($$v) {_vm.$set(_vm.story, "as", $$v)},expression:"story.as"}}),_c('v-textarea',{attrs:{"filled":"","auto-grow":"","label":_vm.$t('story.be_able_to')},model:{value:(_vm.story.be_able_to),callback:function ($$v) {_vm.$set(_vm.story, "be_able_to", $$v)},expression:"story.be_able_to"}}),_c('v-textarea',{attrs:{"filled":"","auto-grow":"","label":_vm.$t('story.so_that')},model:{value:(_vm.story.so_that),callback:function ($$v) {_vm.$set(_vm.story, "so_that", $$v)},expression:"story.so_that"}}),_c('wyswyg',{attrs:{"label":_vm.$t('story.details')},model:{value:(_vm.story.details),callback:function ($$v) {_vm.$set(_vm.story, "details", $$v)},expression:"story.details"}})],1),_c('v-col',{attrs:{"cols":"12","md":6}},[_c('v-select',{attrs:{"items":[
                    {text: _vm.$t('story.low'), value: 'low'},
                    {text: _vm.$t('story.medium'), value: 'medium'},
                    {text: _vm.$t('story.high'), value: 'high'} ],"label":_vm.$t('story.priority'),"filled":"","rules":[_vm.rules.required]},model:{value:(_vm.story.priority),callback:function ($$v) {_vm.$set(_vm.story, "priority", $$v)},expression:"story.priority"}}),_c('v-select',{attrs:{"items":[
                    {text: _vm.$t('story.draft'), value: 'draft'},
                    {text: _vm.$t('story.backlog'), value: 'backlog'},
                    {text: _vm.$t('story.planified'), value: 'planified'},
                    {text: _vm.$t('story.open'), value: 'open'},
                    {text: _vm.$t('story.cancel'), value: 'cancel'},
                    {text: _vm.$t('story.done'), value: 'done'},
                    {text: _vm.$t('story.waiting'), value: 'waiting'} ],"label":_vm.$t('story.status'),"filled":"","rules":[_vm.rules.required]},model:{value:(_vm.story.status),callback:function ($$v) {_vm.$set(_vm.story, "status", $$v)},expression:"story.status"}}),_c('v-text-field',{attrs:{"filled":"","label":_vm.$t('story.estimate')},model:{value:(_vm.story.estimate),callback:function ($$v) {_vm.$set(_vm.story, "estimate", $$v)},expression:"story.estimate"}}),_c('v-autocomplete',{attrs:{"items":_vm.allSprints,"label":_vm.$t('object.sprint'),"item-text":"name","item-value":"id","clearable":"","filled":""},model:{value:(_vm.story.sprint_id),callback:function ($$v) {_vm.$set(_vm.story, "sprint_id", $$v)},expression:"story.sprint_id"}}),_c('v-combobox',{attrs:{"items":_vm.allTags,"label":_vm.$t('object.tags'),"item-text":"name","item-value":"name","multiple":"","chips":"","deletable-chips":""},model:{value:(_vm.story.tags),callback:function ($$v) {_vm.$set(_vm.story, "tags", $$v)},expression:"story.tags"}})],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.cancel($event)}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":_vm.loading,"loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t('save')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }