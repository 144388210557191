<style scoped>
    input[type=file] {
        position: absolute;
        left: -99999px;
    }
</style>
<template>
    <div>
        <v-text-field prepend-icon="attach_file" single-line
                      :value=" this.value ? this.value.map(file=>file.name).join(', ') : null "
                      :label="$t('add_file')" :required="required"
                      @click.native="onFocus"
                      :disabled="disabled" ref="fileTextField"></v-text-field>
        <input type="file" :accept="accept" :multiple="multiple" :disabled="disabled"
               ref="fileInput" @change="onFileChange">
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: [Array, String]
        },
        accept: {
            type: String,
            default: "*"
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        returnMethod: {
            type: String,
            default: 'json'
        }
    },
    data(){
        return {
            filename: "",
            files: []
        };
    },

    methods: {
        onFocus(){
            if (!this.disabled) {
                this.$refs.fileInput.click();
            }
        },
        async onFileChange($event){
            let files = $event.target.files || $event.dataTransfer.files;
            files = [...files].map(file => file)
            
            if (files) {
                if (files.length > 0) {
                    this.filename = [...files].map(file => file.name).join(', ');
                } else {
                    this.filename = null;
                }
            } else {
                this.filename = $event.target.value.split('\\').pop();
            }

            if( this.returnMethod == 'json' ){
                for (let i = 0; i < files.length; i++){
                    const file = files[i]
                    let fileJson = {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        binary: await this.readFileAsync(files[i])
                    }
                    this.files.push(fileJson)
                }
            }else if( this.returnMethod == 'file' ){
                this.files = files
            }

            if(this.multiple){
                this.$emit('input', this.files)
            }else{
                this.$emit('input', this.files.slice(-1))
            }
        },
        readFileAsync(file){
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = () => {
                    resolve(reader.result)
                }
                reader.readAsDataURL(file)
            })
        }
    }
}
</script>