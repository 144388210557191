<template>
    <v-content class="issues">
        <v-card color="primary" dark flat>
            <v-card-text class="py-4">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon x-large class="mr-5">{{ $t('issue.icon') }}</v-icon></v-col>
                    <v-col class="white--text text-no-wrap">{{ $t('object.issues') }}</v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid :style="{'height': 'calc(100% - 72px)'}">
            <v-row class="fill-height">
                <v-col cols="12">
                    <IssueKanban :stages="stages" :stagesFilter="showStages"/>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>
<script>
import IssueKanban from '@/components/issue/Kanban'
export default {
    components: {
        IssueKanban,
    },
    data () {
        return {
            stages: ['new', 'waiting', 'in-progress', 'to-test', 'closed', 'canceled'],
            showStages: ['new', 'waiting', 'in-progress', 'to-test', 'closed'],
        }
    },
    mounted () {
        // 
    },
    computed: {
        // 
    },
    methods: {
        // 
    }
}
</script>
