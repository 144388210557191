import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth'
import snackbar from './modules/snackbar'
import password from './modules/password'
import user from './modules/user'
import project from './modules/project'
import navigation from './modules/navigation'
import message from './modules/message'
import capability from './modules/capability'
import feature from './modules/feature'
import story from './modules/story'
import mass_update from './modules/mass_update'
import sprint from './modules/sprint'
import partner from './modules/partner'
import lexicon from './modules/lexicon'
import default_lexicon from './modules/default_lexicon'
import product_vision from './modules/product_vision'
import product_usertype from './modules/product_usertype'
import issue from './modules/issue'
import tag from './modules/tag'
import timesheet from './modules/timesheet'
import importable from './modules/importable'
import todo from './modules/todo'
import event from './modules/event'
import ethercalc from './modules/ethercalc'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        snackbar,
        password,
        user,
        project,
        navigation,
        message,
        capability,
        feature,
        story,
        mass_update,
        sprint,
        partner,
        lexicon,
        default_lexicon,
        product_vision,
        product_usertype,
        issue,
        tag,
        timesheet,
        importable,
        todo,
        event,
        ethercalc,
    }
});