<template>
    <v-content class="product-backlog">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('project.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.projects'), to: {name: 'Projects'}},
                        {text: project.complete_name, to: {name: 'Project', params: {id: project.id}}},
                        {text: $t('project.product_backlog')},
                    ]"/>
                    <v-spacer/>
                    <v-col cols="auto">
                        <ProjectMenu v-model="project" small/>
                        -
                        <v-btn
                            @click="dialogPrintBacklog = true"
                            class="mx-2"
                            text
                            fab
                            small
                        >
                            <v-icon>print</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row dense>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-if="readyProject">
                    <v-card>
                        <v-card-title>
                            {{ $t('project.product_backlog') }}
                            <v-spacer/>
                            <v-chip class="mr-5" dark>{{ estimate }} sp</v-chip>
                        </v-card-title>
                        <v-card-text>
                            <v-row align="end">
                                <v-col cols="12" :md="2">{{ $t('object.capabilities') }} <small>({{ capabilitiesCount }})</small></v-col>
                                <v-col cols="12" :md="10">
                                    <v-row no-gutters align="end">
                                        <v-col cols="12" :md="2">{{ $t('object.features') }} <small>({{ featuresCount }})</small></v-col>
                                        <v-col cols="12" :md="10">
                                            <!-- <v-select
                                                v-model="filterStoriesStatus"
                                                :items="storyStatus"
                                                :label="$t('story.status')"
                                                filled
                                                hide-details
                                                multiple
                                                class="mb-3"
                                            ></v-select> -->
                                            {{ $t('object.stories') }} <small>({{ storiesCount }})</small>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <!-- :filters="{stories: {status: filterStoriesStatus}}" -->
                            <CapabilityIndexV2 v-model="project.capabilities" @selectedStories="changeSelectedStories"/>

                            <v-row no-gutters v-if="selectedStories.length" style="position: sticky; bottom: 0px;" class="white">
                                <v-col cols="12"><hr/></v-col>
                                <v-col cols="12" :md="2"></v-col>
                                <v-col cols="12" :md="10">
                                    <v-row no-gutters>
                                        <v-col cols="12" :md="2"></v-col>
                                        <v-col cols="12" :md="10" class="d-flex align-center">
                                            <v-select
                                                :items="storyStatus"
                                                :label="$t('story.status')"
                                                filled
                                                hide-details
                                                v-model="selectedStoriesStatus"
                                            ></v-select>
                                            <v-btn fab text @click="saveSelectedStories"><v-icon>save</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-btn
                                @click="dialogAddCapability = true"
                                depressed
                                block
                                x-large
                                outlined
                                class="my-5"
                            >
                                <v-icon>add</v-icon>
                                {{ $t('add') }} {{ $t('object.capability') }}
                            </v-btn>

                            <div class="text-center mt-4">
                                <v-btn 
                                    depressed
                                    dark
                                    @click="dialogImportStories = true"
                                >
                                    {{ $t('import') }} {{ $t('object.stories') }}
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <Lexicons v-if="readyProject" :project_id="project.id"/>
                </v-col>
                <v-col cols="12">
                    <messages v-if="readyProject" messageable_type="project" :messageable_id="project.id"/>
                </v-col>
            </v-row>

            <v-dialog v-model="dialogAddCapability" max-width="80%" persistent scrollable>
                <v-card>
                    <v-card-title class="secondary white--text">
                        {{ $t('object.capability') }}
                    </v-card-title>
                    <v-card-text>
                        <CapabilityForm :value="{project_id: project.id}" :key="Date.now()" @savedForm="handleAddedCapability" @canceledForm="dialogAddCapability = false"/>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogPrintBacklog" max-width="80%" persistent scrollable>
                <v-card>
                    <v-card-title class="secondary white--text">
                        {{ $t('printParams') }}
                    </v-card-title>
                    <v-card-text>
                        <v-form v-on:submit.prevent="printBacklog">
                            <v-switch v-model="printParams.showEstimate" 
                                :label="`${$t('project.estimate')}: ${printParams.showEstimate ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>
                            <v-switch v-model="printParams.showPricing" 
                                :label="`${$t('pricing')}: ${printParams.showPricing ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>
                            <v-switch v-model="printParams.showStories" 
                                :label="`${$t('object.stories')}: ${printParams.showStories ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>
                            <v-select
                                v-model="printParams.statusStories"
                                v-if="printParams.showStories"
                                :items="storyStatus"
                                :label="$t('story.status')"
                                filled
                                multiple
                            ></v-select>
                            <v-switch v-model="printParams.showSprintZero" 
                                :label="`${$t('project.show_sprintzero')}: ${printParams.showSprintZero ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>
                            <v-switch v-model="printParams.showSynthesis" 
                                :label="`${$t('project.show_synthesis')}: ${printParams.showSynthesis ? $t('yes') : $t('no')}`"
                                :true-value="parseInt(1)"
                                :false-value="parseInt(0)"
                            ></v-switch>

                            <v-row justify="center" class="mt-5">
                                <v-btn @click.stop="dialogPrintBacklog = false" text color="secondary" x-large>{{ $t('cancel') }}</v-btn>
                                <v-btn color="primary" type="submit" :loading="loadingBacklogPrint" x-large>{{ $t('print') }}</v-btn>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogImportStories" max-width="900px" persistent scrollable>
                <v-card>
                    <v-card-text>
                        <v-form v-on:submit.prevent="importStories" ref="form">
                            <v-row>
                                <v-col>
                                    <v-file-input 
                                        v-model="imported.file" 
                                        label="Fichier"
                                        outlined
                                        :rules="[value => !!value || 'Champ requis']"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row v-if="importedHelp">
                                <v-col>
                                    <p>Format du fichier:</p>
                                    <code>feature_ref,ref,as,estimate,be_able_to,details,so_that
                                        <br> "FEAT1";"25";"utilisateur";;"je peux importer des 'stories', mais pas de 'features'";;"gagner du temps"
                                        <br> "FEAT2";"36";"système";"0.5";"j'affiche un message en cas d'erreur";"champs : 
                                        <br>- numéro de ligne
                                        <br>- champ en erreur";"ne pas faire d'erreur"
                                        <br> "FEAT1";"26";"utilisateur";;"je peux cliquer sur un bouton Annuler";;"d'annuler l'import"
                                        <br>...
                                    </code>
                                    <p>Les champs non requis sont: <code>estimate</code>, <code>details</code>, <code>priority</code>, <code>status</code></p>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="importedHelp = ! importedHelp" depressed color="secondary">
                            Aide
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialogImportStories = false" depressed color="secondary">
                            Annuler
                        </v-btn>
                        <v-btn @click="importStories" depressed color="primary">
                            Importer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-content>
</template>

<script>
import Lexicons from '@/components/Lexicons'
import CapabilityForm from '@/components/capability/Form'
import CapabilityIndexV2 from '@/components/capability/IndexV2'
import ProjectMenu from '@/components/project/Menu'
import i18n from '@/plugins/i18n/i18n'
const storyStatus = [
    {text: i18n.t('story.draft'), value: 'draft'},
    {text: i18n.t('story.backlog'), value: 'backlog'},
    {text: i18n.t('story.planified'), value: 'planified'},
    {text: i18n.t('story.open'), value: 'open'},
    {text: i18n.t('story.cancel'), value: 'cancel'},
    {text: i18n.t('story.done'), value: 'done'},
    {text: i18n.t('story.waiting'), value: 'waiting'},
]
export default {
    components: {
        Lexicons,
        CapabilityForm,
        CapabilityIndexV2,
        ProjectMenu,
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data () {
        return {
            readyProject: false,
            project: {},
            loading: false,
            loadingBacklogPrint: false,
            dialogPrintBacklog: false,
            dialogAddCapability: false,
            dialogImportStories: false,
            imported: {'project_id': this.id},
            importedHelp: false,
            // filterStoriesStatus: [],
            // filterStoriesStatus: ['draft', 'backlog', 'planified', 'open', 'cancel', 'done', 'waiting'],
            selectedStories: [],
            selectedStoriesStatus: null,
            showStoriesUpdate: false,
            storyStatus: storyStatus,
            printParams: {
                showEstimate: 1,
                showPricing: 1,
                showStories: 1,
                showSprintZero: 1,
                showSynthesis: 1,
                statusStories: storyStatus.map(s => s.value),
            },
        }
    },
    mounted () {
        this.getProject()
    },
    computed: {
        estimate() {
            let res = 0
            let that = this
            this.project.capabilities.forEach(function(c) {
                c.features.forEach(function(f) {
                    that.$set(f, 'stories_estimate', f.stories.reduce((acc,s) => acc + parseFloat(s.estimate || 0), 0))
                })
                that.$set(c, 'estimate', c.features.reduce((acc, f) => acc + parseFloat(f.stories_estimate || f.estimate || 0), 0))
                res += c.estimate
            })
            return res
        },
        capabilitiesCount() {
            return this.project.capabilities.length
        },
        featuresCount() {
            return this.project.capabilities.reduce((acc, c) => acc + parseInt(c.features.length), 0)
        },
        storiesCount() {
            return this.project.capabilities.reduce((acc1, c) => acc1 + parseInt(c.features.reduce((acc2, f) => acc2 + f.stories.length, 0)), 0)
        },
    },
    methods: {
        getProject() {
            this.loading = true
            this.$store.dispatch('projectRequest', {id: this.id, with: ['capabilities.features.stories']})
                .then(result => {
                    this.project = result.data
                    if (! this.project.capabilities) this.project.capabilities = []
                    this.readyProject = true
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        printBacklog() {
            this.loadingBacklogPrint = true
            let queries = {
                id: this.project.id,
                datas: this.printParams,
            }
            let filename = '[' + this.project.complete_name + '] ProductBacklog.pdf'
            return this.$store.dispatch('projectPrintBacklogRequest', queries)
                .then((data) => {
                    const url = window.URL.createObjectURL(new Blob([data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                    this.loadingBacklogPrint = false
                })
        },
        importStories () {
            if (this.$refs.form.validate()) {
                const datas = new FormData()
                datas.append('project_id', this.imported.project_id)
                datas.append('file', this.imported.file)

                this.$store.dispatch('storiesImportRequest', datas)
                    .then((workshop) => {
                        this.$refs.form.resetValidation()
                        this.dialogImportStories = false
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.imported')})
                        this.$router.go({name: 'Users'})
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        handleAddedCapability(capability) {
            this.dialogAddCapability = false
            this.project.capabilities.push(capability)
        },
        changeSelectedStories(newSelectedStories, oldSelectedStories) {
            oldSelectedStories.forEach(s => {
                let i = this.selectedStories.findIndex(s2 => s2.id == s.id)
                this.selectedStories.splice(i, 1)
            })
            this.selectedStories = this.selectedStories.concat(newSelectedStories)
            console.log(this.selectedStories.length)
        },
        saveSelectedStories() {
            let queries = {
                object: 'stories', 
                datas: {
                    ids: this.selectedStories.map(s => s.id),
                    field: 'status',
                    value: this.selectedStoriesStatus,
                }
            }
            this.$store.dispatch('massUpdateRequest', queries)
                .then(() => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('stories.saved')})
                    this.selectedStories.forEach(s => s.status = this.selectedStoriesStatus)
                    this.selectedStories = []
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        }
    }
}
</script>