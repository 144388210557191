<template>
    <v-breadcrumbs :items="values">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" exact>
                <span :class="color +'--text headline'">{{ item.text }}</span>
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>
<script>
export default {
    props: {
        values: {
            type: Array,
            required: true
        },
        color: {
            type: String,
            default: 'black'
        }
    },
    data () {
        return {
            // 
        }
    },
    mounted () {
        // 
    },
    methods: {
        // 
    }
}
</script>