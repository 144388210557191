import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)
 
export default new Vuetify({
    icons: {
        iconfont: 'md'
    },
    theme: {
        options: {
            customProperties: true,
        },
        dark: false,
        themes: {
            light: {
                primary: '#000',
                secondary: '#000'
            },
            dark: {
                primary: '#000',
                secondary: '#000'
            }
        }
    }
})
