<template>
    <v-content>
        <v-card color="primary" dark flat>
            <v-card-text class="py-4">
                <v-row no-gutters align="center" class="headline mb-3">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('ethercalc.icon') }}</v-icon></v-col>
                    <v-col class="text-no-wrap">{{ $t('object.ethercalcs') }}</v-col>
                    <v-col cols="12" sm="auto">
                        <v-btn 
                            block
                            color="primary"
                            @click="dialogEditEthercalc = true"
                        >
                            {{ $t('create') }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row dense justify="center" align="center">
                    <v-col style="min-width: 200px;">        
                        <v-text-field
                            v-model="search.title"
                            clearable
                            flat
                            solo
                            hide-details
                            prepend-inner-icon="search"
                            :label="$t('ethercalc.title')"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row justify="start">
                <v-col cols="6" :sm="4" :md="2" :lg="1" v-for="item in ethercalcs" :key="item">
                    <v-sheet class="text-center">
                        <div @click="showEthercalc(item)" class="cursor-pointer">
                            <v-icon size="60">description</v-icon>
                            <div>{{ decodeURI(item) }}</div>
                        </div>
                        <RemoveButton text small @confirmed="deleteEthercalc(item)"/>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="dialogEditEthercalc">
            <v-card>
                <v-card-title class="secondary white--text">
                    {{ $t('object.ethercalc') }}
                </v-card-title>
                <v-card-text class="pa-3">
                    <v-form v-on:submit.prevent="submitNewEthercalcForm" ref="newEthercalcForm">
                        <v-row no-gutters>
                            <v-col>
                                <v-text-field
                                    v-model="newEthercalc"
                                    filled
                                    :label="$t('ethercalc.title')"
                                    :rules="[rules.required, rules.valid]"
                                    hide-details
                                    height="68px"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn color="primary" type="submit" depressed :style="{height: '100%'}">
                                    <v-icon>done</v-icon>
                                </v-btn>
                                <v-btn @click.stop="cancelNewEthercalcForm" text color="primary" :style="{height: '100%'}">
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <small>{{ $t('ethercalc.forbidden_char') }}: ()[]/\</small>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-content>
</template>
<script>
export default {
    data () {
        return {
            ethercalcs: [],
            loading: false,
            dialogEditEthercalc: false,
            editedEthercalc: {},
            search: {},
            newEthercalc: '',
            rules: {
                required: value => !!value || this.$t('rules.required'),
                valid: value => /^[^()[\]/]+$/.test(value) || 'Charactères interdits: ()[]/\\',
            },
        }
    },
    watch: {
        search: {
            handler () {this.fetchEthercalcs()},
            deep: true,
        },
    },
    mounted() {
        this.fetchEthercalcs()
    },
    methods: {
        fetchEthercalcs () {
            const queries = {
                search: JSON.stringify(this.search),
            }

            this.loading = true
            this.ethercalcs = []
            this.$store.dispatch('ethercalcsRequest', queries)
                .then(result => {
                    this.ethercalcs = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        submitNewEthercalcForm() {
            if (this.$refs.newEthercalcForm.validate()) {
                this.showEthercalc(this.newEthercalc)
                this.cancelNewEthercalcForm()
                this.$refs.newEthercalcForm.resetValidation()
                var that = this
                setTimeout(function () {
                    that.fetchEthercalcs()
                }, 3000);
            }
        },
        cancelNewEthercalcForm() {
            this.newEthercalc = ''
            this.dialogEditEthercalc = false
        },
        showEthercalc(item) {
            let win = window.open(process.env.VUE_APP_ETHERCALC_URL + '/' + item, '_blank')
            win.focus()
        },
        deleteEthercalc(item) {
            this.$store.dispatch('ethercalcDeleteRequest', {key: item}).then(() => {
                this.fetchEthercalcs()
            })

        }
    },
}
</script>