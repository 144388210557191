<template>
    <v-content class="todos">
        <v-card color="primary" dark flat>
            <v-card-text class="py-4">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('calendar.icon') }}</v-icon></v-col>
                    <v-col class="white--text text-no-wrap">{{ $t('calendar.title') }}</v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-toolbar flat>
            <v-toolbar-title>
                <v-btn icon @click="$refs.calendar.prev()">
                    <v-icon>keyboard_arrow_left</v-icon>
                </v-btn>
                {{ $moment(focus).format('MMMM YYYY') }}
                <v-btn icon @click="$refs.calendar.next()">
                    <v-icon>keyboard_arrow_right</v-icon>
                </v-btn>
                <v-btn text @click="focus = $moment().format('YYYY-MM-DD')">{{ $t('calendar.today') }}</v-btn>
            </v-toolbar-title>

            <v-btn color="primary" @click="newBlock" depressed>
                <v-icon>add</v-icon> {{ $t('add') }}
            </v-btn>

            <v-spacer/>

            <v-radio-group v-model="search.users" row hide-details>
                <v-radio :label="$t('calendar.my')" value="my"></v-radio>
                <v-radio :label="$t('calendar.all')" :value="null"></v-radio>
            </v-radio-group>

            <v-spacer/>

            <div class="d-flex">
                <v-btn-toggle v-model="type" background-color="transparent" color="white">
                    <v-btn active-class="primary" outlined value="month">{{ $t('calendar.month') }}</v-btn>
                    <v-btn active-class="primary" outlined value="week">{{ $t('calendar.week') }}</v-btn>
                    <v-btn active-class="primary" outlined value="day">{{ $t('calendar.day') }}</v-btn>
              </v-btn-toggle>
            </div>
        </v-toolbar>

        <v-container fluid :style="containerStyle" class="pa-0">
            <v-row no-gutters class="fill-height">
                <v-col cols="auto" style="height: 100%; max-width: 20%;">
                    <div class="title text-center">TODO</div>
                    <div style="max-height: calc(100% - 32px);" class="overflow-y-auto">
                        <div 
                            v-for="(todos, due_at) in todoThread"
                            :key="due_at"
                            two-line
                        >
                            <v-divider class="primary lighten-5 ma-3"/>
                            <div v-if="due_at == 'null'" class="text-center">asap</div>
                            <div v-else class="text-center">{{ $moment(due_at).format('DD/MM/YYYY') }}</div>
                            <div 
                                v-for="todo in todos" 
                                :key="todo.id" 
                                v-on:click="showBlock({nativeEvent: $event, event: formatTodoToBlock(todo)})"
                                class="pa-1 todo d-flex align-center"
                            >
                                <v-avatar :color="todo.user.color || 'primary'" size="30" class="white--text mr-1">
                                    <small>{{ todo.user.name.split(" ").map((n)=>n[0]).join("") }}</small>
                                </v-avatar>
                                <div class="text-truncate">{{ todo.title }}</div>
                            </div>
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <v-calendar
                        ref="calendar"
                        :weekdays="[1, 2, 3, 4, 5]"
                        :type="type"
                        :events="blocks"
                        :event-color="(event) => event.color"
                        :event-more="false"
                        v-model="focus"
                        color="primary"
                        @change="fetchAll"
                        @click:event="showBlock"
                        @click:date="viewDay"
                        @click:day="newBlock"
                        @click:time="newBlock"
                        :locale="$i18n.locale"
                        :first-interval="8*2"
                        :interval-count="12*2"
                        :interval-minutes="60/2"
                    >
                        <template v-slot:event="{ event }">
                            <div v-if="event.todo" class="px-1 d-flex align-center justify-space-between">
                                <div class="text-truncate">{{ event.name }}</div>
                                <v-icon v-if="event.done" color="white" small>done</v-icon>
                            </div>
                            <div v-if="event.event" class="px-1 d-flex align-center justify-space-between">
                                <div class="text-truncate">
                                    {{ event.event.users.map(u => u.name.split(" ").map((n)=>n[0]).join("")).join(",") }}
                                     - {{ event.name }}
                                 </div>
                                <span>{{ $moment(event.start).format('HH[h]') }}</span>
                            </div>
                        </template>
                    </v-calendar>

                    <v-menu
                        v-model="selectedOpen"
                        :close-on-content-click="false"
                        :close-on-click="! dialogEditBlock"
                        :activator="selectedElement"
                        offset-x
                    >
                        <v-card>
                            <v-toolbar :color="selectedBlock.color" dark flat dense>
                                <v-btn icon fab small @click="selectedOpen = false"><v-icon>close</v-icon></v-btn>
                                <v-toolbar-title class="mr-4 text-no-wrap">{{ selectedBlock.name }}</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon fab small @click="editBlock(selectedBlock)"><v-icon>edit</v-icon></v-btn>
                                <RemoveButton
                                    depressed
                                    text
                                    small
                                    color="white"
                                    @confirmed="removeBlock(selectedBlock)"
                                />
                            </v-toolbar>

                            <v-card-text v-if="selectedTodo">
                                <div class="d-flex justify-space-between">
                                    <v-chip small :color="selectedBlock.color" dark>{{ selectedTodo.user.name }}</v-chip>
                                    <v-chip small :color="selectedBlock.color" dark v-if="selectedTodo.project">{{ selectedTodo.project.complete_name }}</v-chip>
                                </div>
                                <div class="my-5 text-pre" v-html="selectedTodo.description"></div>
                                
                                <small>{{ $t('todo.created', {by: selectedTodo.created_by.name, at: $moment(selectedTodo.created_at).format('DD/MM/YYYY [à] HH:mm')}) }}</small>
                            </v-card-text>

                            <v-card-text v-if="selectedEvent">
                                <div class="d-flex justify-space-between">
                                    {{ formatEventTimeslot(selectedEvent) }}
                                    <v-chip v-if="selectedEvent.project" small :color="selectedBlock.color" dark>{{ selectedEvent.project.complete_name }}</v-chip>
                                </div>
                                <p>
                                    {{ $t('calendar.with') }}

                                    <v-chip v-for="user in selectedEvent.users" :key="user.id" small :color="selectedBlock.color" dark class="mx-1">{{ user.name }}</v-chip>
                                </p>

                                <p>
                                    {{ $t('event.attendees') }}:

                                    <v-chip 
                                        v-for="attendee in selectedEvent.attendees" 
                                        :key="attendee.email" 
                                        small 
                                        :color="selectedBlock.color" 
                                        dark 
                                        class="mx-1"
                                        label
                                    >
                                        {{ attendee.email }}
                                        <v-icon v-if="attendee.status == 'accepted'" right small>thumb_up</v-icon>
                                        <v-icon v-if="attendee.status == 'waiting'" right small>hourglass_full</v-icon>
                                        <v-icon v-if="attendee.status == 'declined'" right small>thumb_down</v-icon>
                                    </v-chip>
                                </p>

                                <div class="my-5 text-pre" v-html="selectedEvent.description"></div>
                                
                                <small>{{ $t('todo.created', {by: selectedEvent.created_by.name, at: $moment(selectedEvent.created_at).format('DD/MM/YYYY [à] HH:mm')}) }}</small>
                            </v-card-text>

                            <v-card-actions v-if="selectedTodo" class="px-0 py-0">
                                <v-btn v-if="! selectedTodo.done_at" block color="red" @click="closeTodo(selectedTodo)" dark>
                                    {{ $t('todo.close') }}
                                </v-btn>
                                <v-btn v-else block color="success" disabled>
                                    <v-icon>done</v-icon>{{ $t('todo.done') }}
                                </v-btn>
                            </v-card-actions>

                            <!-- <v-card-actions v-if="selectedTodo" class="px-0 py-0">
                                <messages messageable_type="todo" :messageable_id="selectedTodo.id" :key="Date.now()" :dark="false"/>
                            </v-card-actions>
                            <v-card-actions v-if="selectedEvent" class="px-0 py-0">
                                <messages messageable_type="event" :messageable_id="selectedEvent.id" :key="Date.now()" :dark="false"/>
                            </v-card-actions> -->
                        </v-card>
                    </v-menu>
                    <v-dialog v-model="dialogEditBlock" max-width="800px" persistent scrollable>
                        <v-card>
                            <v-card-title class="secondary white--text">{{ $t('add') }}</v-card-title>
                            <v-card-text>
                                <v-tabs v-model="tab" grow>
                                    <v-tab key="todo" v-if="editedBlock.todo">{{ $t('object.todo') }}</v-tab>
                                    <v-tab key="event" v-if="editedBlock.event">{{ $t('object.event') }}</v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item key="todo" v-if="editedBlock.todo">
                                        <TodoForm v-model="editedBlock.todo" :key="Date.now()" @savedForm="handleSavedTodoForm" @canceledForm="dialogEditBlock = false"/>
                                    </v-tab-item>
                                    <v-tab-item key="event" v-if="editedBlock.event">
                                        <EventForm v-model="editedBlock.event" :key="Date.now()" @savedForm="handleSavedEventForm" @canceledForm="dialogEditBlock = false"/>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>
<style scoped>
    .wrapper {
        position: absolute;
        width: 100%;
    }
    .todo {cursor: pointer;}
    .todo:hover {
        background-color: rgba(0,0,0,0.05);
    }
</style>
<script>
import TodoForm from '@/components/todo/Form'
import EventForm from '@/components/event/Form'
export default {
    components: {
        TodoForm,
        EventForm,
    },
    data() {
        return {
            type: 'month',
            focus: this.$moment().format('YYYY-MM-DD'),
            search: {users: 'my'},
            todos: [],
            events: [{ start: "1900-01-01", name: "" }],
            loading: false,
            selectedBlock: {},
            selectedElement: null,
            selectedOpen: false,
            editedBlock: {},
            dialogEditBlock: false,
            tab: null,
            undatedTodos: [],
        }
    },
    watch: {
        search: {
            handler () {
                this.fetchAll()
            },
            deep: true,
        },
    },
    computed: {
        blocks() {
            return [
                ...this.todos.map(t => this.formatTodoToBlock(t)), 
                ...this.events.map(e => this.formatEventToBlock(e)),
            ]
        },
        todoThread() {
            var groupBy = function(xs, key) {
                return xs.reduce(function(rv, x) {
                    (rv[x[key]] = rv[x[key]] || []).push(x)
                    return rv
                }, {})
            }

            let thread = [
                ...this.todos.filter(t => ! t.done_at),
                ...this.undatedTodos,
            ]
            // sort
            thread = thread.sort((t1, t2) => (t1.due_at > t2.due_at) ? 1 : (t2.due_at > t1.due_at) ? -1 : 0 )
            // group
            thread = groupBy(thread, 'due_at')
            // Object.keys(thread).map(function(key, index) {
            //     thread[key] = groupBy(thread[key], 'user_id')
            // })
            return thread
        },
        selectedTodo() {
            return this.selectedBlock.todo
        },
        selectedEvent() {
            return this.selectedBlock.event
        },
        containerStyle() {
            let height = 'calc(100vh - 80px - 64px - 64px)'
            if (['week','day'].indexOf(this.type) > -1)
                return {'min-height': height}
            return {'height': height}
        }
    },
    mounted() {

    },
    methods: {
        fetchAll() {
            this.fetchTodos()
            this.fetchEvents()
            this.fetchUndatedTodos()
        },
        fetchTodos () {
            let queries = {
                per_page: -1,
                search: JSON.stringify({
                    ...this.search,
                    start: this.$moment(this.focus).startOf(this.type).format('YYYY-MM-DD')+' 00:00:00',
                    end: this.$moment(this.focus).endOf(this.type).format('YYYY-MM-DD')+' 23:59:59'
                }),
                with: ['user', 'project', 'created_by'],
            }

            this.loading = true
            this.todos = []
            this.$store.dispatch('todosRequest', queries)
                .then(result => {
                    this.todos = result.data
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        fetchUndatedTodos() {
            let queries = {per_page: -1, search: JSON.stringify({...this.search, undated: 1, open: 1}), with: ['user', 'project', 'created_by']}
            this.loading = true
            this.undatedTodos = []
            this.$store.dispatch('todosRequest', queries)
                .then(result => {
                    this.undatedTodos = result.data
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        fetchEvents () {
            let queries = {
                per_page: -1,
                search: JSON.stringify({
                    ...this.search,
                    start: this.$moment(this.focus).startOf(this.type).format('YYYY-MM-DD')+' 00:00:00',
                    end: this.$moment(this.focus).endOf(this.type).format('YYYY-MM-DD')+' 23:59:59'
                }),
                with: ['users', 'project', 'created_by'],
            }

            this.loading = true
            this.events = []
            this.$store.dispatch('eventsRequest', queries)
                .then(result => {
                    this.events = result.data
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        formatTodoToBlock(todo) {
            return {
                name: todo.user.name.split(" ").map((n)=>n[0]).join("")+' - '+todo.title,
                start: todo.due_at,
                end: todo.due_at,
                color: todo.user.color || 'primary',
                timed: false,
                done: !! todo.done_at,
                todo: todo,
            }
        },
        formatEventToBlock(event) {
            return {
                name: event.title,
                start: event.start,
                end: event.end,
                color: 'primary',
                timed: true,
                event: event,
            }
        },
        formatEventTimeslot(event) {
            let start_day = this.$moment(event.start).format('DD/MM/YYYY')
            let end_day = this.$moment(event.end).format('DD/MM/YYYY')
            let start_time = this.$moment(event.start).format('HH:mm')
            let end_time = this.$moment(event.end).format('HH:mm')
            let string = ''

            if (start_day == end_day) {
                string += this.$t('calendar.le')+' '+start_day
                if (start_time == end_time)
                    string += this.$t('calendar.à')+' '+start_time
                else
                    string += ' '+this.$t('calendar.de')+' '+start_time+' '+this.$t('calendar.à')+' '+end_time
            } else {
                string =+ this.$t('calendar.du')+' '+start_day+' '+this.$t('calendar.à')+' '+start_time
                string =+ this.$t('calendar.au')+' '+end_day+' '+this.$t('calendar.à')+' '+end_time
            }

            return string
        },
        viewDay({date}) {
            this.focus = date
            this.type = 'day'
        },
        showBlock({nativeEvent, event}) {
            const open = () => {
                this.selectedBlock = event
                this.selectedElement = nativeEvent.target
                setTimeout(() => {this.selectedOpen = true}, 10)
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                setTimeout(open, 10)
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },
        editBlock(block) {
            this.editedBlock = block
            this.dialogEditBlock = true
            this.selectedOpen = false
        },
        handleSavedTodoForm(todo) {
            this.dialogEditBlock = false
            if (todo.due_at) {
                let i = this.todos.findIndex(t => t.id == todo.id)
                if (i >= 0) Object.assign(this.todos[i], todo)
                else this.todos.push(todo)
            } else {
                let i = this.undatedTodos.findIndex(t => t.id == todo.id)
                if (i >= 0) Object.assign(this.undatedTodos[i], todo)
                else this.undatedTodos.push(todo)
            }
        },
        handleSavedEventForm(event) {
            this.dialogEditBlock = false
            let i = this.events.findIndex(t => t.id == event.id)
            if (i >= 0) Object.assign(this.events[i], event)
            else this.events.push(event)
        },
        removeBlock(block) {
            if (block.todo)
                this.removeTodo(block.todo.id)
            if (block.event)
                this.removeEvent(block.event.id)
        },
        removeTodo(todo_id) {
            return this.$store.dispatch('todoDeleteRequest', { id: todo_id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('todo.deleted')});
                this.selectedOpen = false
                let i = this.todos.findIndex(t => t.id == todo_id)
                this.todos.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        removeEvent(event_id) {
            return this.$store.dispatch('eventDeleteRequest', { id: event_id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('event.deleted')});
                this.selectedOpen = false
                let i = this.events.findIndex(t => t.id == event_id)
                this.events.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        closeTodo(todo) {
            let data = {...todo, done_at: this.$moment().format('YYYY-MM-DD HH:mm:ss')}
            this.$store.dispatch('todoEditRequest', {id: todo.id, datas: data})
                .then((result) => {
                    todo.done_at = result.done_at
                    this.selectedOpen = false
                    let i = this.undatedTodos.findIndex(t => t.id == todo.id)
                    if (i > -1) this.undatedTodos.splice(i, 1)
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('todo.saved')});
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        newBlock(ev) {
            this.selectedOpen = false
            this.editedBlock = {
                todo: {user_id: this.$store.state.auth.profile.id},
                event: {users: [this.$store.state.auth.profile.id], attendees: []}
            }
            if (ev.date) {
                this.editedBlock.todo.due_at = ev.date
                this.editedBlock.event.start = ev.date+' '
                this.editedBlock.event.end = ev.date+' '
                if (ev.hour) {
                    this.editedBlock.event.start += ev.hour+':00'
                    this.editedBlock.event.end += (ev.hour+1)+':00'
                } else {
                    this.editedBlock.event.start += this.$moment().add(1, 'h').format('HH:00')
                    this.editedBlock.event.end += this.$moment().add(2, 'h').format('HH:00')
                }
            }
            this.tab = 'todo'
            this.dialogEditBlock = true
        },
    }
}
</script>
