<template>
    <v-content class="product-definition">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline">
                    <v-col cols="auto"><v-icon large class="mr-5">{{ $t('project.icon') }}</v-icon></v-col>
                    <breadcrumbs color="white" :values="[
                        {text: $t('object.projects'), to: {name: 'Projects'}},
                        {text: project.complete_name, to: {name: 'Project', params: {id: project.id}}},
                        {text: $t('project.product_definition')},
                    ]"/>
                    <v-spacer/>
                    <v-col cols="auto">
                        <ProjectMenu v-model="project" small/>
                        -
                        <v-btn
                            @click="printProductDefinition"
                            class="ml-2"
                            fab
                            text
                            small
                            :loading="loadingProductDefinitionPrint"
                        >
                            <v-icon>print</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row dense>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <v-card>
                        <v-card-title>
                            {{ $t('project.product_definition') }}
                        </v-card-title>

                        <v-card-text>
                            <v-expansion-panels
                                :light="!$vuetify.theme.dark"
                                accordion
                                flat
                                multiple
                                :value="[0,1,2,3]"
                            >
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <v-hover>
                                            <template v-slot:default="{ hover }">
                                                <v-row no-gutters align="center" justify="space-between">
                                                    <v-col cols="auto" class="title">
                                                        {{ $t('project.product_card') }}
                                                    </v-col>
                                                    <v-spacer/>
                                                    <v-col cols="auto" :style="! hover ? 'visibility: hidden' : ''">
                                                        <v-btn
                                                            fab
                                                            color="primary"
                                                            @click.stop="dialogEditProductVision = true"
                                                            small
                                                            class="mr-2"
                                                            depressed
                                                        >
                                                            <v-icon>edit</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-hover>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        <v-row>
                                            <v-col cols="12" :sm="4">{{ $t('productVision.description') }}</v-col>
                                            <v-col v-html="project.product_vision.description"></v-col>
                                        </v-row>
                                        <hr>
                                        <v-row>
                                            <v-col cols="12" :sm="4">{{ $t('productVision.target') }}</v-col>
                                            <v-col v-html="project.product_vision.target"></v-col>
                                        </v-row>
                                        <hr>
                                        <v-row>
                                            <v-col cols="12" :sm="4">{{ $t('productVision.needs') }}</v-col>
                                            <v-col v-html="project.product_vision.needs"></v-col>
                                        </v-row>
                                        <hr>
                                        <v-row>
                                            <v-col cols="12" :sm="4">{{ $t('productVision.product') }}</v-col>
                                            <v-col v-html="project.product_vision.product"></v-col>
                                        </v-row>
                                        <hr>
                                        <v-row>
                                            <v-col cols="12" :sm="4">{{ $t('productVision.goals') }}</v-col>
                                            <v-col v-html="project.product_vision.goals"></v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <v-hover>
                                            <template v-slot:default="{ hover }">
                                                <v-row no-gutters align="center" justify="space-between">
                                                    <v-col cols="auto" class="title">
                                                        {{ $t('project.requirements') }}
                                                    </v-col>
                                                    <v-spacer/>
                                                    <v-col cols="auto" :style="! hover ? 'visibility: hidden' : ''">
                                                        <v-btn
                                                            fab
                                                            color="primary"
                                                            @click.stop="dialogEditProjectRequirements = true"
                                                            small
                                                            class="mr-2"
                                                            depressed
                                                        >
                                                            <v-icon>edit</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-hover>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        <v-row>
                                            <v-col cols="12" :sm="4"></v-col>
                                            <v-col v-html="project.requirements"></v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <v-hover>
                                            <template v-slot:default="{ hover }">
                                                <v-row no-gutters align="center" justify="space-between">
                                                    <v-col cols="auto" class="title">
                                                        {{ $t('project.technical_env') }}
                                                    </v-col>
                                                    <v-spacer/>
                                                    <v-col cols="auto" :style="! hover ? 'visibility: hidden' : ''">
                                                        <v-btn
                                                            fab
                                                            color="primary"
                                                            @click.stop="dialogEditProjectTechnical = true"
                                                            small
                                                            class="mr-2"
                                                            depressed
                                                        >
                                                            <v-icon>edit</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-hover>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <hr>
                                        <v-row>
                                            <v-col cols="12" :sm="4">
                                                {{ $t('project.scheme') }}
                                            </v-col>
                                            <v-col>
                                                <v-img v-if="technical_scheme_url" alt="" :src="technical_scheme_url"></v-img>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" :sm="4">
                                                {{ $t('project.technical_needs') }}
                                            </v-col>
                                            <v-col v-html="project.technical_needs"></v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                                <v-expansion-panel>
                                    <v-expansion-panel-header class="title">
                                        {{ $t('project.product_usertypes') }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-hover v-for="usertype in project.product_usertypes" :key="usertype.id">
                                            <template v-slot:default="{ hover }">
                                                <v-row dense>
                                                    <v-col cols="12"><hr></v-col>
                                                    <v-col cols="12" :sm="4">{{ usertype.name }}</v-col>
                                                    <v-col>
                                                        <div>{{ $t('productUsertype.motivation') }}</div>
                                                        <div v-html="usertype.motivation"></div>
                                                        <br>
                                                        <div>{{ $t('productUsertype.goals') }}</div>
                                                        <div v-html="usertype.goals"></div>
                                                        <br>
                                                        <div>{{ $t('productUsertype.pain_points') }}</div>
                                                        <div v-html="usertype.pain_points"></div>
                                                    </v-col>
                                                    <v-col cols="auto" :style="! hover ? 'visibility: hidden' : ''">
                                                        <v-btn
                                                            fab
                                                            color="primary"
                                                            @click.stop="editProductUsertype(usertype)"
                                                            small
                                                            class="mr-2"
                                                            depressed
                                                        >
                                                            <v-icon>edit</v-icon>
                                                        </v-btn>
                                                        <RemoveButton 
                                                            color="red darken-1"
                                                            small
                                                            depressed
                                                            @confirmed="deleteProductUsertype(usertype.id)"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-hover>
                                        <v-row>
                                            <v-col cols="auto">
                                                <v-btn
                                                    light
                                                    @click="editProductUsertype({project_id: project.id})"
                                                    class="mt-5"
                                                >
                                                    <v-icon>add</v-icon>
                                                    {{ $t('add') }}
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>

                            </v-expansion-panels>

                            <v-dialog v-model="dialogEditProductVision" max-width="80%" persistent scrollable>
                                <v-card>
                                    <v-card-title class="secondary white--text">
                                        {{ $t('project.product_card') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <ProductVisionForm ref="ProductVisionForm" v-model="project.product_vision" :key="Date.now()" @savedForm="handleSavedProductVisionForm" @canceledForm="handleCanceledProductVisionForm"/>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>

                            <v-dialog v-model="dialogAddProductUsertype" max-width="80%" persistent scrollable>
                                <v-card>
                                    <v-card-title class="secondary white--text">
                                        {{ $t('project.product_usertype') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <ProductUsertypeForm ref="ProductUsertypeForm" v-model="editedProductUsertype" :key="Date.now()" @savedForm="handleSavedProductUsertypeForm" @canceledForm="handleCanceledProductUsertypeForm"/>
                                    </v-card-text>
                                </v-card>
                            </v-dialog>

                            <v-dialog v-model="dialogEditProjectRequirements" max-width="80%" persistent scrollable>
                                <v-card>
                                    <v-card-title class="secondary white--text">
                                        {{ $t('project.requirements') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <ProjectForm 
                                            ref="ProjectForm" 
                                            v-model="project" 
                                            :key="Date.now()" 
                                            @savedForm="handleSavedProjectForm" 
                                            @canceledForm="handleCanceledProjectForm"
                                            :fields="['requirements']"
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-dialog>

                            <v-dialog v-model="dialogEditProjectTechnical" max-width="80%" persistent scrollable>
                                <v-card>
                                    <v-card-title class="secondary white--text">
                                        {{ $t('project.technical_scheme') }}
                                    </v-card-title>
                                    <v-card-text>
                                        <ProjectForm 
                                            ref="ProjectForm" 
                                            v-model="project" 
                                            :key="Date.now()" 
                                            @savedForm="handleSavedProjectForm" 
                                            @canceledForm="handleCanceledProjectForm"
                                            :fields="['technical_needs', 'technical_scheme']"
                                        />
                                    </v-card-text>
                                </v-card>
                            </v-dialog>

                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <Lexicons v-if="ready" :project_id="project.id"/>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <messages v-if="ready" messageable_type="project" :messageable_id="project.id"/>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
import Lexicons from '@/components/Lexicons'
import ProductVisionForm from '@/components/product_vision/Form'
import ProductUsertypeForm from '@/components/product_usertype/Form'
import ProjectForm from '@/components/project/Form'
import ProjectMenu from '@/components/project/Menu'
export default {
    components: {
        Lexicons,
        ProductVisionForm,
        ProductUsertypeForm,
        ProjectForm,
        ProjectMenu
    },
    props: {
        id: { type: [String, Number], required: true },
    },
    data () {
        return {
            ready: false,
            project: {},
            loading: false,
            loadingProductDefinitionPrint: false,
            dialogEditProductVision: false,
            dialogAddProductUsertype: false,
            editedProductUsertype: {},
            dialogEditProjectRequirements: false,
            dialogEditProjectTechnical: false,
            technical_scheme_url: null,
        }
    },
    watch: {
        project: {
            handler() {
                if (this.project.technical_scheme)
                    this.$axios.get('/api/attachments/'+this.project.technical_scheme.id, {responseType: 'blob'})
                        .then( (response) => {
                            const url = window.URL.createObjectURL(new Blob([response.data]))
                            this.technical_scheme_url = url
                        })
            },
            immediate: true,
        }
    },
    mounted () {
        this.getProject()
    },
    methods: {
        getProject() {
            this.loading = true
            this.$store.dispatch('projectRequest', {id: this.id, with: ['product_vision', 'product_usertypes', 'technical_scheme']})
                .then(result => {
                    this.project = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        printProductDefinition() {
            let filename = '[' + this.project.complete_name + '] ProductProductDefinition.pdf'
            this.loadingProductDefinitionPrint = true
            return this.$store.dispatch('projectPrintProductDefinitionRequest', { id: this.project.id}).then((data) => {
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()
                link.remove()
                this.loadingProductDefinitionPrint = false
            });
        },
        handleCanceledProductVisionForm() {
            this.dialogEditProductVision = false
        },
        handleSavedProductVisionForm(productVision) {
            this.dialogEditProductVision = false
            this.project.product_vision = productVision
        },
        handleCanceledProjectForm() {
            this.dialogEditProjectRequirements = false
            this.dialogEditProjectTechnical = false
        },
        handleSavedProjectForm(project) {
            this.dialogEditProjectRequirements = false
            this.dialogEditProjectTechnical = false
            this.project = project
        },
        editProductUsertype(productUsertype) {
            this.editedProductUsertype = productUsertype
            this.dialogAddProductUsertype = true
        },
        handleCanceledProductUsertypeForm() {
            this.editedProductUsertype = {}
            this.dialogAddProductUsertype = false
        },
        handleSavedProductUsertypeForm(productUsertype) {
            this.dialogAddProductUsertype = false
            let i = this.project.product_usertypes.findIndex(m => m.id == productUsertype.id)
            if (i >= 0)
                Object.assign(this.project.product_usertypes[i], productUsertype)
            else
                this.project.product_usertypes.push(productUsertype)
        },
        deleteProductUsertype(productUsertype_id) {
            return this.$store.dispatch('productUsertypeDeleteRequest', { id: productUsertype_id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('productUsertype.deleted')});
                let i = this.project.product_usertypes.findIndex(m => m.id == productUsertype_id)
                this.project.product_usertypes.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
    }
}
</script>