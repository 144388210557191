<template>
    <v-content class="parameter">
        <v-card color="primary" dark flat>
            <v-card-text class="py-0">
                <v-row no-gutters align="center" class="headline mb-3">
                    <v-col cols="auto"><v-icon large class="white--text mr-5">settings</v-icon></v-col>
                    <v-col class="white--text text-no-wrap">{{ $t('navigation.configuration') }}</v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-container fluid>
            <v-row>
                <v-col cols="12" v-if="loading">
                    <v-skeleton-loader type="article, list-item, list-item, list-item" height="500px"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" v-if="ready">
                    <Lexicons :values="default_lexicons"/>
                </v-col>
                    <!-- <v-row>
                        <v-col>
                            Next params...
                        </v-col>
                    </v-row> -->
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
import Lexicons from '@/components/Lexicons'
export default {
    components: {
        Lexicons,
    },
    props: {
        // 
    },
    data () {
        return {
            ready: false,
            loading: false,
            default_lexicons: [],
        }
    },
    mounted () {
        this.getDefaultLexicons()
    },
    methods: {
        getDefaultLexicons() {
            this.loading = true
            this.$store.dispatch('defaultLexiconsRequest', {})
                .then(result => {
                    this.default_lexicons = result.data
                    this.loading = false
                    this.ready = true
                })
                .catch((err) => {
                    this.loading = false
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
    }
}
</script>