const translations = {

    add: 'ajouter',
    add_file: 'add file',
    cancel: 'cancel',
    confirm: 'confirm',
    confirm_text: 'please confirm the deletion',
    create: 'create',
    loadmore: 'load more',
    new: 'new',
    no_datas: 'no datas',
    pagination: 'page {current} on {last}',
    items_per_page: 'items per page',
    remove: 'remove',
    save: 'save',
    search: 'search',
    sort_by: 'sort by',
    update: 'update',
    back: 'go back',
    print: 'print',
    printParams: 'print configuration',
    yes: 'yes',
    no: 'no',
    of: 'of',
    pricing: 'pricing',
    show_mailthread: 'show mail thread', 
    import: 'import',

    object: {
        capabilities: 'capabilities',
        capability: 'capability',
        default_lexicon: 'default lexicon',
        default_lexicons: 'default lexicons',
        feature: 'feature',
        features: 'features',
        issue: 'issue',
        issues: 'issues',
        lexicon: 'lexicon',
        lexicons: 'lexicons',
        partner: 'partner',
        partners: 'partners',
        project: 'project',
        projects: 'projects',
        sprint: 'sprint',
        sprints: 'sprints',
        stories: 'stories',
        story: 'story',
        user: 'user',
        users: 'users',
        tag: 'tag',
        tags: 'tags',
        timesheet: 'timesheet',
        timesheets: 'timesheets',
        todo: 'todo',
        todos: 'todos',
        event: 'event',
        events: 'events',
        ethercalc: 'spreadsheet',
        ethercalcs: 'spreadsheets',
    },

    project: {
        icon: 'developer_board',
        access_and_notifications: 'access and notifications',
        daily_price: 'daily price',
        deleted: 'project deleted !',
        description: 'description',
        estimate: 'estimate',
        short_estimate: 'estim.',
        fold: 'fold',
        fold_all: 'fold all',
        informations: 'informations',
        name: 'name',
        notifications: 'notifications',
        product_backlog: 'product backlog',
        product_card: 'product card',
        product_definition: 'product definition',
        product_manager: 'product manager',
        service_manager: 'service manager',
        product_usertype: 'user type',
        product_usertypes: 'user types',
        project_manager: 'project manager',
        requirements: 'requirements',
        saved: 'project saved !',
        scheme: 'scheme',
        show_stories: 'show stories',
        sp_rate: "sp's rate",
        sp_type: "sp's type",
        sp_value: "sp's value",
        status: 'status',
        technical_env: 'technical environment',
        technical_manager: 'technical manager',
        technical_needs: 'constraint / need',
        technical_scheme: 'technical scheme',
        unfold: 'unfold',
        unfold_all: 'unfold all',
        day: 'day',
        hour: 'hour',
        draft: 'draft',
        commercial: 'commercial',
        design: 'design',
        conception: 'conception',
        maintenance: 'maintenance',
        done: 'done',
        cancel: 'cancel',
        show_sprintzero: 'sprint 0',
        show_synthesis: 'synthesis',
    },

    capability: {
        deleted: 'capability deleted !',
        description: 'description',
        estimate: 'estimate',
        short_estimate: 'estim.',
        name: 'name',
        profits: 'profits',
        ref: 'ref',
        saved: 'capability saved !',
    },

    feature: {
        deleted: 'feature deleted !',
        description: 'description',
        estimate: 'pre estimate',
        short_estimate: 'pre esti.',
        name: 'name',
        priority: 'priority',
        ref: 'ref',
        saved: 'feature saved !',
        status: 'status',
        stories_estimate: 'estimate',
        short_stories_estimate: 'estim.',
        low: 'low',
        medium: 'medium',
        high: 'high',
        draft: 'draft',
        backlog: 'backlog',
        planified: 'planified',
        open: 'open',
        cancel: 'cancel',
        done: 'done',
        waiting: 'waiting',
        comment: 'comment',
    },

    issue: {
        icon: 'bug_report',
        blocking: 'blocking',
        canceled: 'canceled',
        closed: 'closed',
        created: 'created by {by} at {at}',
        created_at: 'created at {at}',
        deleted: 'issue deleted !',
        description: 'description',
        'in-progress': 'in progress',
        'to-test': 'to test',
        major: 'major',
        minor: 'minor',
        new: 'new',
        priority: 'criticity',
        saved: 'issue saved !',
        title: 'title',
        waiting: 'waiting',
        status: 'statut',
    },

    lexicon: {
        deleted: 'lexicon deleted !',
        description: 'description',
        saved: 'lexicon saved !',
        title: 'title',
    },

    story: {
        icon: 'extension',
        as: 'as',
        be_able_to: 'be able to',
        deleted: 'story deleted !',
        imported: 'stories imported !',
        details: 'details',
        estimate: 'estimate',
        short_estimate: 'estim.',
        estim: 'estim.',
        priority: 'priority',
        saved: 'story saved !',
        so_that: 'so that',
        ref: 'ref',
        status: 'status',
        low: 'low',
        medium: 'medium',
        high: 'high',
        draft: 'draft',
        backlog: 'backlog',
        planified: 'planified',
        open: 'open',
        cancel: 'cancel',
        done: 'done',
        waiting: 'waiting',
        null: 'ERROR',
        created_at: 'created at {at}',
        updated_at: 'updated at {at}',
        all: 'all stories',
        no_sprint: 'not specified',
    },

    message: {
        deleted: 'message deleted !',
        is_internal: 'internal',
        new: 'tape your message',
        ok: 'send',
        saved: 'message updated !',
        thread: 'mail thread',
    },

    navigation: {
        configuration: 'parameters',
        logout: 'logout',
        profile: 'my profile',
    },

    partner: {
        city: 'city',
        country: 'country',
        deleted: 'partner deleted !',
        email: 'email',
        icon: 'face',
        name: 'name',
        phone: 'phone',
        saved: 'partner saved !',
        street2: 'street 2',
        street: 'street',
        zip: 'zip',
    },

    filemanager: {
        icon: 'file_copy',
        ged: 'file manager',
    },

    user: {
        deleted: 'user deleted !',
        email: 'email',
        icon: 'directions_run',
        name: 'name',
        old_password: 'old password',
        password: 'password',
        password_confirmation: 'password confirmation',
        saved: 'user saved !',
        type: 'type',
        project: 'project',
        crm: 'crm',
        portal: 'portal',
        color: 'color',
        internal: 'internal',
        external: 'external',
    },

    productUsertype: {
        deleted: 'product usertype deleted !',
        goals: 'goals',
        motivation: 'motivation',
        name: 'name',
        pain_points: 'pain points',
        saved: 'product usertype saved !',
    },

    productVision: {
        deleted: 'product vision deleted !',
        description: 'product vision',
        target: 'targets',
        needs: 'needs',
        product: 'product',
        goals: 'goals',
        saved: 'product vision saved !',
    },

    sprint: {
        name: 'name',
        deleted: 'sprint deleted !',
        description: 'description',
        saved: 'sprint saved !',
        status: 'status',
        draft: 'draft',
        planified: 'planified',
        open: 'open',
        done: 'done',
        waiting: 'waiting',
        estimate: 'estimate',
        short_estimate: 'estim.',        
    },

    timesheet: {
        icon: 'timer',
        date: 'date',
        timed: 'timed',
        description: 'description',
        time: 'time',
        saved: 'timesheet saved !',
        deleted: 'timesheet deleted !',
    },

    todo: {
        icon: 'today',
        saved: 'todo saved !',
        deleted: 'todo deleted !',
        description: 'description',
        created: 'created by {by} at {at}',
        created_at: 'created at {at}',
        user: 'assigned to',
        title: 'titre',
        due_at: 'due date',
        close: 'close',
        done: 'done',
    },

    event: {
        icon: 'today',
        saved: 'event sauvegardée !',
        deleted: 'event supprimée !',
        description: 'description',
        created: 'created by {by} at {at}',
        created_at: 'created at {at}',
        title: 'title',
        place: 'place',
        users: 'users',
        start: 'start',
        end: 'end',
        start_day: 'start (date)',
        start_time: 'start (time)',
        end_day: 'end (date)',
        end_time: 'end (time)',
        attendees: 'attendees',
        attendee: {
            status: 'status',
            accepted: 'accepted',
            waiting: '',
            declined: 'declined',
        },
        resend: 'resend invitations',
    },

    calendar: {
        icon: 'today',
        title: 'agenda',
        month: 'mois',
        week: 'semaine',
        day: 'jour',
        days: {
            0: 'dim',
            1: 'lun',
            2: 'mar',
            3: 'mer',
            4: 'jeu',
            5: 'ven',
            6: 'sam',
        },
        today: "aujourd'hui",
        le: 'le',
        à: 'à',
        de: 'de',
        du: 'du',
        au: 'au',
        with: 'avec',
        my: 'my',
        all: 'all',
    },

    positioning: {
        saved: 'positioning saved !',
    },

    duplication: {
        new: 'new duplication',
        relationships: 'children to duplicate',
        reset_relationships_fields: 'children fields to reset',
    },

    rules: {
        required: 'required',
        minlength: '8 characters min.',
        picture: 'picture file required (.jpg, .png)',
    },

    login: {
        email: 'email',
        forgot_password: 'forgot password ?',
        login: 'login',
        ok: 'ok',
        password: 'password',
    },

    sort: {
        alphabetical: 'alphabetical',
    },

    password_edit: {
        email: 'email',
        login: 'login',
        ok: 'save',
        password: 'password',
        password_confirmation: 'password confirmation',
        password_edit: 'update password',
    },

    password_reset: {
        cancel: 'cancel',
        email: 'email',
        ok: 'send',
        password_reset: 'reset password',
    },

    auth: {
        invalid_credentials: 'invalid credentials',
        disconnected: 'you\'ve been disconnected',
        password_send: 'reset password request send',
        password_reset: 'password saved !',
    },

    ethercalc: {
        icon: 'table_view',
        title: 'title',
        deleted: 'spreadsheet deleted !',
        saved: 'spreadsheet saved !',
        forbidden_char: 'forbidden characters',
    }
}

export default translations