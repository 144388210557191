<template>
    <div class="issues-kanban fill-height">
        <v-card>
            <v-card-text>
                <template>
                    <v-form v-on:submit.prevent="onSubmit" ref="form">
                        <tr>
                             <td v-if="!project_id">        
                                <v-select
                                    v-model="search.project_id"
                                    label="Projet"
                                    filled
                                    :items="projects"
                                    dense
                                    hide-details
                                    clearable
                                    item-value="id"
                                    item-text="name"
                                    class='pa-1'
                                />
                            </td>
                            
                                <td>        
                                    <v-text-field
                                        v-model="search.text"
                                        clearable
                                        hide-details
                                        dense
                                        filled
                                        type="text"
                                        label="Rechercher"
                                        class="pa-1"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-btn class="mb-6 ml-4" large color="primary" @click="searchByText">Rechercher </v-btn>
                                </td>
                          
                        </tr>
                         </v-form>
                    </template>
            </v-card-text>
        </v-card>
        <KanbanBoard ref="IssueKanban" :config="kanbanConfig" :stages="stages" :stagesFilter="stagesFilter" :blocks="blocks" @update-block="updateBlock">
            <v-row no-gutters v-for="stage in stagesFilter" :slot="stage" :key="stage">
                <v-col cols="12">
                    <h2>{{ $t('issue.'+stage) }}</h2>
                </v-col>
                <v-btn v-if="stage == 'new'" color="primary" dark block @click="createIssue" class="mt-2">{{ $t('new') }}</v-btn>
                <v-btn v-else :style="{'visibility': 'hidden'}" class="mt-2"></v-btn>
            </v-row>

            <v-card v-for="issue in blocks" :slot="issue.id" :key="issue.id" @click="showIssue(issue)">
                <v-card-text :class="'px-1 py-1 '+($vuetify.theme.dark ? 'white--text' : 'black--text')">
                    <v-row no-gutters justify="space-between">
                        <v-col cols="auto">#{{ issue.id }}</v-col>
                        <v-col cols="auto"><v-chip small label>{{ $t('issue.'+issue.priority) }}</v-chip></v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="title">{{ issue.title }}</v-col>
                    </v-row>
                    <v-row no-gutters class="overline flex-column" align="center" justify="center">
                        <v-col cols="auto">{{ $t('issue.created_at', {at: $moment(issue.created_at).format('DD/MM/YYYY')}) }}</v-col>
                        <v-col cols="auto" v-if="issue.project"><v-chip small color="primary"><small class="font-weight-bold">{{ issue.project.complete_name }}</small></v-chip></v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <div v-for="stage in stagesFilter" :key="stage" :slot="`footer-${stage}`">
                <v-btn text block @click="fetchIssues(stage)" v-if="nextPages[stage]" :loading="loadingPage == stage">{{ $t('loadmore') }}</v-btn>
            </div>
        </KanbanBoard>

        <v-dialog v-model="dialogShowIssue" max-width="80%">
            <v-card v-if="showedIssue">
                <v-card-title class="primary white--text">
                    <RemoveButton 
                        color="primary"
                        small
                        depressed
                        @confirmed="deleteIssue(showedIssue.id)"
                    />
                    {{ $t('object.issue') }} #{{ showedIssue.id }}
                    <v-spacer/>
                    <v-btn icon dark @click="editIssue(showedIssue)"><v-icon>edit</v-icon></v-btn>
                    <v-btn icon dark @click="dialogShowIssue = false"><v-icon>close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="py-0">
                    <v-row align="center">
                        <v-col cols="auto">
                            <v-chip>{{ $t('issue.'+showedIssue.status) }}</v-chip>
                        </v-col>
                        <v-col>
                            <h2 class="">{{ showedIssue.title }}</h2>
                        </v-col>
                        <v-spacer/>
                        <v-col cols="auto" v-if="showedIssue.project">
                            <v-chip color="primary">{{ showedIssue.project.complete_name }}</v-chip>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('issue.priority') }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ $t('issue.'+showedIssue.priority) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('issue.description') }}</v-list-item-title>
                                    <v-row no-gutters>
                                        <v-col v-html="showedIssue.description"></v-col>
                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col>
                            {{ $t('issue.created', {by: showedIssue.user.name, at: $moment(showedIssue.created_at).format('DD/MM/YYYY [à]  HH:mm')}) }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="px-0 py-0">
                    <v-row no-gutters>
                        <v-col>
                            <messages messageable_type="issue" :messageable_id="showedIssue.id" :key="Date.now()"/>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogEditIssue" max-width="80%" persistent scrollable>
            <v-card>
                <v-card-title class="primary white--text">
                    {{ $t('object.issue') }}
                </v-card-title>
                <v-card-text>
                    <IssueForm ref="IssueForm" v-model="editedIssue" :key="Date.now()" @savedForm="handleSavedIssueForm" @canceledForm="handleCanceledIssueForm"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import IssueForm from '@/components/issue/Form'

// trick to get props in dragula config (kanbanConfig)
let props

export default{
    components: {
        IssueForm,
    },
    props: {
        project_id: { 
            type: [String, Number], 
            required: false
        },
        stages: {
            type: Array,
            required: true,
        },
        stagesFilter: {
            type: Array,
        }
    },
    data(){
        return {
            ready: false,
            blocks: [],
            nextPages: {},
            loadingPage: null,
            showedIssue: null,
            dialogShowIssue: false,
            editedIssue: null,
            dialogEditIssue: false,
            search: {},
            kanbanConfig: {
                accepts(block, target, source) {
                    let from = source.dataset.status 
                    let to = target.dataset.status 
                    return (from == to || props.stages.indexOf(from) < props.stages.indexOf(to)) || (from == 'in-progress' && to == 'waiting' || (from == 'to-test' && to == 'in-progress'))
                },
                revertOnSpill: true,
            },
            showStages: ['new', 'waiting', 'in-progress', 'to-test'],
            projects:[],
        }
    },
    computed: {
        searchProjectId() {
            return this.search.project_id;
        }
    },
     watch: {
        search: {
            handler () {
                console.log(this.search)
                if(this.search.text == "" || this.search.text == null){
                    this.stages.forEach((s) => {
                        this.nextPages[s] = 1
                    this.fetchIssues(s)
                    })
                }
            },
            deep: true,
        },
        searchProjectId: {
            handler () {
                this.stages.forEach((s) => {
                    this.nextPages[s] = 1
                this.fetchIssues(s)
            })},
        },
    },
    mounted() {
        // trick to get props in dragula config (kanbanConfig)
        props = this.$props

        // init
        this.stages.forEach((s) => {
            this.nextPages[s] = 1
            this.fetchIssues(s)
        })

        if (this.$route.query.id)
            this.fetchIssue(this.$route.query.id)

        this.ready = true
        this.fetchProjects()
    },
    methods: {
        fetchIssue(issue_id) {
            this.$store.dispatch('issueRequest', {id: issue_id, with: ['user']})
                .then(result => {
                    this.addBlocks([result.data])
                    this.showIssue(result.data)
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                })
        },
        fetchIssues(stage) {
            this.loadingPage = stage

            let queries = {
                search: JSON.stringify({
                    status: stage, 
                    project_id: this.project_id ? this.project_id : this.search.project_id,
                    text: this.search.text
                }),
                per_page: 5,
                page: this.nextPages[stage],
                with: ['user'],
            }
            if (! this.project_id)
                queries.with.push('project')

            this.blocks = []
            this.$store.dispatch('issuesRequest', queries)
                .then((result) => {
                    this.addBlocks(result.data)
                    if (result.meta.current_page == result.meta.last_page)
                        this.nextPages[stage] = null
                    else
                        this.nextPages[stage] = result.meta.current_page+1

                    this.loadingPage = null
                })
                .catch((err) => {
                })
        },
        showIssue(issue) {
            this.showedIssue = issue
            this.dialogShowIssue = true
        },
        createIssue() {
            this.editedIssue = {project_id: this.project_id}
            this.dialogEditIssue = true
        },
        editIssue(issue) {
            this.editedIssue = issue
            this.dialogEditIssue = true
        },
        handleCanceledIssueForm() {
            this.editedIssue = {}
            this.dialogEditIssue = false
        },
        handleSavedIssueForm(issue) {
            this.dialogEditIssue = false
            this.addBlocks([issue])
            this.showIssue(issue)
        },
        deleteIssue(issue_id) {
            return this.$store.dispatch('issueDeleteRequest', { id: issue_id}).then(() => {
                this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('issue.deleted')});
                this.dialogShowIssue = false
                let i = this.blocks.findIndex(m => m.id == issue_id)
                this.blocks.splice(i, 1)
            }).catch((err) => {
                if (err.response.status != 403)
                    this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
            });
        },
        addBlocks(newBlocks) { 
            var blocks = this.blocks
            newBlocks.forEach(function(b) {
                let index = blocks.findIndex(i => i.id == b.id)
                if (index < 0)
                    blocks.push(b)
                else
                    if (blocks[index].updated_at < b.updated_at) {
                        blocks.splice(index, 1)
                        blocks.push(b)
                    }
            })
            // this.blocks = this.blocks.concat(newBlocks.filter( (b) => this.blocks.find(i => i.id == b.id) === undefined ) )
        },
        updateBlock(id, status) {
            let issue = this.blocks.find(b => b.id === Number(id))

            // update status:
            if (issue.status != status) {
                issue.status = status
                let queries = {id: issue.id, datas: issue}

                this.$store.dispatch('issueEditRequest', queries)
                    .then((issue) => {
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('issue.saved')});
                        this.$emit('savedForm', issue);
                    })
                    .catch((err) => {
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }

            // update position:
            let ul = this.$refs.IssueKanban.$el.querySelector('[data-status='+status+']')
            let newPos = Array.from(ul.children).map(li => li.getAttribute('data-block-id'))
            let queries = {object: 'issues', datas: {positioning: newPos}}

            this.$store.dispatch('updatePositioningRequest', queries)
                .then((project) => {
                    this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('positioning.saved')});
                })
                .catch((err) => {
                    if (err.response.status != 403)
                        this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data});
                })
        },
        fetchProjects() {
            this.loading = true;
            this.$store
                .dispatch("projectsRequest", {})
                .then((result) => this.projects=result.data)
        },

        searchByText: function() {
            this.stages.forEach((s) => {
                    this.nextPages[s] = 1
                this.fetchIssues(s)
            })
            console.log(this.search.text)
        }
    }
}
</script>