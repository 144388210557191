<style>
    .v-application code{
        box-shadow: none
    }
</style>
<template>
    <v-card :loading="loading" :color="color" class="black--text">
        <v-card-actions>
            <span class="caption">{{message.created_at}} par {{message.user ? message.user.name : 'unknown'}}</span>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card-text class="black--text pt-0">
            <v-row dense align="center">
                <v-col cols="auto" v-if="message.is_internal">
                    <v-icon color="primary lighten-4" large>visibility_off</v-icon>
                </v-col>
                <v-col>
                    <div v-html="message.content"></div>
                    <code flat v-if="message.dirty">{{message.dirty}}</code>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider v-if="message.attachments.length > 0" light></v-divider>
        <v-card-actions v-if="message.attachments.length > 0">
            <v-chip small v-for="attachment in message.attachments" :key="attachment.id">
                {{attachment.name}}
                <v-icon class="ml-4" small @click="downloadAttachment(attachment)">get_app</v-icon>
                <v-icon class="ml-2" small @click="viewAttachment(attachment)">visibility</v-icon>
            </v-chip>
        </v-card-actions>
        <!-- <div :style="{ height: '10px', backgroundColor: '#f00'}" v-if="message.unread" v-waypoint="{ active: true, callback: onRead }"/> -->
    </v-card>
</template>
<script>
export default {
    props: {
        message: {
            required: true
        }
    },
    data () {
        return {
            errors: false,
            loading: false,
            dialogFileViewer: false,
            fileViewed: {},
        }
    },
    computed: {
        color: function(){
            return this.message.unread ? 'grey lighten-3' : 'white'
        }
    },
    methods: {
        onRead({ going, direction }) {
            if(going === 'in'){
                this.putDataToApi();
            }    
        },
        putDataToApi() {
            this.loading = true
            this.$store.dispatch('messageReadRequest', this.message)
                .then(()=>{
                    this.loading = false
                    this.message.unread = false
                })
                .catch(error => {
                    this.errors = error.response.data.errors
                })
        },
        downloadAttachment(attachment) {
            return this.$axios.get('/api/attachments/'+attachment.id, {responseType: 'blob'})
                .then( (response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', attachment.name);
                    document.body.appendChild(link);
                    link.click();
            });
        },
        viewAttachment(attachment) {
            return this.$axios.get('/api/attachments/'+attachment.id, {responseType: 'blob'})
                .then( (response) => {
                    let file = new Blob([response.data], {type: response.data.type})
                    window.open(URL.createObjectURL(file));
            });
        }
    }
}
</script>