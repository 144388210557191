<template>
    <v-content class="home">
        <v-container fluid>
            <v-row justify="center" class="mb-5">
                <v-col cols="auto">
                    Dashboard2ouf
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>
<script>
export default {
    data() {
        return {
            // 
        }
    },
    mounted() {

    },
    methods: {}
}
</script>
