var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('v-row',[(_vm.sprint)?_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.allProjects,"label":_vm.$t('object.project'),"item-text":"name","item-value":"id","rules":[_vm.rules.required],"filled":"","disabled":_vm.loading,"loading":_vm.loading},model:{value:(_vm.sprint.project_id),callback:function ($$v) {_vm.$set(_vm.sprint, "project_id", $$v)},expression:"sprint.project_id"}}),_c('v-text-field',{attrs:{"filled":"","label":_vm.$t('sprint.name'),"rules":[_vm.rules.required]},model:{value:(_vm.sprint.name),callback:function ($$v) {_vm.$set(_vm.sprint, "name", $$v)},expression:"sprint.name"}}),_c('wyswyg',{attrs:{"label":_vm.$t('sprint.description')},model:{value:(_vm.sprint.description),callback:function ($$v) {_vm.$set(_vm.sprint, "description", $$v)},expression:"sprint.description"}}),_c('v-select',{attrs:{"items":[
                    {text: _vm.$t('sprint.draft'), value: 'draft'},
                    {text: _vm.$t('sprint.planified'), value: 'planified'},
                    {text: _vm.$t('sprint.open'), value: 'open'},
                    {text: _vm.$t('sprint.done'), value: 'done'},
                    {text: _vm.$t('sprint.waiting'), value: 'waiting'} ],"label":_vm.$t('sprint.status'),"filled":"","rules":[_vm.rules.required]},model:{value:(_vm.sprint.status),callback:function ($$v) {_vm.$set(_vm.sprint, "status", $$v)},expression:"sprint.status"}}),_c('v-data-table',{class:_vm.$vuetify.breakpoint.mdAndDown ? 'mobile' : '',attrs:{"headers":[
                    {text: _vm.$t('story.ref'), value: 'ref', align: 'center'},
                    {text: _vm.$t('story.as'), value: 'as', align: 'start',},
                    {text: _vm.$t('story.be_able_to'), value: 'be_able_to', align: 'start',},
                    {text: _vm.$t('story.so_that'), value: 'so_that', align: 'start',},
                    {text: _vm.$t('story.priority'), value: 'priority', align: 'center'},
                    {text: _vm.$t('story.status'), value: 'status', align: 'center'},
                    {text: _vm.$t('story.estimate'), value: 'estimate', align: 'center'} ],"items":_vm.allStories,"item-key":"id","items-per-page":-1,"show-select":"","show-expand":"","fixed-header":"","height":"600"},scopedSlots:_vm._u([{key:"item.text",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('story.as')))]),_vm._v(" "+_vm._s(item.as.charAt(0).toLowerCase() + item.as.slice(1))+", "),_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('story.be_able_to')))]),_vm._v(" "+_vm._s(item.be_able_to.charAt(0).toLowerCase() + item.be_able_to.slice(1))+", "),_c('span',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$t('story.so_that')))]),_vm._v(" "+_vm._s(item.so_that.charAt(0).toLowerCase() + item.so_that.slice(1))+" ")]}},{key:"header.estimate",fn:function(ref){
                var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" ("+_vm._s(_vm.sprint.stories.reduce(function (acc, s) { return acc + parseFloat(s.estimate || 0); }, 0))+" sp) ")]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{class:{'white': !_vm.$vuetify.theme.dark, 'pa-3': true},attrs:{"colspan":headers.length}},[_c('div',{staticClass:"grey--text text--darken-2 mb-1"},[_vm._v(_vm._s(_vm.$t('story.details'))+":")]),_c('div',{class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text',domProps:{"innerHTML":_vm._s(item.details)}})])]}},{key:"no-data",fn:function(){return [_c('no-data')]},proxy:true}],null,false,3922858142),model:{value:(_vm.sprint.stories),callback:function ($$v) {_vm.$set(_vm.sprint, "stories", $$v)},expression:"sprint.stories"}})],1):_vm._e()],1),_c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.cancel($event)}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":_vm.loading,"loading":_vm.loading}},[_vm._v(_vm._s(_vm.$t('save')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }