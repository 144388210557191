<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form">
        <v-row>
            <v-col cols="12" :md="6">
                <v-autocomplete
                    v-model="feature.capability_id"
                    :items="allCapabilities"
                    :label="$t('object.capability')"
                    :item-text="c => c.project.complete_name+' / '+ c.name"
                    item-value="id"
                    :rules="[rules.required]"
                    filled
                ></v-autocomplete>

                <v-text-field
                    v-model="feature.name"
                    filled
                    :label="$t('feature.name')"
                    :rules="[rules.required]"
                ></v-text-field>

                <v-text-field
                    v-model="feature.ref"
                    filled
                    :label="$t('feature.ref')"
                ></v-text-field>

                <v-select
                    v-model="feature.priority"
                    :items="[
                        {text: $t('feature.low'), value: 'low'},
                        {text: $t('feature.medium'), value: 'medium'},
                        {text: $t('feature.high'), value: 'high'},
                    ]"
                    :label="$t('feature.priority')"
                    filled
                    :rules="[rules.required]"
                ></v-select>

                <v-select
                    v-model="feature.status"
                    :items="[
                        {text: $t('feature.draft'), value: 'draft'},
                        {text: $t('feature.backlog'), value: 'backlog'},
                        {text: $t('feature.planified'), value: 'planified'},
                        {text: $t('feature.open'), value: 'open'},
                        {text: $t('feature.cancel'), value: 'cancel'},
                        {text: $t('feature.done'), value: 'done'},
                        {text: $t('feature.waiting'), value: 'waiting'},
                    ]"
                    :label="$t('feature.status')"
                    filled
                    :rules="[rules.required]"
                ></v-select>

                <v-text-field
                    v-model="feature.estimate"
                    filled
                    :label="$t('feature.estimate')"
                ></v-text-field>
            </v-col>
            <v-col cols="12" :md="6">
                <wyswyg v-model="feature.description" :label="$t('feature.description')"/>
                <wyswyg v-model="feature.comment" :label="$t('feature.comment')"/>
            </v-col>
        </v-row>

        <v-row v-if="feature.original_id">
            <v-col cols="12" :md="6">
                <p>{{ $t('duplication.relationships') }}:</p>
                <v-switch 
                    v-model="feature.duplicate_relationships"
                    :label="$t('object.stories')"
                    value="stories"
                ></v-switch>
            </v-col>
            <v-col cols="12" :md="6">
                <p>{{ $t('duplication.reset_relationships_fields') }}:</p>
                <v-switch 
                    v-model="feature.reset_relationships_fields"
                    :label="$t('story.ref')"
                    value='{"name": "ref", "method": "uniqid()"}'
                ></v-switch>
                <v-switch 
                    v-model="feature.reset_relationships_fields"
                    :label="$t('story.estimate')"
                    value='{"name": "estimate", "method": "null"}'
                ></v-switch>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary">{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">{{ $t('save') }}</v-btn>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            feature: Object.assign({}, this.value),
            allCapabilities: [],
        }
    },
    computed: {
        // 
    },
    mounted() {
        this.fetchCapabilities()
    },
    methods: {
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                let request = 'featureCreateRequest'
                let queries = this.feature

                if (this.feature.id) {
                    request = 'featureEditRequest'
                    queries = {id: this.feature.id, datas: this.feature}
                }

                this.$store.dispatch(request, queries)
                    .then((feature) => {
                        this.loading = false;
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('feature.saved')});
                        if (! feature.stories) feature.stories = []
                        this.$emit('savedForm', feature);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
        fetchCapabilities() {
            let queries = {with: ['project']}
            if (this.feature.project_id)
                queries = {
                    ...queries,
                    search: JSON.stringify({project_id: this.feature.project_id}), 
                    per_page: -1
                }
            this.$store.dispatch('capabilitiesRequest', queries)
                .then((result) => {
                    this.allCapabilities = result.data
                })
                .catch((err) => {
                })
        },
    }
}
</script>
