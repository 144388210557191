const state = () => ({
    snack: ''
})

const getters = {
    getSnack: state => () => state.snack
}

const actions = {

}


const mutations = {
    setSnack (state, snack) {
        state.snack = snack
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}