var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-inline-block"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"text":"","fab":"","small":_vm.small,"large":_vm.large,"dark":_vm.dark,"to":{ name: 'Project', params: {id: _vm.value.id} },"exact":""}},on),[_c('v-icon',[_vm._v("article")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('project.informations')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"text":"","fab":"","small":_vm.small,"large":_vm.large,"dark":_vm.dark,"to":{ name: 'ProductDefinition', params: {id: _vm.value.id} },"exact":""}},on),[_c('v-icon',[_vm._v("aspect_ratio")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('project.product_definition')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"text":"","fab":"","small":_vm.small,"large":_vm.large,"dark":_vm.dark,"to":{ name: 'ProductBacklogV2', params: {id: _vm.value.id} },"exact":""}},on),[_c('v-icon',[_vm._v("view_list")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('project.product_backlog')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"text":"","fab":"","small":_vm.small,"large":_vm.large,"dark":_vm.dark,"to":{ name: 'ProjectStories', params: {id: _vm.value.id} },"exact":""}},on),[_c('v-icon',[_vm._v("work")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('object.sprints')+' & '+_vm.$t('object.stories')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.value.new_issues_count)?_c('v-badge',{attrs:{"overlap":"","right":"","dot":"","color":"red darken-2"}},[_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"text":"","fab":"","small":_vm.small,"large":_vm.large,"dark":_vm.dark,"to":{ name: 'ProjectIssues', params: {id: _vm.value.id} },"exact":""}},on),[_c('v-icon',[_vm._v("bug_report")])],1)],1):[_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"text":"","fab":"","small":_vm.small,"large":_vm.large,"dark":_vm.dark,"to":{ name: 'ProjectIssues', params: {id: _vm.value.id} },"exact":""}},on),[_c('v-icon',[_vm._v("bug_report")])],1)]]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('object.issues')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }