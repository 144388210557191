<template>
    <v-form v-on:submit.prevent="onSubmit" ref="form">
        <v-row>
            <v-col cols="12" :md="6">
                <v-autocomplete
                    v-model="story.feature_id"
                    :items="allFeatures"
                    :label="$t('object.feature')"
                    :item-text="f => f.capability.project.complete_name+' / #'+f.capability.ref+' / '+f.name"
                    item-value="id"
                    :rules="[rules.required]"
                    filled
                    autofocus
                ></v-autocomplete>

                <v-text-field
                    v-model="story.ref"
                    filled
                    :label="$t('story.ref')"
                ></v-text-field>

                <v-text-field
                    v-model="story.as"
                    filled
                    :label="$t('story.as')"
                    :rules="[rules.required]"
                ></v-text-field>

                <v-textarea
                    v-model="story.be_able_to"
                    filled
                    auto-grow
                    :label="$t('story.be_able_to')"
                ></v-textarea>

                <v-textarea
                    v-model="story.so_that"
                    filled
                    auto-grow
                    :label="$t('story.so_that')"
                ></v-textarea>

                <wyswyg v-model="story.details" :label="$t('story.details')"/>

            </v-col>
            <v-col cols="12" :md="6">
                <v-select
                    v-model="story.priority"
                    :items="[
                        {text: $t('story.low'), value: 'low'},
                        {text: $t('story.medium'), value: 'medium'},
                        {text: $t('story.high'), value: 'high'},
                    ]"
                    :label="$t('story.priority')"
                    filled
                    :rules="[rules.required]"
                ></v-select>

                <v-select
                    v-model="story.status"
                    :items="[
                        {text: $t('story.draft'), value: 'draft'},
                        {text: $t('story.backlog'), value: 'backlog'},
                        {text: $t('story.planified'), value: 'planified'},
                        {text: $t('story.open'), value: 'open'},
                        {text: $t('story.cancel'), value: 'cancel'},
                        {text: $t('story.done'), value: 'done'},
                        {text: $t('story.waiting'), value: 'waiting'},
                    ]"
                    :label="$t('story.status')"
                    filled
                    :rules="[rules.required]"
                ></v-select>

                <v-text-field
                    v-model="story.estimate"
                    filled
                    :label="$t('story.estimate')"
                ></v-text-field>
                <!-- <v-select
                    v-model="story.estimate"
                    :items="[
                        {text: '0', value: 0},
                        {text: '1', value: 1},
                        {text: '2', value: 2},
                        {text: '3', value: 3},
                        {text: '5', value: 5},
                        {text: '8', value: 8},
                        {text: '13', value: 13},
                        {text: '21', value: 21},
                        {text: '34', value: 34},
                    ]"
                    label="Estimation"
                    filled
                ></v-select> -->

                <v-autocomplete
                    v-model="story.sprint_id"
                    :items="allSprints"
                    :label="$t('object.sprint')"
                    item-text="name"
                    item-value="id"
                    clearable
                    filled
                ></v-autocomplete>

                <v-combobox
                    v-model="story.tags"
                    :items="allTags"
                    :label="$t('object.tags')"
                    item-text="name"
                    item-value="name"
                    multiple
                    chips
                    deletable-chips
                ></v-combobox>
            </v-col>
        </v-row>

        <v-row justify="center" class="mt-5">
            <v-btn @click.stop="cancel" text color="primary">{{ $t('cancel') }}</v-btn>
            <v-btn color="primary" type="submit" :disabled="loading" :loading="loading">{{ $t('save') }}</v-btn>
        </v-row>

    </v-form>
</template>

<script>
export default {
    props: {
        value: Object,
    },
    data () {
        return {
            loading: false,
            rules: {
                required: value => !!value || this.$t('rules.required'),
                minlength: value => !value || (value && value.length >= 8) || this.$t('rules.minlength'),
            },
            story: Object.assign({}, this.value),
            allFeatures: [],
            allSprints: [],
            allTags: [],
        }
    },
    computed: {
        // 
    },
    mounted() {
        this.fetchFeatures()
        this.fetchSprints()
        this.fetchTags()
    },
    methods: {
        onSubmit () {
            if (this.$refs.form.validate()) {
                this.loading = true;

                let request = 'storyCreateRequest'
                let queries = this.story

                if (this.story.id) {
                    request = 'storyEditRequest'
                    queries = {id: this.story.id, datas: this.story}
                }

                this.$store.dispatch(request, queries)
                    .then((story) => {
                        this.loading = false;
                        this.$store.commit('setSnack', {'type': 'success', 'msg': this.$t('story.saved')});
                        this.$emit('savedForm', story);
                    })
                    .catch((err) => {
                        this.loading = false;
                        if (err.response.status != 403)
                            this.$store.commit('setSnack', {'type': 'red', 'msg': err.response.data})
                    })
            }
        },
        cancel() {
            this.$emit('canceledForm');
        },
        fetchFeatures() {
            let queries = {with: ['capability.project']}
            if (this.story.feature)
                queries = {...queries, search: JSON.stringify({project_id: this.story.feature.project_id}), per_page: -1}
            this.$store.dispatch('featuresRequest', queries)
                .then((result) => {
                    this.allFeatures = result.data
                })
                .catch((err) => {
                })
        },
        fetchSprints() {
            let queries = {}
            if (this.story.feature)
                queries = {search: JSON.stringify({project_id: this.story.feature.project_id}), per_page: -1}
            this.$store.dispatch('sprintsRequest', queries)
                .then((result) => {
                    this.allSprints = result.data
                })
                .catch((err) => {
                })
        },
        fetchTags() {
            let queries = {search: JSON.stringify({taggable_type: 'story'}), per_page: -1}
            this.$store.dispatch('tagsRequest', queries)
                .then((result) => {
                    this.allTags = result.data
                })
                .catch((err) => {
                })
        },
    }
}
</script>
