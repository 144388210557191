import Vue from 'vue'
import Router from 'vue-router'
import store from './vuex/store'
import Login from './views/Login.vue'
import PasswordReset from './views/PasswordReset.vue'
import PasswordEdit from './views/PasswordEdit.vue'
import Home from './views/Home.vue'
import Unauthorized from './views/Unauthorized.vue'
import Profile from './views/Profile.vue'

import Projects from './views/Project/Index.vue'
import Project from './views/Project/Show.vue'
import ProductBacklog from './views/Project/ProductBacklog.vue'
import ProductBacklogV2 from './views/Project/ProductBacklogV2.vue'
import ProductDefinition from './views/Project/ProductDefinition.vue'
import CreateProject from './views/Project/Create.vue'
import EditProject from './views/Project/Edit.vue'
import Stories from './views/Project/Stories.vue'
import ProjectIssues from './views/Project/Issues.vue'

import Issues from './views/Issues/Index.vue'

import Timesheets from './views/Timesheet/Index.vue'

import FileManager from './views/FileManager/Index.vue'

import Ethercalc from './views/Ethercalc/Index.vue'

import Users from './views/User/Index.vue'
import User from './views/User/Show.vue'
import CreateUser from './views/User/Create.vue'
import EditUser from './views/User/Edit.vue'

import Calendar from './views/Calendar/Index.vue'
import CalendarAttendee from './views/Calendar/Attendee.vue'

import ShowParameters from './views/Parameter/Show.vue'


Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuth) {
        next()
        return
    }
    next('/login')
}

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuth) {
        next()
        return
    }
    next('/home')
}

const router = new Router({
    routes: [
        {
            path: '/unauthorized',
            name: 'Unauthorized',
            component: Unauthorized,
        },
        {
            path: '/',
            redirect: 'login',
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/password/reset',
            name: 'password-reset',
            component: PasswordReset,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/password/reset/:token',
            name: 'password-edit',
            component: PasswordEdit,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/parameters',
            name: 'Parameters',
            component: ShowParameters,
            beforeEnter: ifAuthenticated,
        },
        // ------------------------------------------------------------------
        //          PROJECT
        // ------------------------------------------------------------------
        {
            path: '/projects',
            name: 'Projects',
            component: Projects,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/projects/create',
            name: 'CreateProject',
            component: CreateProject,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/project/:id',
            props: true,
            name: 'Project',
            component: Project,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/project/:id/edit',
            props: true,
            name: 'EditProject',
            component: EditProject,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/project/:id/product_backlog',
            props: true,
            name: 'ProductBacklog',
            component: ProductBacklog,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/project/:id/product_backlogV2',
            props: true,
            name: 'ProductBacklogV2',
            component: ProductBacklogV2,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/project/:id/product_definition',
            props: true,
            name: 'ProductDefinition',
            component: ProductDefinition,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/project/:id/stories',
            props: true,
            name: 'ProjectStories',
            component: Stories,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/projects/:id/issues',
            props: true,
            name: 'ProjectIssues',
            component: ProjectIssues,
            beforeEnter: ifAuthenticated,
        },
        // ------------------------------------------------------------------
        //          ISSUE
        // ------------------------------------------------------------------
        {
            path: '/issues',
            name: 'Issues',
            component: Issues,
            beforeEnter: ifAuthenticated,
        },
        // ------------------------------------------------------------------
        //          GED
        // ------------------------------------------------------------------
        {
            path: '/filemanager',
            name: 'FileManager',
            component: FileManager,
            beforeEnter: ifAuthenticated,
        },
        // ------------------------------------------------------------------
        //          User
        // ------------------------------------------------------------------
        {
            path: '/users',
            name: 'Users',
            component: Users,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/users/create',
            name: 'CreateUser',
            component: CreateUser,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/user/:id',
            props: true,
            name: 'User',
            component: User,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/user/:id/edit',
            props: true,
            name: 'EditUser',
            component: EditUser,
            beforeEnter: ifAuthenticated,
        },
        // ------------------------------------------------------------------
        //          TIMESHEET
        // ------------------------------------------------------------------
        {
            path: '/timesheets',
            name: 'Timesheets',
            component: Timesheets,
            beforeEnter: ifAuthenticated,
        },
        // ------------------------------------------------------------------
        //          CALENDAR (TODO + EVENT)
        // ------------------------------------------------------------------
        {
            path: '/calendar',
            name: 'Calendar',
            component: Calendar,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/attendee',
            name: 'CalendarAttendee',
            component: CalendarAttendee,
            // beforeEnter: ifAuthenticated,
        },
        // ------------------------------------------------------------------
        //          ETHERCALC
        // ------------------------------------------------------------------
        {
            path: '/ethercalcs',
            name: 'Ethercalc',
            component: Ethercalc,
            beforeEnter: ifAuthenticated,
        },
        // {
        //     path: '/ethercalc/:id',
        //     props: true,
        //     name: 'Ethercalc',
        //     component: Ethercalc,
        //     meta: {
        //         rule: 'isInternal'
        //     }
        // },

    ]
})

router.afterEach((to, from, next) => {
    store.commit('setToRoute', to)
    store.commit('setFromRoute', from)
    store.commit('setNextRoute', next)
})

export default router